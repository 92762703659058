import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Spinner,
  Badge,
  Input,
  Row,
  Pagination,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { FaPlus, FaEdit, FaTrashAlt } from "react-icons/fa";
import { useFormik } from "formik";
import { api } from "../../helpers/api";
import { ToastContainer, toast } from "react-toastify";
import {
  getLoggedinUser,
  getSettingsDetails,
  getAddressFromCoordinates,
} from "../../helpers/api_helper";

const Faq = () => {
  document.title = "Faq";

  const [selectedCountry, setSelectedCountry] = useState("US");
  const [isEdit, setIsEdit] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [faqView, setFaqView] = useState(null);
  const [isExpanded, setIsExpanded] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const userDetails = getLoggedinUser()?.user; // Assuming userDetails is fetched from context or store

  const toggleModal = () => setModalOpen(!modalOpen);

  const getFaq = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `faq?page=${currentPage}&perPage=${rowPerPage}`
      );
      setFaqList(response.data?.status ? response.data.data : []);
    } catch (err) {
      console.error(err.message);
      toast.error("Error fetching FAQs");
    } finally {
      setLoading(false);
    }
  };

  const deleteFaq = async (id) => {
    try {
      const response = await api.delete(`faq/${id}`);
      if (response.data.status) {
        getFaq();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error(err.message);
      toast.error("Error deleting FAQ");
    }
  };

  const handleAddClick = () => {
    setFaqView(null);
    validation.resetForm();
    setIsEdit(false);
    toggleModal();
  };

  const handleEditClick = (item) => {
    setFaqView(item);
    validation.setValues({
      question: item.question,
      answer: item.answer,
    });
    setIsEdit(true);
    toggleModal();
  };

  const handleToggle = (index) => {
    setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const validation = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.question) errors.question = "The question field is required.";
      if (!values.answer) errors.answer = "The answer field is required.";
      return errors;
    },
    onSubmit: (values) => {
      isEdit ? editFaq(values, faqView.id) : addFaq(values);
    },
  });

  const addFaq = async (values) => {
    try {
      const response = await api.post("faq", values);

      if (response.data.status) {
        getFaq();
        toggleModal();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error("Error adding FAQ");
    }
  };

  const editFaq = async (values, id) => {
    try {
      const response = await api.put(`faq/${id}`, values);
      if (response.data.status) {
        getFaq();
        toggleModal();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error(err.message);
      toast.error("Error updating FAQ");
    }
  };

  const handlePaginationChange = (pageNumber) => setCurrentPage(pageNumber);

  const filteredFaqList = faqList.filter((item) =>
    item.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    getFaq();
  }, [currentPage, rowPerPage]);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className="mb-1 mb-md-0">
              <div className="mb-2 page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0">Faqs</h4>
              </div>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col sm={4}> </Col>
            <Col className="col-sm-auto ms-auto d-flex gap-2">
              <div className="search-box">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              {/* Conditional rendering based on role */}
              {userDetails?.roles?.[0]?.name === "Admin" && (
                <div className="list-grid-nav hstack gap-1">
                  <button
                    className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1"
                    onClick={handleAddClick}
                  >
                    <i className="ri-add-line"></i>
                    <span className="d-none d-md-block"> Add FAQ</span>
                  </button>
                  <FaPlus
                    className="text-primary d-flex d-md-none"
                    size={18}
                    onClick={handleAddClick}
                  />
                </div>
              )}
            </Col>
          </Row>

          <Row className="row gy-1 mb-1 mt-2 px-2">
            {loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : filteredFaqList.length ? (
              filteredFaqList.map((item, index) => (
                <Col key={index} xs={12} className="p-0">
                  <Card className="faq-card" style={{ marginBottom: 0 }}>
                    <CardBody style={{ padding: 0 }}>
                      <div
                        className="faq-question d-flex justify-content-between align-items-center"
                        onClick={() => handleToggle(index)}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#D6F5FF",
                          padding: "15px",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease-in-out",
                          borderLeft: isExpanded[index]
                            ? "4px solid black"
                            : "none", // Apply black left border when expanded
                        }}
                      >
                        {index + 1}. {item.question}
                        {/* Admin conditional rendering */}
                        {userDetails?.roles?.[0]?.name === "Admin" && (
                          <div>
                            <Button
                              size="sm"
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditClick(item);
                              }}
                              className="me-2"
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteFaq(item.id);
                              }}
                            >
                              <FaTrashAlt />
                            </Button>
                          </div>
                        )}
                      </div>
                      <Collapse isOpen={isExpanded[index]}>
                        <div
                          className="faq-answer"
                          style={{
                            padding: "15px",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "8px",
                          }}
                        >
                          {item.answer}
                        </div>
                      </Collapse>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <div>No FAQs found</div>
            )}
          </Row>

          <Pagination
            activePage={currentPage}
            itemsCountPerPage={rowPerPage}
            totalItemsCount={faqList.total || 0}
            onChange={handlePaginationChange}
          />
        </Container>
      </div>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {isEdit ? "Edit FAQ" : "Add FAQ"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={validation.handleSubmit}>
            <div>
              <label>Question</label>
              <Input
                name="question"
                value={validation.values.question}
                onChange={validation.handleChange}
                invalid={!!validation.errors.question}
              />
              {validation.errors.question && (
                <div>{validation.errors.question}</div>
              )}
            </div>
            <div>
              <label>Answer</label>
              <Input
                name="answer"
                value={validation.values.answer}
                onChange={validation.handleChange}
                invalid={!!validation.errors.answer}
              />
              {validation.errors.answer && (
                <div>{validation.errors.answer}</div>
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={validation.handleSubmit}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Faq;
