import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, Card, CardBody, Col, Container, ModalHeader,
  Offcanvas, OffcanvasHeader, Spinner, OffcanvasBody, Badge,
  Form, Input, Label, Modal, ModalBody, ModalFooter, Row,
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { FaCheck, FaTimesCircle, FaTimes, FaPlus, FaFilePdf, FaFileAlt, FaFileExcel, } from "react-icons/fa";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import Pagination from "react-js-pagination";
import { FormatDate } from "../../Components/Hooks/Function";
import { ToastMessage, DefaultDate, SmallDateFormat, FormatNumber } from "../../Components/Hooks/Function";

const Expense = () => {
  document.title = "Expense";
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [settingsList, setSettingsList] = useState([]);
  var moment = require('moment-timezone');
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [deleteModal, setDeleteModal] = useState(false);
  const [expenseList, setExpenseList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [userList, setUserList] = useState([]);
  const [expenseView, setExpenseView] = useState(null)
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [fileUrl, setFileUrl] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
  const pdfExtensions = new Set(["pdf"]);
  const excelExtensions = new Set(["xls", "xlsx"]);
  const [modalFile, setModalFile] = useState(false);
  const [modalAttachment, setModalAttachment] = useState({
    show: false,
    file: [],
  });

  const [downloadFormat, setDownloadFormat] = useState({
    value: "", label: "Select Report "
  });

  const [file, setFile] = useState([]);
  const [files, setFiles] = useState([]);
  const userDetails = getLoggedinUser()?.user;
  //  const [userDetails, setUserDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fromValue, setFromValue] = useState(moment().clone().tz(moment.tz.guess()).startOf('month'));
  const [toValue, setToValue] = useState(moment().clone().tz(moment.tz.guess()).endOf('month'));

  const paymentTypeOptions = [
    { value: 'UPI', label: 'UPI' },
    { value: 'Cheque', label: 'Cheque' },
    { value: 'Cash', label: 'Cash' },
  ]

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setFromValue(selectedDates[0]);
      setToValue(selectedDates[1]);
      filterValidation.handleSubmit();
    }
  };

  const [view, setView] = useState(false);

  const selectStyles = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",

    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      margin: "0px 0px 5px 0px",
      minHeight: "30px",

    }),
    dropdownButton: (baseStyles, state) => ({
      ...baseStyles,
      width: "100%",
      height: "30px",
      padding: "5px 8px",
      boxWidth: "none",
      boxShadow: "none",
      color: "rgb(118, 118, 118)",
      border: "1px solid #ced4da",
      "& svg": {
        color: "hsl(0, 0%, 80%)",
        height: "35",
        width: "35"
      }
    }),

    groupHeading: (provided) => ({
      fontWeight: 'bold',
      // color: '#019aff',
      padding: "5px 12px",
      paddingLeft: '6px',
      cursor: "pointer",
      alignItems: 'center',
      display: 'flex',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: "pointer",
      paddingLeft: state.data.label === 'Select All' ? '6px' : '16px',
      fontWeight: state.data.label === 'Select All' && 'bold',
      // color: state.data.label === 'Select All' && '#019aff',
      // padding:"5px 12px",
      height: "30px",
      boxShadow: "none",
      display: 'flex',
      alignItems: 'center',
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "unset",
      },
    }),
  };

  const selectStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? '#ced4da' : ' ',
        color: state.isFocused ? '#000' : "",
        backgroundColor: "transparent",
        "&:hover": {
          color: "#000",
          backgroundColor: "#ced4da",
        },
        "&:active": {
          color: "#000",
          // backgroundColor: "#019aff", 
        },
      }),
      groupHeading: (provided) => ({
        fontWeight: 'bold',
        // color: '#019aff',
        padding: '0 6px',
        paddingLeft: '10px',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px', // Change padding on focus
      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    }
  }

  const handleDownload = async (value) => {
    value === "Recurring" ?
      getReportSum()
      : value === "Annual" ?
        getAllReport()
        : getReport()
    setDownloadFormat({
      value: "", label: "Select Report   "
    });

    setModalConfirm(false);
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % modalAttachment?.file?.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? modalAttachment?.file?.length - 1 : prevSlide - 1
    );
  };

  const handleAddClick = (arg) => {
     validation.resetForm();
    validation.setValues({
      date: DefaultDate("", selectedCountry)
    })
    setView(false);
    setSubmit(false);
    setAttachmentList([]);
    setFile([]);
    setFiles([]);
    setIsEdit(false);
    toggleDrawer();
  };


  const handleEditClick = useCallback((arg) => {
    setView(false);
    setFile([]);
    setFiles([]);
    setAttachmentList([]);
    setSubmit(false);
    viewExpense(arg?.id);
    validation.resetForm();
    setIsEdit(true);
  }, []);

  const handleViewClick = (arg) => {
    setView(true);
    viewExpense(arg?.id);
    validation.resetForm();
    setIsEdit(false);
  };

  const onClickData = () => {
    setDeleteModal(true);
  };

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    setSettingsList(getSettingsDetails("settings"));
  };

  useEffect(() => {
    getSettings();
  }, []);


  const handleDelete = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('expenseAttachment/' + id, {});
      if (response.data.status === true) {
        setAttachmentList(attachmentList?.filter(item => item.id !== id));
        setFile(file?.filter(item => item.id !== id));
        setFiles(files?.filter(item => item.id !== id));
      }

    } catch (err) {
      console.error(err.message);
    }

  };


  const keywordSearch = async (e) => {
    let inputVal = e;
    if (inputVal !== "") {
      const filteredResults = searchList?.data?.data?.filter(item =>
        item.user?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        DefaultDate(item.date, selectedCountry).includes(inputVal.toLowerCase()) ||
        item.expense_category?.name.toLowerCase().includes(inputVal.toLowerCase()) ||
        item?.purpose?.toLowerCase().includes(inputVal.toLowerCase()) ||
        FormatNumber(item.amount, selectedCountry).toLowerCase().includes(inputVal.toLowerCase()) ||
        DefaultDate(item.created_at, selectedCountry).includes(inputVal.toLowerCase()) ||
        DefaultDate(item.status_date, selectedCountry).includes(inputVal.toLowerCase()) ||
        item.expense_status?.name.toLowerCase().includes(inputVal.toLowerCase())
      );

      setExpenseList({
        ...expenseList,
        data: filteredResults
      });

      setExpenseTotal(filteredResults?.filter((item) => item.expense_status_id === 1)?.reduce((row, { amount }) => row + Number(amount), 0));

    } else {
      setExpenseList({
        ...expenseList,
        data: searchList?.data?.data
      });
      setExpenseTotal(searchList?.total);
    }

  };

  const [categoryAllList, setCategoryAllList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const handleFileChange = (e) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
    const files = Array.from(e.target.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize <= maxSizeInBytes) {
      const allowedFiles = files.filter((file) =>
        file.type === 'application/pdf' || file.type.startsWith('image/')
      );
      setFile(allowedFiles);
      setFiles(
        allowedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    } else {
      setFile([]);
      setFiles([]);
      toast("Uplaoad File is Greater than 10 MB",
        {
          position: "top-right", hideProgressBar: true,
          className: 'secondary',
          progress: undefined, toastId: ""
        })
    }
  }

  const [isExpanded, setIsExpanded] = useState([]);
  const handleToggle = (index) => {
    const newExpandedStates = [...isExpanded];
    newExpandedStates[index] = !newExpandedStates[index];
    setIsExpanded(newExpandedStates);
  };

  useEffect(() => {
    setIsExpanded(expenseList?.data?.map(() => false))
  }, [expenseList]);

  const viewAttachmet = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('expenseAttachment/' + id, {});

      if (response.data.status === true) {
        setModalAttachment({ ...modalAttachment, show: true, file: response.data.data });
      } else {
        setModalAttachment({ ...modalAttachment, show: true, file: [], });
      }

    } catch (err) {
      console.error(err.message);
    }
  }

  const getCategory = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('expenseCategoryList', {});

      if (response.data.status === true) {
        const formattedAllOptions = formatAllOptions(response.data?.data);
        const formattedOptions = formatOptions(response.data?.data);
        setCategoryList(formattedOptions);
        setCategoryAllList(formattedAllOptions);
      } else {
        setCategoryList([]);
        setCategoryAllList([]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const formatAllOptions = (apiData) => {
    const groupedOptions = {};
    apiData.forEach(option => {
      const group = option.parent?.name;
      const value = option.id;
      const label = option.name;

      if (!groupedOptions[group]) {
        groupedOptions[group] = [];
        //   groupedOptions[group].push({ value, label, group });
      }
      groupedOptions[group].push({ value, label, group });
    });

    const formattedOptions = Object.keys(groupedOptions).map(group => ({
      label: group,
      options: groupedOptions[group],
    }));

    return formattedOptions;
  };

  const formatOptions = (apiData) => {
    const groupedOptions = {};
    apiData.forEach(option => {
      const group = option.parent?.name;
      const value = option.id;
      const label = option.name;
      if (!groupedOptions[group]) {
        groupedOptions[group] = [];
      }
      groupedOptions[group].push({ value, label });
    });

    const formattedOptions = Object.keys(groupedOptions)?.map(group => ({
      label: group,
      options: groupedOptions[group],
    }));
    return formattedOptions;
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const allOptions = categoryAllList
      .flatMap((group) => group.options)
      .map((option) => option);

    setSelectedOptions([
      ...allOptions,
      { value: 0, label: "Select All" },
    ]);

  }, [categoryAllList]);


  const handleSelectionChange = (selected) => {
    const isSelectAll = selected.filter((group) => group.value === 0)[0]?.value === 0;

    if (isSelectAll) {
      const allOptions = categoryAllList
        .flatMap((group) => group.options)
        .map((option) => option);

      setSelectedOptions([
        ...allOptions,
        { value: 0, label: "Select All" },
      ]);

      const wasSelectAllSelected = selectedOptions?.some((group) => group.value === 0);
      if (wasSelectAllSelected) {
        setSelectedOptions(selected);
      }

    } else {
      const wasSelectAllSelected = selectedOptions?.some((group) => group.value === 0);
      setSelectedOptions(selected);
      if (wasSelectAllSelected) {
        setSelectedOptions([]);
      }
    }

    filterValidation.handleSubmit();
  };


  const handleUserSelectionChange = (selected) => {
    filterValidation.setValues({
      ...filterValidation.values,
      user_id: selected.length > 0 ? selected : [{
        name: "Select...",
        value: 0
      }]
    });

    filterValidation.handleSubmit();
  };

  const getStatus = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('expenseStatus', {});

      if (response.data.status === true) {

        setStatusList(response.data?.data);

      } else {
        setStatusList([]);
      }


    } catch (err) {
      console.error(err.message);
    }
  };

  const [loading, setLoading] = useState(false);
  const getExpense = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`expense`,
        {
          params: {
            expense_category_id: expense_category_idParam,
            user_id: user_idParam,
            startDate: startDateParam,
            endDate: endDateParam,
            page: currentPage,
            perPage: rowPerPage
          }
        }
      );

      if (response.data.status === true) {
        setSearchList(response.data);
        setExpenseList(response.data?.data);
        setExpenseTotal(response.data?.total);

      } else {
        setSearchList([]);
        setExpenseList([]);
        setExpenseTotal(response.data?.total);

        ToastMessage("error", response.data?.message);
      }

      setLoading(false);

      setCurrentPage(1);

    } catch (err) {
      console.error(err.message);
    }

  };

  const getExpensePage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`expense`,
        {
          params: {
            expense_category_id: expense_category_idParam,
            user_id: user_idParam,
            startDate: startDateParam,
            endDate: endDateParam,
            page: currentPage,
            perPage: rowPerPage
          }
        }
      );

      if (response.data.status === true) {
        setSearchList(response.data);
        setExpenseList(response.data?.data);
        setExpenseTotal(response.data?.total);
      } else {
        setSearchList([]);
        setExpenseList([]);
        setExpenseTotal(response.data?.total);

        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }
  };


  const getReport = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`expenseReport`, {
        params: {
          startDate: startDateParam,
          endDate: endDateParam,
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
          selectedCountry: selectedCountry,
          expense_category_id: expense_category_idParam,
          user_id: user_idParam,
        }, responseType: 'blob',
      });


      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'One-Time Expense Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err.message);
    }

  };


  const getReportSum = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`expenseReportSum`, {
        params: {
          startDate: startDateParam,
          endDate: endDateParam,
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          totalamount: expenseTotal,
          selectedCountry: selectedCountry,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
          expense_category_id: expense_category_idParam,
          user_id: user_idParam,
        },
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Recurring Expense Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err.message);
    }

  };

  const getAllReport = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`expenseAllReport`, {
        params: {
          startDate: startDateParam,
          endDate: endDateParam,
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          selectedCountry: selectedCountry,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
        }, responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Annual Expense Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err.message);
    }
  };

  const viewExpense = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('expense/' + id, {});
      if (response.data.status === true) {
        validation.setValues({ 
          ...response.data?.data,
          date: DefaultDate(response.data?.data?.date, selectedCountry) || '',
        });

        setExpenseView(response.data?.data);
        setAttachmentList(response.data?.attachment);
      } else {
        setExpenseView([]);
        setAttachmentList([]);
      }
      setOpen(true);

    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllUser = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('getAllUser', {});
      if (response.data.status === true) {
        setUserList(response.data?.data);
      } else {
        setUserList([]);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getExpense();
    getCategory();
    getSettings();
    getStatus();
    getAllUser();
  }, []);


  const addExpenses = async (values) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      formData.append('expense_category_id', values?.expense_category_id || '');
      formData.append('amount', values?.amount || '');
      formData.append('payment_type', values?.payment_type || '');
      if (values?.expense_status_id !== undefined && values?.expense_status_id !== null) {
        formData.append('expense_status_id', values?.expense_status_id);
      }
      if (values?.purpose !== undefined && values?.purpose !== null) {
        formData.append('purpose', values?.purpose);
      }
      Object.keys(file).forEach(key => {
        const files = file[key];
        if (files) {
          formData.append('attachment[]', files);
        }
      });

      formData.append('date', values.date ? FormatDate(values.date, selectedCountry) : "");

      const response = await api.post('expense', formData);
      if (response.data.status === true) {
        getExpense();
        validation.resetForm();
        setIsEdit(false);
        setOpen(false);
        setFile([]);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const editExpenses = async (values, id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      formData.append('_method', "PUT");
      formData.append('expense_category_id', values?.expense_category_id || '');
      formData.append('amount', values?.amount || '');
      formData.append('payment_type', values?.payment_type || '');
      if (values?.expense_status_id !== undefined && values?.expense_status_id !== null) {
        formData.append('expense_status_id', values?.expense_status_id);
      }
      if (values?.purpose !== undefined && values?.purpose !== null) {
        formData.append('purpose', values?.purpose);
      }
      Object.keys(file).forEach(key => {
        const files = file[key];
        if (files) {
          formData.append('attachment[]', files);
        }
      });
      formData.append('date', values.date ? FormatDate(values.date, selectedCountry) : "");

      const response = await api.post('expense/' + id, formData);
      if (response.data.status === true) {
        getExpense();
        validation.resetForm();
        setIsEdit(false);
        setFile([]);
        setOpen(false);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const deleteExpenses = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('expense/' + value?.id, {});
      if (response.data.status === true) {
        getExpense();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)
    } catch (err) {
      console.error(err.message);
    }

  };

  function getExtension(filename) {
    return filename?.split('.').pop()
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      expense_category_id: '',
      purpose: '',
      amount: '',
      expense_status_id: '',
      date: "",
    },
    validationSchema: Yup.object({
      expense_category_id: Yup.string().required("Please select expense for"),
      purpose: Yup.string().notRequired(),
      amount: Yup.string()
        .min(0, "amount must be more than 0 characters long")
        .max(10, "amount must be less than 10 characters long").required("Please enter"),
      expense_status_id: Yup.string().notRequired(),
      payment_type: Yup.string().required("Please select payment mode"),
      date: Yup.string().required("Please enter"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isEdit) {
          await editExpenses(values, validation.values?.id);
        } else {
          await addExpenses(values);
        }
        setSubmitting(false);
      } catch (error) {

        console.error("An error occurred:", error);
        setSubmitting(false);
      }
    },
  });

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      expense_category_id: [],
      startDate: '',
      endDate: '',
      user_id: userList?.map((item, index) => {
        return (
          { value: item.id, label: item.name, }
        );
      }),
    },
    validationSchema: Yup.object({
      expense_category_id: Yup.array(),
      user_id: Yup.array(),
      startDate: Yup.date(),
      endDate: Yup.date(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
      api.defaults.headers.common['Content-Type'] = 'application/json';
      try {
        const response = await api.get(`expense`, {
          params: {
            expense_category_id: expense_category_idParam,
            user_id: user_idParam,
            startDate: startDateParam,
            endDate: endDateParam,
            page: currentPage,
            perPage: rowPerPage
          }
        });

        if (response.data.status === true) {
          setSearchList(response.data);
          setExpenseList(response.data?.data);
          setExpenseTotal(response.data?.total);
        } else {
          setSearchList([]);
          setExpenseList([]);
          setExpenseTotal([]);
        }
        setLoading(false);

      } catch (err) {
        console.error(err.message);
      }
    },
  });



  const startDateParam = fromValue ? `${moment(fromValue).format('YYYY-MM-DD')}` : '';
  const endDateParam = toValue ? `${moment(toValue).format('YYYY-MM-DD')}` : '';
  const user_idParam = userDetails?.roles?.[0]?.name === "Admin" ? filterValidation?.values?.user_id ? filterValidation.values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '' : '';
  const expense_category_idParam = selectedOptions?.map((value) => (value.value)).filter(value => value !== "Select All" && value !== 0);
 
   
  const getDefaultDate = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return [firstDayOfMonth, lastDayOfMonth];
  };

  const handleClick = (e) => {
    setCurrentPage(e);
    getExpensePage(e, rowPerPage);
  };

  // const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(expenseList?.total / rowPerPage); i++) {
  //   pageNumbers.push(i);
  // }

  // const handleprevPage = () => {
  //   let prevPage = currentPage - 1;
  //   setCurrentPage(prevPage);
  //   getExpensePage(prevPage, rowPerPage);
  // };

  // const handlenextPage = () => {
  //   let nextPage = currentPage + 1;
  //   setCurrentPage(nextPage);

  //   getExpensePage(nextPage, rowPerPage);

  // };

  // const renderPageNumbers = () => {
  //   // Logic to render the current set of page numbers (e.g., 1 to 10)
  //   const start = (currentPage - 1) * 1;
  //   const end = start + 10;
  //   const currentSet = pageNumbers.slice(start, end);

  //   return currentSet.map((item, key) => (
  //     <React.Fragment key={key}>
  //       <li className="page-item px-1">
  //         <Link to="#!" className={currentPage === item ? "page-link active px-1 py-0" : "page-link px-1 py-0"} onClick={() => handleClick(item)}>{item}</Link>
  //       </li>
  //     </React.Fragment>
  //   ));
  // };


  // const isSelectAllIndeterminate =
  //   selectedOptions.length > 0 &&
  //   selectedOptions.length < categoryAllList.flatMap((group) => group.options).length;

  // const isSelectAllChecked =
  //   selectedOptions.length === categoryAllList.flatMap((group) => group.options).length;


  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        name={"this"}
        show={deleteModal}
        onDeleteClick={() => deleteExpenses(expenseView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2 page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Expenses</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} lg={6} xl={6} >
              <Form onSubmit={(e) => {
                e.preventDefault();
                filterValidation.handleSubmit();
                return false;
              }}>
                <Row  >
                  <Col xs={12} md={6} lg={6} xl={6} >
                    <Flatpickr
                      className="form-control py-1 px-2"
                      placeholder="Select Date Range"
                      options={{
                        allowInput: true,
                        mode: 'range',
                        dateFormat: SmallDateFormat(selectedCountry),
                        defaultDate: getDefaultDate(),
                      }}
                      //     value={[fromValue, toValue]} // Pass the selected range as value
                      onChange={handleDateChange}
                    />
                  </Col>
                  <Col xs={userDetails?.roles?.[0]?.name !== "Admin" ? 12 : 6} md={3} lg={3} xl={3} className={`${userDetails?.roles?.[0]?.name !== "Admin" ? "" : "pe-0"} px-md-0 py-1 py-md-0`} >
                    <ReactMultiSelectCheckboxes
                      value={selectedOptions}
                      options={[{ value: 0, label: "Select All" },
                      ...categoryAllList,
                      ]}
                      onChange={handleSelectionChange}
                      styles={selectStyles}
                    />
                  </Col>

                  {userDetails?.roles?.[0]?.name === "Admin" && <Col xs={6} md={3} lg={3} xl={3} className='py-1 py-md-0' >
                    <ReactMultiSelectCheckboxes
                      value={filterValidation.values.user_id?.filter(item => item?.value !== 0)?.map((item, index) => {
                        return (
                          { value: item.value, label: item.label }
                        );
                      })
                      }

                      options={[{
                        label: "Select All",
                        options: userList?.map((item, index) => {
                          return (
                            {
                              value: item.id, label: item.name,
                              // isIndeterminate: isSelectAllIndeterminate,  isChecked: isSelectAllChecked, 
                            }
                          );
                        }),
                      }]}

                      onChange={handleUserSelectionChange}
                      styles={selectStyles}
                    />
                  </Col>}
                </Row>
              </Form>
            </Col>


            <Col xs={12} md={6} lg={6} xl={6} className='align-items-center' >
              <Row className='align-items-center' >
                <Col className="col-xs-12 col-sm-auto ms-auto d-flex gap-2">            
                  <div className="search-box">
                    <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                    <i className="ri-search-line search-icon"></i>
                  </div>

                  <div className="d-none d-md-flex list-grid-nav hstack gap-1">
                    <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                      <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Expense</span></button>
                    {/* <FaPlus className="text-primary d-flex d-md-none " size={18}    onClick={() => {handleAddClick();   }}/>  */}
                  </div>

                  {userDetails?.roles?.[0]?.name === "Admin" &&
                    <div className=" list-grid-nav hstack gap-1">
                      <Select
                        value={{ "value": downloadFormat.value, "label": downloadFormat.label }}
                        name='download'
                        onChange={(e) => {
                          if (e?.value !== "") {
                            setDownloadFormat({   value: e?.value, label: e?.label   });
                            setModalConfirm(true);
                          }
                        }}

                        options={[
                          { value: "", label: "Select Report     " },
                          { value: "One-Time", label: "One-Time Expense Report" },
                          { value: "Recurring", label: "Recurring Expense Report" },
                          { value: "Annual", label: "Annual Expense Report" },
                        ]
                        }
                        styles={selectStyle('download')}
                      />

                    </div>}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1 mt-1 d-flex d-md-none" >
            <Col lg={12} className='pt-1'>
              <Row className='align-items-center'>
                <Col xs={6} sm={6} md={6} className='px-3' >
                  <h5 className="fs-14 mb-0 d-flex gap-2 align-items-center" >  <span>Total:   </span>  <span className="fs-16 mb-0" > {FormatNumber(expenseTotal, selectedCountry)} </span> </h5>
                </Col>
                <Col xs={6} sm={6} md={6} className='gap-2 px-3 d-flex align-items-center justify-content-end' >
                  <FaPlus className="text-primary" size={18} onClick={() => handleAddClick()} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1 mt-1 px-2" >
            <Col lg={12} className='table px-1 mb-0'>
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0 rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-flex align-items-center px-0 py-2">
                    <div className="col-6 col-md-2 px-2" >
                      <h5 className="fs-14 mb-0">Expense By</h5>
                    </div>

                    <div className="col-6 col-md-1 d-flex justify-content-end justify-content-md-start align-items-center px-0 px-md-0">
                      <h5 className="fs-14 mb-0">Date</h5>
                    </div>

                    <div className="col-6 col-md-2 px-3">
                      <h5 className="fs-14 mb-0"> Expense For </h5>
                    </div>

                    <div className="d-none d-md-block d-flex col-6 col-md-3 align-items-center px-1">
                      <h5 className="fs-14 mb-0">Description</h5>
                    </div>

                    <div className="col-6 col-md-1 align-items-center d-flex justify-content-end px-md-2">
                      <h5 className="fs-14 mb-0" >Amount</h5>
                    </div>

                    <div className="col-6 col-md-1 align-items-center d-none d-md-flex justify-content-end px-2 px-md-1">
                      <h5 className="fs-14 mb-0"> Created</h5>
                    </div>

                    <div className="col-6 col-md-1 align-items-center d-none d-md-flex justify-content-end px-2 px-md-0">
                      <h5 className="fs-14 mb-0"> Approved on </h5>
                    </div>

                    <div className={`col-6 col-md-1 d-flex gap-0 align-items-center justify-content-end px-3 `}>
                      <h5 className="fs-14 mb-0"> Action</h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>

          <Row className="row gy-1 mb-1" id="expenses-list">
            {
              loading ?
                <div className='text-center'>
                  <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                    Loading...
                  </Spinner>
                </div>
                :
                expenseList?.data?.length !== 0 ?
                  (expenseList?.data)?.map((item, index) => (

                    <Col lg={12} key={index} className='mt-0'>

                      <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                        <CardBody className="px-2 py-0">

                          <div className="d-flex align-items-center row px-2">

                            <div className="col-7 col-md-2 order-0 order-md-0 px-1 px-md-2">

                              <a href="#!" > <span onClick={() => { handleViewClick(item); }}>  {item.user?.name}</span>  </a>

                            </div>

                            <div className="col-5 col-md-1 order-0 order-md-0 d-flex justify-content-end justify-content-md-start align-items-center px-1 px-md-0">
                              <p className="mb-0"> {DefaultDate(item.date, selectedCountry)}  </p>

                            </div>

                            <div className="col-6 col-md-2 order-1 order-md-1 px-1 px-md-3">
                              <p className="mb-0"> {item.expense_category?.name} </p>
                            </div>

                            <div className={`${userDetails?.roles?.[0]?.name !== "Admin" ? "col-8 d-none d-md-flex" : "col-8 "} ${isExpanded?.[index] ? "d-block" : "d-flex"} col-6 col-md-3 order-4 order-md-1 align-items-center px-1 `}>

                              <p className="mb-0"> {isExpanded?.[index] ? item?.purpose : item?.purpose?.length > 20 ? item?.purpose?.slice(0, 20) + "..." : item?.purpose}
                                {item?.purpose?.length > 20 && (
                                  <a href="#!" key={item.id} onClick={() => handleToggle(index)}>
                                    {isExpanded?.[index] ? ' Read Less' : ' Read More'}
                                  </a>
                                )}
                              </p>

                            </div> 

                            <div className="col-6 col-md-1 order-2 order-md-2 align-items-center d-flex justify-content-end px-1">
                              <p className={`mb-0 fw-bold`}>{FormatNumber(item?.amount, selectedCountry)}</p>
                            </div>

                            <div className="col-12 col-md-1 order-2 order-md-2 align-items-center d-none d-md-flex justify-content-end px-0">
                              <p className="mb-0"> {DefaultDate(item.created_at, selectedCountry)} </p>

                            </div>

                            <div className={`${userDetails?.roles?.[0]?.name !== "Admin" ? "d-flex justify-content-start" : "d-none d-md-flex justify-content-end"} col-4 col-md-1 order-3 order-md-3 align-items-center  px-2 px-md-0 `}>

                              <p className="mb-0"> {item.status_date ? DefaultDate(item.status_date, selectedCountry) : ""}</p>

                            </div>

                            <div className={`${userDetails?.roles?.[0]?.name !== "Admin" ? "d-flex d-md-none" : "d-none"} col-4 col-md-1 order-4 order-md-4 align-items-center `}   >

                              <p className="mb-0">  {item.status?.name} </p>
                            </div> 

                            <div className={`col-4 col-md-1 order-5 order-md-5 d-flex gap-0 align-items-center justify-content-end px-0 px-md-2`}>

                              {item.expense_attachments?.length > 0 ?
                                <button type="button" className="btn  btn-sm btn-icon btn25" onClick={(e) => { viewAttachmet(item.id) }}>  <i className="ri-attachment-2 fs-18 primary"  ></i>
                                </button> : " "}

                              <button type="button" className="btn  btn-sm btn-icon btn25" onClick={(e) => { handleEditClick(item); }}>   <i className="ri-pencil-fill fs-18 primary"  ></i>
                              </button>

                              <button type="button" className="btn btn-sm btn-icon btn25"  onClick={(e) => { onClickData(item); setExpenseView(item); }}>  <i className="ri-delete-bin-fill fs-18 secondary" ></i> 
                              </button>

                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                  ))

                  :
                  <div className="noresult" >
                    <div className="text-center">
                      <h6 className="mt-2">Sorry! No Result Found</h6>
                    </div>
                  </div>
            }
          </Row>

          {!loading && expenseList?.data?.length > 0 &&
            <Row className="row gy-1 mb-1 d-none d-md-flex" >
              <Col lg={12} className='mt-0'  >
                <Card className="mb-0 mt-0 border-0 border-bottom rounded-0  py-1">
                  <CardBody className="px-1 py-0">
                    <div className="d-none d-md-flex align-items-center row">
                      <div className="d-flex col-5">
                        <h5 className="fs-14 mb-0"> </h5>
                      </div>

                      <div className="  col-6 col-md-4 align-items-center d-flex justify-content-end">
                        <p className={`mb-0 fw-bold d-flex gap-2`}> <span> Total:   </span> <span>{FormatNumber(expenseTotal, selectedCountry)}</span></p>
                      </div>

                      <div className=" col-12 col-md-1">
                        <h5 className="fs-14 mb-0">    </h5>
                      </div>
                      <div className="mb-1 col-12 col-md-1">
                        <h5 className="fs-14 mb-0">   </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }

          <div className="d-flex justify-content-end  pagination-wrap hstack gap-2">
            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getExpensePage(1, e.target.value);
                }}
                value={rowPerPage}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={expenseList?.total}>All</option>

              </select>

              {/* <ul className="mb-0  pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                {currentPage <= 1 ? (
                  <Link className="page-item pagination-prev px-1 py-0 disabled" href="#!">
                    Previous
                  </Link>
                ) :
                  <li className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
                    <Link to="#!" className="page-link px-1 py-0" onClick={handleprevPage}>Previous</Link>
                  </li>
                }
                {renderPageNumbers()}
                {currentPage >= pageNumbers.length ? (
                  <Link className="page-item pagination-next px-1 py-0 disabled" href="#!">
                    Next
                  </Link>
                ) :
                  <li className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
                    <Link to="#!" className="page-link px-1 py-0" onClick={handlenextPage}>Next</Link>
                  </li>
                }
              </ul> */}


              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={expenseList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />

            </div>
          </div>

          <div className="modal fade" id="showAttachment" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="lg" isOpen={modalAttachment.show} toggle={() => {
                setModalAttachment({
                  ...modalAttachment,
                  show: false,
                  file: [],
                });
              }} centered>

                <ModalHeader className='bpBottom pb-2' toggle={() => {
                  setModalAttachment({
                    ...modalAttachment,
                    show: false,
                    file: [],
                  });
                }}  >
                  {"View Attachment"}

                </ModalHeader>

                <ModalBody >

                  {modalAttachment?.show ? <>
                    <div className='text-center pb-2' >
                      {/* <h5 className='text-secondary'>{modalAttachment?.file?.[currentSlide].name}</h5> <br/> */}
                      {modalAttachment?.file?.[0]?.path !== undefined ?
                        getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase() !== ("pdf" || "doc") ?
                          <img src={url + modalAttachment?.file?.[currentSlide]?.path} className='img' />

                          :
                          <iframe src={url + modalAttachment?.file?.[currentSlide]?.path} className='iframe' />

                        : "No Attachment Found"}

                    </div>
                  </>
                    : ""}


                  {modalAttachment?.file?.[0]?.path !== undefined ?
                    <div className='pb-2 text-center' >
                      <button className="btn btn-primary shadow-lg">
                        <a className='text-white' href={url + modalAttachment?.file?.[currentSlide]?.path} target='_blank' download>  Download</a>
                      </button>
                    </div>

                    : ""}


                  {modalAttachment?.file?.length > 1 ?

                    <div className="d-flex justify-content-center">
                      <div className="pagination-wrap hstack gap-2">
                        <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                          <FeatherIcon icon="arrow-left" size="20" />
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" onClick={goToNextSlide}>
                          <FeatherIcon icon="arrow-right" size="20" />
                        </Link>
                      </div>
                    </div>
                    : ""}
                </ModalBody>
              </Modal>
            </div>
          </div>


          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered"    >
              <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => {
                setModalConfirm(!modalConfirm)
              }} centered>

                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                  setModalConfirm(!modalConfirm)
                }}  >
                  {"Confirm"}

                </ModalHeader>

                <ModalBody >
                  <p> Are you sure you want to download {downloadFormat.value === "One-Time" ?
                    'One-Time Expense Report'
                    : downloadFormat.value === "Recurring" ?
                      'Recurring Expense Report'
                      : downloadFormat.value === "Annual" ?
                        'Annual Expense Report' : 'Expense Report'}  </p>


                </ModalBody >

                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => {
                      setModalConfirm(false);
                      setDownloadFormat({
                        value: "", label: "Select Report   "
                      });
                    }} > No </Button>
                    <Button type="submit" color="success" id="add-btn" onClick={(e) => {
                      handleDownload(downloadFormat?.value);
                      setDownloadFormat({
                        value: "", label: "Select Report   "
                      });
                    }} > Yes  </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>


          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary  p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">{view ? "View Expense" : !isEdit ? "Add Expense" : "Update Expense"}</span>

                <span>
                  {!view && <span className="fs-18 px-1 text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span>}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>
              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">

                  {view ? <>

                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Expense By  </Label> <br />
                          {expenseView?.user?.name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Expense For </Label> <br />
                          {expenseView?.expense_category?.name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Description </Label> <br />
                          {expenseView?.purpose}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Amount </Label>
                          <br />
                          {FormatNumber(expenseView?.amount, selectedCountry)}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Date </Label>
                          <br />
                          {DefaultDate(expenseView?.date, selectedCountry)}
                        </div>
                      </Col>



                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Status </Label>
                          <br />
                          <Badge color={`${expenseView?.expense_status?.name === "Approved" ? "success-subtle text-success" :
                            expenseView?.expense_status?.name === "Reject" ? "danger-subtle text-danger"
                              : "waring-subtle text-waring"}`} className="ms-auto">{expenseView?.expense_status?.name}</Badge>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Applied On </Label>
                          <br />
                          {DefaultDate(expenseView?.created_at, selectedCountry)}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Approved at </Label>
                          <br />

                          {expenseView?.status_date ? DefaultDate(expenseView?.status_date, selectedCountry) : ""}
                        </div>

                      </Col>
                    </Row>

                  </>
                    :

                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <Row>


                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="roleName" className="form-label"> Expense For <span className='secondary'>*</span></Label>


                            <Select

                              defaultValue={
                                validation.values.expense_category?.name ? {
                                  "value": validation.values.expense_category_id,
                                  "label": validation.values.expense_category?.name
                                } : ""}
                              options={categoryList}

                              placeholder="Select..."
                              name='expense_category_id'
                              onChange={(e) => {

                                validation.setValues({
                                  ...validation.values,
                                  expense_category_id: e?.value
                                });
                              }}

                              styles={selectStyle('expense_category_id')}

                            />

                          </div>
                        </Col>


                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="amount" className="form-label">Amount <span className='secondary'>*</span></Label>
                            <Input type="number" className="form-control px-2 py-1" id="amount" placeholder="Enter amount"
                              name='amount'

                              onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                              onChange={(e) => {

                                let inputValue = e.target.value;

                                if (inputValue.length > 10) {
                                  inputValue = inputValue.slice(0, 10);
                                }

                                validation.handleChange({
                                  target: {
                                    name: 'amount',
                                    value: inputValue,
                                  },
                                });

                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.amount || ""}
                              invalid={submit && validation.errors.amount ? true : false
                              }

                            />

                          </div>

                        </Col>

                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="purpose" className="form-label">Description</Label>
                            <Input type="textarea" className="form-control px-2 py-1" id="purpose" placeholder="Enter purpose"
                              name='purpose'

                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.purpose || ""}
                              invalid={submit && validation.errors.purpose ? true : false}
                              maxLength={250}
                              //   style={{ height: userDetails?.roles?.[0]?.name === "Admin" ?'165px' : "100px", minHeight: '50px', maxHeight: userDetails?.roles?.[0]?.name === "Admin" ?'165px' : "100px", }} 
                              style={{ height: isEdit && userDetails?.roles?.[0]?.name === "Admin" && validation.values.user_id !== userDetails?.id ? '165px' : "100px", minHeight: '50px', maxHeight: isEdit && userDetails?.roles?.[0]?.name === "Admin" && validation.values.user_id !== userDetails?.id ? '165px' : "100px", }}
                              multiline="true"

                            />

                          </div>
                        </Col>

                        <Col lg={6}>
                          <Row>

                            <Col lg={12}>

                              <div className="mb-2">
                                <Label htmlFor="payment_type" className="form-label">Payment Mode <span className='secondary'>*</span> </Label>

                                <Select

                                  defaultValue={validation.values.payment_type ? paymentTypeOptions?.filter(
                                    item => item?.value === validation.values.payment_type)?.map((item, index) => {

                                      return (
                                        { value: item.value, label: item.label }
                                      );
                                    }) : ""}


                                  onChange={(e) => {

                                    validation.setValues({
                                      ...validation.values,
                                      payment_type: e?.value
                                    });


                                  }}
                                  options={paymentTypeOptions}

                                  styles={selectStyle('payment_type')}
                                />

                              </div>

                            </Col>

                            <Col lg={12}>

                              <div className="mb-2">
                                <Label htmlFor="date" className="form-label">Date
                                  <span className='secondary'>*</span></Label>


                                <Flatpickr
                                  name='date'
                                  className={`form-control py-1 px-2 ${submit && validation.errors.date ? 'is-invalid' : ''}`}

                                  options={{
                                    allowInput: true,
                                    dateFormat: SmallDateFormat(selectedCountry),
                                  }}
                                  onChange={(selectedDates, dateStr, instance) => {

                                    validation.setValues({
                                      ...validation.values,
                                      date: dateStr
                                    });
                                  }}

                                  value={validation.values.date || ""}
                                />
                              </div>
                            </Col>

                            <Col lg={12}>

                              {isEdit && userDetails?.roles?.[0]?.name === "Admin" && validation.values.user_id !== userDetails?.id &&
                                <div className="mb-2">
                                  <Label htmlFor="roleName" className="form-label">Status <span className='secondary'>*</span></Label>

                                  <Select
                                    defaultValue={validation.values.expense_status_id ? statusList?.filter(
                                      item => item?.id === validation.values.expense_status_id)?.map((item, index) => {

                                        return (
                                          { value: item.id, label: item.name }
                                        );
                                      }) : ""}


                                    onChange={(e) => {

                                      validation.setValues({
                                        ...validation.values,
                                        expense_status_id: e?.value
                                      });


                                    }}
                                    options={statusList?.map((item, index) => {

                                      return (
                                        { value: item.id, label: item.name }
                                      );
                                    })}

                                    styles={selectStyle('expense_status_id')}
                                  />

                                </div>

                              }
                            </Col>
                          </Row>

                        </Col>

                        <Col lg={12} className='py-2'>
                          <div className="mb-2">
                            <Label htmlFor="attachment" className="form-label">Upload Attachment</Label>
                            <Input type="file" accept=".pdf, image/*" className="form-control px-2 py-1" id="attachment"
                              name='attachment'

                              onChange={handleFileChange}

                              multiple />

                          </div>

                          <Row className='py-2  '>
                            {(files || [])?.map((item, index) => {

                              return [
                                <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                  <FaTimesCircle className="close-button" onClick={(e) => {

                                    setFiles(
                                      files?.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                    );
                                    setFile(
                                      file?.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                    );
                                  }} />
                                  <div className="selected-image cursor" onClick={(e) => {
                                    setModalFile(true);
                                    // setFileUrl(item);  
                                    setFileUrl({ name: item.name, path: item.preview });
                                  }}>
                                    {imageExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                      <img
                                        src={item.preview}
                                        alt={item.name}
                                        width={100}
                                        height={80}
                                      />
                                      : pdfExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                        <FaFilePdf size={30} />
                                        : excelExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                          <FaFileExcel size={30} />
                                          : <FaFileAlt size={30} />

                                    }

                                  </div>
                                </Col>

                              ]
                            })}

                            {attachmentList?.map((item, index) => {

                              return [

                                <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >

                                  <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                    handleDelete(item.id);
                                  }} />

                                  <div className="selected-image cursor" onClick={(e) => {
                                    setModalFile(true); setFileUrl({ name: item.path, path: url + item.path });
                                  }}>


                                    {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                      <img
                                        src={url + item.path}
                                        alt={item.name}
                                        width={100}
                                        height={80}
                                      />
                                      : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                        <FaFilePdf size={30} />
                                        : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                          <FaFileExcel size={30} />
                                          : <FaFileAlt size={30} />

                                    }

                                  </div>
                                </Col>
                              ]
                            })}</Row>
                        </Col>


                        <div className="modal fade" tabIndex="-1" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <Modal size="lg" isOpen={modalFile} toggle={() => {
                              setModalFile(false);
                            }} centered>

                              <ModalHeader className='bpBottom pb-2' toggle={() => {
                                setModalFile(false);
                              }}  >
                                {"View Attachment"}

                              </ModalHeader>

                              <ModalBody >


                                {modalFile && <>

                                  <div className='text-center py-4' >

                                    {imageExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                      <img

                                        src={fileUrl.path}
                                        alt={fileUrl.name}
                                        width={isDesktop ? 500 : "100%"}
                                        height={"100%"}

                                      />
                                      : pdfExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                        <iframe src={fileUrl.path} className='iframe' />
                                        : excelExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                          <>  {fileUrl.name}</>
                                          : <>  {fileUrl.name}</>

                                    }

                                  </div>
                                </>
                                }

                                <div className='pt-2 text-center' >
                                  <button className="btn btn-primary shadow-lg">
                                    <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                                  </button>
                                </div>

                              </ModalBody>
                            </Modal>
                          </div>
                        </div>

                        <Col lg={12}  >
                          <div className="hstack gap-2 justify-content-end">

                            <button type="submit" disabled={validation.isSubmitting ? true : false}
                              className="btn btn-primary shadow-lg" id="addNewExpense" onClick={() => {
                                setSubmit(true);
                              }}> {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                              {!isEdit ? "Add Expense" : "Update Expense"}</button>
                          </div>
                        </Col>
                      </Row>
                    </Form>

                  }

                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Expense;