import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Button, Offcanvas, Card, CardBody, FormGroup, OffcanvasHeader, Spinner, Badge, OffcanvasBody, Col, Container, ModalFooter,
    ModalHeader, Form, Input, Label, Modal, ModalBody, Row,
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import Select from "react-select";
import { FaCheck, FaTimesCircle, FaTimes, FaPlus, FaFilePdf, FaFileAlt, FaFileExcel, FaCloudDownloadAlt, } from "react-icons/fa";
import Flatpickr from "react-flatpickr";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import FeatherIcon from 'feather-icons-react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import Pagination from "react-js-pagination";
import { FormatDate, FormatMinDate, FormatMaxBalanceDate, ToastMessage, DefaultDate, SmallDateFormat } from "../../Components/Hooks/Function";

const Leave = () => {
    document.title = "Leave Status";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    var moment = require('moment-timezone');
    const [searchList, setSearchList] = useState([]);
    const userDetails = getLoggedinUser()?.user;
    // const [userDetails, setUserDetails] = useState([]);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    const [deleteModal, setDeleteModal] = useState(false);
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const [leaveList, setLeaveList] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [leaveView, setLeaveView] = useState(null)
    const [view, setView] = useState(false);
    const [focus, setFocus] = React.useState(""); 
    const [hidden, setHidden] = useState(false);
    const [fileUrl, setFileUrl] = useState([]);
    const [settingsList, setSettingsList] = useState([]);
    const [leaveCount, setLeaveCount] = useState([]);
    const [balanceCount, setBalanceCount] = useState(0);
    const [statusList, setStatusList] = useState([
        { value: "Pending", label: "Pending" },
        { value: "Approved", label: "Approved" },
        { value: "Reject", label: "Reject" },
    ]);
    const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
    const pdfExtensions = new Set(["pdf"]);
    const excelExtensions = new Set(["xls", "xlsx"]);
    const [modalFile, setModalFile] = useState(false);
    const [modalStartTime, setModalStartTime] = useState(false);
    const [modalEndTime, setModalEndTime] = useState(false);
    const [startTime, setStartTime] = React.useState(dayjs('2024-01-01T00:00'));
    const [endTime, setEndTime] = React.useState(dayjs('2024-01-01T00:00'));
    const [modalReport, setModalReport] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const goToNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % modalAttachment?.file?.length);
    };
    const goToPreviousSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? modalAttachment?.file?.length - 1 : prevSlide - 1
        );
    };

    const handleStartTimeChange = (newTime) => {
        setStartTime(newTime);
        validation.setValues({
            ...validation.values,
            start_time: newTime.format('HH:mm:ss')
        });

        if(focus === "seconds"){
            setModalStartTime(false);
          } 
    };

    const handleEndTimeChange = (newTime) => {
        setEndTime(newTime);
        validation.setValues({
            ...validation.values,
            end_time: newTime.format('HH:mm:ss')
        });

        if(focus === "seconds"){
            setModalEndTime(false);
          }
    };

    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [fromValue, setFromValue] = useState(moment().clone().tz(moment.tz.guess()).startOf('month'));
    const [toValue, setToValue] = useState(moment().clone().tz(moment.tz.guess()).endOf('month'));

    const getDefaultDate = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        return [firstDayOfMonth, lastDayOfMonth];
    };

    const handleDateChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setFromValue(selectedDates[0]);
            setToValue(selectedDates[1]);
            filterValidation.handleSubmit();
        }
    };

    const selectStyle = {
        container: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",

        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            margin: "0px 0px 5px 0px",
            minHeight: "30px",

        }),
        dropdownButton: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
            height: "30px",
            padding: "5px 8px",
            boxWidth: "none",
            boxShadow: "none",
            color: "rgb(118, 118, 118)",
            border: "1px solid #ced4da",
            "& svg": {
                color: "hsl(0, 0%, 80%)",
                height: "35",
                width: "35"
            }

        }),
        groupHeading: (provided) => ({
            fontWeight: 'bold',
            // color: '#019aff',
            padding: "5px 12px",
            paddingLeft: '6px',
            cursor: "pointer",
            alignItems: 'center',
            display: 'flex',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            cursor: "pointer",
            paddingLeft: state.data.label === 'Select All' ? '6px' : '16px',
            fontWeight: state.data.label === 'Select All' && 'bold',
            // color: state.data.label === 'Select All' && '#019aff',
            // padding:"5px 12px",
            height: "30px",
            boxShadow: "none",
            display: 'flex',
            alignItems: 'center',
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: "transparent",
            },
            "&:active": {
                backgroundColor: "unset",
            },
        }),
    };

    const selectStyles = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus

            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        };
    };

    const selectStausStyles = (fieldName) => {
        return {
            container: (provided, state) => ({
                ...provided,
            }),
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '20px',
                minHeight: '20px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '20px',
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                padding: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '20px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus

            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
            placeholder: (provided, state) => ({
                ...provided,
                marginTop: '-1px',
            }),

        };
    };


    const handleAddClick = (arg) => {

        setSubmit(false);
        setView(false);
        setHidden(false);
        setStartTime(dayjs('2024-04-17T15:30'));
        setEndTime(dayjs('2024-04-17T15:30'));
        validation.resetForm();
        validation.setValues({
            start_date: DefaultDate("", selectedCountry),
            end_date: DefaultDate("", selectedCountry),
            user_id: userDetails?.roles?.[0]?.name === "Admin" ? "" : userDetails?.id,
            leave_duration: 'Single',
            half_day_period: 'First',
        });

        if (userDetails?.roles?.[0]?.name !== "Admin") {
            getLeaveCount(userDetails.id);
        }
        setIsEdit(false);
        toggleDrawer();
    };


    const handleViewClick = (arg) => {
        setView(true);
        viewLeave(arg?.id);
        validation.resetForm();
        setIsEdit(false);
    };

    const onClickData = () => {
        setDeleteModal(true);
    };


    const [modalAttachment, setModalAttachment] = useState({
        show: false,
        file: [],
    });

    const viewAttachmet = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get('leaveAttachment/' + id, {});
            if (response.data.status === true) {
                setModalAttachment({ ...modalAttachment, show: true, file: response.data.data });
            } else {
                setModalAttachment({ ...modalAttachment, show: true, file: [], });
            }

        } catch (err) {
            console.error(err.message);
        }
    }

    const handleDelete = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('leaveAttachment/' + id, {});
            if (response.data.status === true) {
                setAttachmentList(attachmentList?.filter(item => item.id !== id));
                setFile(file?.filter(item => item.id !== id));
                setFiles(files?.filter(item => item.id !== id));
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getLeaveCount = async (user_id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('leaveCount/' + user_id, {});
            if (response.data.status === true) {
                setLeaveCount(response.data.data);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    const [userList, setUserList] = useState([]);
    const [file, setFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [attachmentList, setAttachmentList] = useState([]);
    const getSettings = async () => {
        setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
        setSettingsList(getSettingsDetails("settings"));
    };

    useEffect(() => {
        getSettings();
    }, []);


    const handleFileChange = (e) => {
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
        const files = Array.from(e.target.files);
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        if (totalSize <= maxSizeInBytes) {
            const allowedFiles = files.filter((file) =>
                file.type === 'application/pdf' || file.type.startsWith('image/')
            );
            setFile(allowedFiles);
            setFiles(
                allowedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        } else {
            setFile([]);
            setFiles([]);

            ToastMessage("error", "Uplaoad File is Greater than 10 MB");
        }
    }

    function getExtension(filename) {
        return filename?.split('.').pop()
    }

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.user?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.leave_type?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                DefaultDate(item.start_date, selectedCountry)?.toLowerCase().includes(inputVal.toLowerCase()) ||
                DefaultDate(item.end_date, selectedCountry)?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.start_time?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.end_time?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.status.toLowerCase().includes(inputVal.toLowerCase()) ||
                (DefaultDate(item.start_date, selectedCountry) + " - " + DefaultDate(item.end_date, selectedCountry))?.toLowerCase().includes(inputVal.toLowerCase()) ||
                (DefaultDate(item.start_date, selectedCountry) + " (" + item.half_day_period + " half)")?.toLowerCase().includes(inputVal.toLowerCase()) ||
                (DefaultDate(item.start_date, selectedCountry) + " (" + item.start_time + " - " + item.end_time + ")")?.toLowerCase().includes(inputVal.toLowerCase())
            );

            setLeaveList({
                ...leaveList,
                data: filteredResults
            });

        } else {
            setLeaveList({
                ...leaveList,
                data: searchList?.data
            });
        }
    };

    const filterValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            status: statusList?.map((item, index) => {
                return (
                    { value: item.value, label: item.label }
                );
            }),
            startDate: '',
            endDate: '',
            user_id: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
                return (
                    { value: item.id, label: item.name }
                );
            }),
        },
        validationSchema: Yup.object({
            status: Yup.array(),
            user_id: Yup.array(),
            startDate: Yup.date(),
            endDate: Yup.date(),
        }),
        onSubmit: async (values) => {
            api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
            api.defaults.headers.common['Content-Type'] = 'application/json';
            try {
                const response = await api.get(`leave`, {
                    params: {
                        status: statusParam,
                        user_id: user_idParam,
                        startDate: startDateParam,
                        endDate: endDateParam,
                        page: currentPage,
                        perPage: rowPerPage
                    }
                });

                if (response.data.status === true) {
                    setSearchList(response.data?.data);
                    setLeaveList(response.data?.data);

                } else {
                    setSearchList([]);
                    setLeaveList([]);
                }
            } catch (err) {
                console.error(err.message);
            }
        },
    });

    const startDateParam = fromValue ? `${moment(fromValue).format('YYYY-MM-DD')}` : '';
    const endDateParam = toValue ? `${moment(toValue).format('YYYY-MM-DD')}` : '';
    const user_idParam = userDetails?.roles?.[0]?.name === "Admin" ? filterValidation?.values?.user_id ? filterValidation.values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '' : '';
    const statusParam = filterValidation?.values?.status ? filterValidation.values.status?.map((value) => (value.value)).filter(value => value !== "") : '';

    useEffect(() => {
        getLeave();
    }, []);

    const handleDownload = async () => {
        setModalReport(false);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`leaveReport`, {
                params: {
                    startDate: startDateParam,
                    endDate: endDateParam,
                    companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
                    logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
                    selectedCountry: selectedCountry,
                    status: statusParam,
                    user_id: user_idParam,
                }, responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Leave Report.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (err) {
            console.error(err.message);
        }
    };

    const getLeave = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`leave`,
                {
                    params: {
                        status: statusParam,
                        user_id: user_idParam,
                        startDate: startDateParam,
                        endDate: endDateParam,
                        page: currentPage,
                        perPage: rowPerPage
                    }
                }
            );

            if (response.data.status === true) {
                setSearchList(response.data?.data);
                setLeaveList(response.data?.data);
            } else {
                setSearchList([]);
                setLeaveList([]);
                ToastMessage("error", response.data?.message);
            }

            setLoading(false);
            setCurrentPage(1);
        } catch (err) {
            console.error(err.message);
        }
    };

    const getLeaveType = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`getLeaveType`, {});

            if (response.data.status === true) {
                setLeaveTypeList(response.data?.data);
            } else {
                setLeaveTypeList([]);
                ToastMessage("error", response.data?.message);
            }
            setLoading(false);
            setCurrentPage(1);
        } catch (err) {
            console.error(err.message);
        }
    };


    const getLeavePage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`leave`,
                {
                    params: {
                        status: statusParam,
                        user_id: user_idParam,
                        startDate: startDateParam,
                        endDate: endDateParam,
                        page: currentPage,
                        perPage: rowPerPage
                    }
                }
            );

            if (response.data.status === true) {
                setSearchList(response.data?.data);
                setLeaveList(response.data?.data);
            } else {
                setSearchList([]);
                setLeaveList([]);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    const viewLeave = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('leave/' + id, {});

            if (response.data.status === true) {
                validation.setValues(response.data?.data);
                setLeaveView(response.data?.data);
            } else {
                setLeaveView([]);
            }
            setOpen(true);
        } catch (err) {
            console.error(err.message);
        }
    };

    const addLeave = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    if (key === "start_date") {
                        formData.append('start_date', value.start_date ? FormatDate(value.start_date, selectedCountry) : "");
                    } else if (key === "end_date") {
                        formData.append('end_date', value.end_date && value.leave_duration === "Multi" ?
                            FormatDate(value.end_date, selectedCountry)
                            :
                            FormatDate(value.start_date, selectedCountry)
                        );
                    } else if (key === "half_day_period") {
                        formData.append('half_day_period', value.leave_duration === "Half" ? value.half_day_period : "");
                    } else {
                        formData.append(key, value[key] || '');
                    }
                }
            }
            Object.keys(file).forEach(key => {
                const files = file[key];
                if (files) {
                    formData.append('attachment[]', files);
                }
            });
            const response = await api.post('leave', formData);
            if (response.data.status === true) {
                getLeave();
                validation.resetForm();
                toggleDrawer();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }
    };

    const editLeave = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('leave/' + value.id, value);
            if (response.data.status === true) {
                getLeave();
                validation.resetForm();
                setIsEdit(false);
                toggleDrawer();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteLeave = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('leave/' + value?.id, {});
            if (response.data.status === true) {
                getLeave();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }

    };


    const getAllUser = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('getAllUser', {});

            if (response.data.status === true) {
                setUserList(response.data?.data);
            } else {
                setUserList([]);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    useEffect(() => {
        getAllUser();
        getLeaveType();
    }, []);

    const changestatus = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`leaveStatusUpdate`, {
                id: value.id,
                status: value.status,
                reason: value.reason,
            });
            if (response.data.status === true) {
                getLeave();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setModalConfirm(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_id: '',
            leave_type_id: '',
            reason: '',
            leave_duration: '',
            half_day_period: '',
            date: '',
            start_date: "",
            end_date: "",
            start_time: "",
            end_time: "",
        },

        validationSchema: Yup.object({
            user_id: userDetails?.roles?.[0]?.name === "Admin" ? Yup.string().required("Enter") : Yup.string().notRequired(),
            leave_type_id: Yup.string().required("Enter"),
            leave_duration: Yup.string().notRequired(),
            reason: Yup.string().notRequired(),
            start_date: Yup.string().when('leave_duration', {
                is: (leave_duration) => ['Single', 'Half', 'Hours', 'Multi'].includes(leave_duration),
                then: (schema) => schema.required("Enter"),
                otherwise: (schema) => schema,
            }),
            end_date: Yup.string().when('leave_duration', {
                is: (leave_duration) => ['Multi'].includes(leave_duration),
                then: (schema) => schema.required("Enter"),
                otherwise: (schema) => schema,
            }),
            start_time: Yup.string().when('leave_duration', {
                is: (leave_duration) => ['Hours'].includes(leave_duration),
                then: (schema) => schema.required("Enter"),
                otherwise: (schema) => schema,
            }),
            end_time: Yup.string().when('leave_duration', {
                is: (leave_duration) => ['Hours'].includes(leave_duration),
                then: (schema) => schema.required("Enter"),
                otherwise: (schema) => schema,
            }),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEdit) {
                    await editLeave(values, validation.values?.id);
                } else {
                    await addLeave(values);
                }
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });


    const statusValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: '',
            status: '',
            reason: ''
        },

        validationSchema: Yup.object({
            id: Yup.string().notRequired(),
            status: Yup.string().notRequired(),
            reason: Yup.string().notRequired(),
            // reason: Yup.string().when('status', {
            //     is: (status) => status === 'reject', 
            //     then: (schema) => schema.required("Enter"),
            //     otherwise: (schema) => schema,
            //   }),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await changestatus(values, validation.values?.id);
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const handleUserSelectionChange = (selected) => {
        filterValidation.setValues({
            ...filterValidation.values,
            user_id: selected.length > 0 ? selected : [{
                name: "Select...",
                value: 0
            }]
        });
        filterValidation.handleSubmit();
    };


    const handleStatusChange = (selected) => {
        filterValidation.setValues({
            ...filterValidation.values,
            status: selected
        });
        filterValidation.handleSubmit();
    };

    const handleClick = (e) => {
        setCurrentPage(e);
        getLeavePage(e, rowPerPage);
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => deleteLeave(leaveView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Leave Status</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className='d-block d-md-flex align-items-center'  >
                        <Col className="col-sm-5">
                            {userDetails?.roles?.[0]?.name === "Admin" &&
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    filterValidation.handleSubmit();
                                    return false;
                                }}>
                                    <Row  >
                                        <Col xs={12} md={6} >
                                            <Flatpickr
                                                className="form-control py-1 px-2"
                                                placeholder="Select Date Range"
                                                options={{
                                                    allowInput: true,
                                                    mode: 'range',
                                                    dateFormat: SmallDateFormat(selectedCountry),
                                                    defaultDate: getDefaultDate(),
                                                }}
                                                onChange={handleDateChange}
                                            />
                                        </Col>

                                        <Col xs={6} md={3} className='pe-2 px-md-0 py-1 py-md-0' >

                                            <ReactMultiSelectCheckboxes
                                                value={filterValidation.values.status.map((item, index) => {
                                                    return ({ value: item.value, label: item.label });
                                                })}

                                                options={[{
                                                    label: "Select All",
                                                    options: statusList?.map((item, index) => {
                                                        return ({ value: item.value, label: item.label });
                                                    }),
                                                }]}

                                                onChange={handleStatusChange}
                                                styles={selectStyle}
                                            />


                                        </Col>

                                        <Col xs={6} md={3} className='   py-1 py-md-0' >
                                            <ReactMultiSelectCheckboxes
                                                value={filterValidation.values.user_id?.filter(item => item?.value !== 0)?.map((item, index) => {
                                                    return ({ value: item.value, label: item.label });
                                                })}

                                                options={[{
                                                    label: "Select All",
                                                    options: userList?.filter(item => item?.id !== 1).map((item, index) => {
                                                        return ({ value: item.id, label: item.name });
                                                    }),
                                                }]}

                                                onChange={handleUserSelectionChange}
                                                styles={selectStyle}
                                            />
                                        </Col>
                                    </Row>
                                </Form>}
                        </Col>
                        <Col className="col-sm-auto ms-auto d-flex gap-2">
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                <i className="ri-search-line search-icon"></i>
                            </div>

                            <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none   d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                                    <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Assign Leave </span></button>

                                <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} />

                            </div>
                            {userDetails?.roles?.[0]?.name === "Admin" && <div className="list-grid-nav hstack gap-1">
                                <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => { setModalReport(!modalReport); }} >
                                    <i className="ri-download-cloud-fill search-icon"></i> <span className='d-none d-md-block'> Download Report </span></button>

                                <FaCloudDownloadAlt className="text-primary d-flex d-md-none" size={25} onClick={() => { setModalReport(!modalReport); }} />
                            </div>}
                        </Col>
                    </Row>
                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                                        <div className="col-7 col-md-2   px-2">
                                            <h5 className="fs-15 mb-0"> Employee Name</h5>
                                        </div>

                                        <div className="col-5 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start px-md-2">
                                            <h5 className="fs-15 mb-0">Leave Type</h5>
                                        </div>

                                        <div className="col-6 col-md-4  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">
                                            <h5 className="fs-15 mb-0">Date </h5>
                                        </div>

                                        <div className="col-6 col-md-1  px-md-1 d-flex gap-2 align-items-center justify-content-end  justify-content-md-start ">
                                            <h5 className="fs-15 mb-0">Status  </h5>
                                        </div>

                                        <div className="col-12 col-md-1  d-flex gap-2 align-items-center justify-content-end px-2">
                                            <h5 className="fs-15 mb-0">Action</h5>
                                        </div>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1" id="leave-list">
                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>
                            :
                            leaveList?.data?.length !== 0 ?

                                (leaveList?.data)?.map((item, index) => (

                                    <Col lg={12} key={index} className='mt-0 '  >
                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                            <CardBody className="px-2 py-0">
                                                <div className="d-lg-flex align-items-center row">
                                                    <div className="col-7 col-md-2">
                                                        <a href="#!"  > <span onClick={() => { handleViewClick(item); }}>  {item.user?.name}</span>  </a>

                                                    </div>

                                                    <div className="col-5 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">
                                                        {item.leave_type?.name}
                                                    </div>
                                                    <div className="col-12 col-md-4  mx-auto d-flex gap-2 align-items-center">
                                                        {item.leave_duration === "Single" ?
                                                            DefaultDate(item.start_date, selectedCountry)
                                                            : item.leave_duration === "Multi" ?
                                                                DefaultDate(item.start_date, selectedCountry) + " - " + DefaultDate(item.end_date, selectedCountry)
                                                                : item.leave_duration === "Half" ?
                                                                    DefaultDate(item.start_date, selectedCountry) + " (" + item.half_day_period + " half)"
                                                                    :
                                                                    DefaultDate(item.start_date, selectedCountry) + " (" +
                                                                    item.start_time + " - " + item.end_time + ")"
                                                        }

                                                    </div>

                                                    <div className="col-10 col-md-1 px-md-0">
                                                        {userDetails?.roles?.[0]?.name === "Admin" ?

                                                            <Select
                                                                name="status"
                                                                value={item.status ? statusList?.filter(
                                                                    items => items?.value === item.status)?.map((items, index) => {
                                                                        return (
                                                                            { value: items.value, label: items.label }
                                                                        );
                                                                    }) : ""}


                                                                onChange={(e) => {
                                                                    setSubmit(false);
                                                                    setModalConfirm(true);
                                                                    statusValidation.resetForm();
                                                                    statusValidation.setValues({
                                                                        id: item.id,
                                                                        status: e?.value,
                                                                        reason: e?.value === item.status ? item.admin_reason : ""
                                                                    })
                                                                }}

                                                                options={statusList?.map((item) => {
                                                                    return (
                                                                        { value: item.value, label: item.label }
                                                                    );
                                                                })}
                                                                styles={selectStausStyles('status')}
                                                            />

                                                            : <Badge color={`${item?.status === "Approved" ? "success-subtle text-success" : "danger-subtle text-danger"}`}  >
                                                                {item?.status} </Badge>}

                                                    </div>

                                                    <div className="col-2 col-md-1 d-flex gap-0 align-items-center justify-content-end px-2">
                                                        {item.leave_attachments?.length > 0 ?
                                                            <button type="button" className="btn  btn-sm btn-icon btn25"
                                                                onClick={(e) => { viewAttachmet(item.id) }}>
                                                                <i className="ri-attachment-2 fs-18 primary"  ></i>
                                                            </button> : " "}

                                                        {userDetails?.roles?.[0]?.name === "Admin" ?
                                                            <button type="button" className="btn btn-sm btn-icon btn25"
                                                                onClick={(e) => { onClickData(item); setLeaveView(item); }}>
                                                                <i className="ri-delete-bin-fill fs-18 secondary" ></i>

                                                            </button> : item.status !== "Approved" &&
                                                            <button type="button" className="btn btn-sm btn-icon btn25"
                                                                onClick={(e) => { onClickData(item); setLeaveView(item); }}>
                                                                <i className="ri-delete-bin-fill fs-18 secondary" ></i>

                                                            </button>}
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                                :
                                <div className="noresult" >
                                    <div className="text-center">
                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                    </div>
                                </div>
                        }
                    </Row>


                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getLeavePage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={leaveList?.total}>All</option>

                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={leaveList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />

                        </div>
                    </div>

                    <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                        <div className="modal-dialog modal-dialog-centered"    >
                            <Modal size="md" backdrop={"static"} isOpen={modalReport} toggle={() => {
                                setModalReport(!modalReport)
                            }} centered>

                                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                                    setModalReport(!modalReport)
                                }}  >
                                    {"Confirm"}

                                </ModalHeader>
                                <ModalBody >
                                    <p>Are you sure you want to download Leave Report?</p>
                                </ModalBody >

                                <ModalFooter>
                                    <div className="hstack gap-2 justify-content-end">
                                        <Button color="light" onClick={() => {
                                            setModalReport(false);
                                        }} > No </Button>

                                        <Button type="submit" color="success" onClick={(e) => { handleDownload(); }} > Yes  </Button>

                                    </div>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>


                    <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                        <div className="modal-dialog modal-dialog-centered"    >
                            <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => {
                                setModalConfirm(!modalConfirm)
                            }} centered>

                                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                                    setModalConfirm(!modalConfirm)
                                }}  >
                                    {"Confirm"}

                                </ModalHeader>

                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    statusValidation.handleSubmit();
                                    return false;
                                }}>

                                    <ModalBody >
                                        <p> Are you sure you want to change status?</p>
                                        <div className="mb-2">
                                            <Label htmlFor="reason" className="form-label">Reason
                                            </Label>
                                            <Input type="text" className="form-control px-2 py-1" id="reason" placeholder="Enter reason"
                                                name='reason'
                                                onChange={(e) => {
                                                    statusValidation.setValues({
                                                        ...statusValidation.values,
                                                        reason: e.target.value
                                                    })
                                                }}
                                                invalid={
                                                    submit && statusValidation.errors.reason ? true : false
                                                }
                                                value={statusValidation.values.reason}
                                            />
                                        </div>
                                    </ModalBody >

                                    <ModalFooter>
                                        <div className="hstack gap-2 justify-content-end">
                                            <Button color="light" onClick={() => {
                                                setModalConfirm(false);
                                            }} > No </Button>

                                            <Button disabled={statusValidation.isSubmitting} type="submit" color="success" onClick={(e) => {
                                                setSubmit(true);
                                            }} >{statusValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} Yes  </Button>

                                        </div>
                                    </ModalFooter>

                                </Form>
                            </Modal>
                        </div>
                    </div>


                    <div className="modal fade" id="showAttachment" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <Modal size="lg" isOpen={modalAttachment.show} toggle={() => {
                                setModalAttachment({
                                    ...modalAttachment,
                                    show: false,
                                    file: [],
                                });
                            }} centered>

                                <ModalHeader className='bpBottom pb-2' toggle={() => {
                                    setModalAttachment({
                                        ...modalAttachment,
                                        show: false,
                                        file: [],
                                    });
                                }}  >
                                    {"View Attachment"}

                                </ModalHeader>
                                <ModalBody >
                                    {modalAttachment?.show ? <>
                                        <div className='text-center pb-2' >
                                            {/* <h5 className='text-secondary'>{modalAttachment?.file?.[currentSlide].name}</h5> <br/> */}
                                            {modalAttachment?.file?.[0]?.path !== undefined ?
                                                getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase() !== ("pdf" || "doc") ?
                                                    <img src={url + modalAttachment?.file?.[currentSlide]?.path} className='img' />
                                                    :
                                                    <iframe src={url + modalAttachment?.file?.[currentSlide]?.path} className='iframe' />

                                                : "No Attachment Found"}
                                        </div>
                                    </> : ""}


                                    {modalAttachment?.file?.[0]?.path !== undefined ?
                                        <div className='pb-2 text-center' >
                                            <button className="btn btn-primary shadow-lg">
                                                <a className='text-white' href={url + modalAttachment?.file?.[currentSlide]?.path} target='_blank' download>  Download</a>
                                            </button>
                                        </div>
                                        : ""}


                                    {modalAttachment?.file?.length > 1 ?
                                        <div className="d-flex justify-content-center">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                                                    <FeatherIcon icon="arrow-left" size="20" />
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" onClick={goToNextSlide}>
                                                    <FeatherIcon icon="arrow-right" size="20" />
                                                </Link>
                                            </div>
                                        </div>
                                        : ""}
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">{view ? "View Leave" : !isEdit ? "Assign Leave" : "Update Leave"}</span>
                                <span>
                                    {!view && <span className="fs-18 px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>}
                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">
                                    {view ? <>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Employee Name</Label> <br />
                                                    {leaveView?.user?.name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Leave Type </Label> <br />
                                                    {leaveView?.leave_type?.name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Date </Label> <br />
                                                    {leaveView?.leave_duration === "Single" ?
                                                        DefaultDate(leaveView?.start_date, selectedCountry)
                                                        : leaveView?.leave_duration === "Multi" ?
                                                            DefaultDate(leaveView?.start_date, selectedCountry) + " - " + DefaultDate(leaveView.end_date, selectedCountry)
                                                            : leaveView?.leave_duration === "Half" ?
                                                                DefaultDate(leaveView?.start_date, selectedCountry) + " (" + leaveView?.half_day_period + " half)"
                                                                :
                                                                DefaultDate(leaveView?.start_date, selectedCountry) + " (" + leaveView?.start_time + " - " + leaveView?.end_time + ")"
                                                    }
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Reason </Label>
                                                    <br />
                                                    {leaveView?.reason}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Status </Label>
                                                    <br />
                                                    <Badge color={`${leaveView?.status === "Approved" ? "success-subtle text-success" : "danger-subtle text-danger"}`} className="ms-auto">
                                                        {leaveView?.status}</Badge>
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Status Reason </Label>
                                                    <br />
                                                    {leaveView?.admin_reason}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Applied On </Label>
                                                    <br />
                                                    {DefaultDate(leaveView?.created_at, selectedCountry)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                        :
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <Row>
                                                {userDetails?.roles?.[0]?.name === "Admin" && <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="name" className="form-label">Employee <span className='secondary'>*</span></Label>
                                                        <Select

                                                            placeholder="Select Employee..."
                                                            defaultValue={validation.values.user_id ? userList?.filter(
                                                                item => item?.id === validation.values.user_id)?.map((item, index) => {
                                                                    return (
                                                                        { value: item.id, label: item.name }
                                                                    );
                                                                }) : ""}

                                                            onChange={(e) => {

                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    user_id: e?.value,
                                                                    leave_type_id: ""
                                                                });

                                                                if (e?.value !== "") {
                                                                    getLeaveCount(e?.value);
                                                                }
                                                            }}

                                                            options={
                                                                [
                                                                    { value: "", label: "Select" },
                                                                    ...userList?.filter(item => item?.id !== 1)?.map((item, index) => {

                                                                        return (
                                                                            { value: item.id, label: item.name }
                                                                        );
                                                                    })
                                                                ]}
                                                            styles={selectStyles('user_id')}
                                                        />
                                                    </div>

                                                </Col>}

                                                {validation.values.user_id !== "" &&
                                                    <Col lg={12}>

                                                        <Label className="form-label">Leave availability  <Link onClick={() => { setHidden(!hidden) }}>  {hidden ? "Hide" : "Show"}  </Link></Label>

                                                        {hidden &&
                                                            <div className='rounded p-3 mb-3 note-warning' >
                                                                <Row>

                                                                    {leaveTypeList.length !== 0 ?

                                                                        (leaveTypeList || []).map((item, index) => (
                                                                            <Col key={index} xs={6} className='px-1 px-md-2'>
                                                                                {item.name}: {
                                                                                    leaveCount?.filter(type => type?.name === item.code)[0]?.count >= 0 ?
                                                                                        ((Number(item?.monthly_allowed) * new Date().getMonth() + 1) -
                                                                                            leaveCount?.filter(type => type?.name === item.code)[0]?.count)

                                                                                        : Number(item?.monthly_allowed)
                                                                                }
                                                                            </Col>
                                                                        ))

                                                                        : <Col xs={6}> </Col>}
                                                                </Row>
                                                            </div>}
                                                    </Col>}

                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="leave_type_id" className="form-label">Leave Type <span className='secondary'>*</span></Label>

                                                        <Select
                                                            defaultValue={validation.values.leave_type_id ? (leaveTypeList)?.filter(
                                                                (item => item?.id === validation.values.leave_type_id)
                                                            )?.map((item, index) => {

                                                                return (
                                                                    { value: item.id, label: item.name }
                                                                );
                                                            }) : ""}


                                                            onChange={(e) => {

                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    leave_type_id: e?.value,
                                                                    leave_duration: e?.label === "Permission" ? 'Half' : 'Single'
                                                                });

                                                                setBalanceCount((leaveTypeList || [])?.filter((item => item?.id === e?.value))?.map((item, index) => {
                                                                    return (
                                                                        leaveCount?.filter(type => type?.name === item.code)[0]?.count >= 0 ?
                                                                            ((Number(item?.monthly_allowed) * new Date().getMonth() + 1) -
                                                                                leaveCount?.filter(type => type?.name === item?.code)[0]?.count)

                                                                            : Number(item?.monthly_allowed)
                                                                    )
                                                                })?.[0]);

                                                            }}

                                                            options={leaveTypeList?.filter((item) => {
                                                                return ((leaveCount?.filter(type => type?.name === item.code)[0]?.count >= 0 ?
                                                                    ((Number(item?.monthly_allowed) * new Date().getMonth() + 1) -
                                                                        leaveCount?.filter(type => type?.name === item.code)[0]?.count)
                                                                    : 1) > 0)
                                                            })?.map((item) => {
                                                                return { value: item.id, label: item.name };
                                                            })
                                                            }

                                                            styles={selectStyles('leave_type_id')}
                                                        />

                                                    </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <FormGroup tag={Row}>
                                                            <Label htmlFor="leave_duration" className="form-label" >Leave duration </Label>
                                                            <Col className="d-flex gap-2 align-items-center" >
                                                                {(leaveTypeList)?.filter(item => item?.id === validation.values.leave_type_id)[0]?.code !== "PR" &&
                                                                    <>
                                                                        <Label check className="d-flex gap-2 align-items-center">
                                                                            <Input
                                                                                type="radio"
                                                                                name="leave_duration"
                                                                                value="Single"
                                                                                checked={validation.values.leave_duration === 'Single'}
                                                                                onChange={validation.handleChange}

                                                                            />
                                                                            Single Day
                                                                        </Label>

                                                                        <Label check className="d-flex gap-2 align-items-center">
                                                                            <Input
                                                                                type="radio"
                                                                                name="leave_duration"
                                                                                value="Multi"
                                                                                checked={validation.values.leave_duration === 'Multi'}
                                                                                onChange={validation.handleChange}

                                                                            />
                                                                            Multi Day
                                                                        </Label>
                                                                    </>
                                                                }

                                                                {(((leaveTypeList)?.filter(item => item?.id === validation.values.leave_type_id)[0]?.code === "PR") ||
                                                                    ((leaveTypeList)?.filter(item => item?.id === validation.values.leave_type_id)[0]?.code === "WFH")) &&
                                                                    <>
                                                                        <Label check className="d-flex gap-2 align-items-center">
                                                                            <Input
                                                                                type="radio"
                                                                                name="leave_duration"
                                                                                value="Half"
                                                                                checked={validation.values.leave_duration === 'Half'}
                                                                                onChange={validation.handleChange}

                                                                            />
                                                                            Half Day
                                                                        </Label>

                                                                        <Label check className="d-flex gap-2 align-items-center">
                                                                            <Input
                                                                                type="radio"
                                                                                name="leave_duration"
                                                                                value="Hours"
                                                                                checked={validation.values.leave_duration === 'Hours'}
                                                                                onChange={validation.handleChange}

                                                                            />
                                                                            Hours
                                                                        </Label>
                                                                    </>

                                                                }

                                                            </Col>
                                                        </FormGroup>
                                                    </div>

                                                </Col>
                                                {validation.values.leave_duration === 'Single' ?
                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="start_date" className="form-label">Date <span className='secondary'>*</span></Label>
                                                            <Flatpickr
                                                                name='start_date'
                                                                className={`form-control py-1 px-2 ${submit && validation.errors.start_date ? 'is-invalid' : ''}`}

                                                                options={{
                                                                    allowInput: true,
                                                                    dateFormat: SmallDateFormat(selectedCountry),
                                                                }}
                                                                onChange={(selectedDates, dateStr, instance) => {
                                                                    validation.setValues({
                                                                        ...validation.values,
                                                                        start_date: dateStr
                                                                    });
                                                                }}

                                                                value={validation.values?.start_date || ""}
                                                            />
                                                        </div>
                                                    </Col>

                                                    : validation.values.leave_duration === 'Half' ?
                                                        <>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="start_date" className="form-label">Date <span className='secondary'>*</span></Label>

                                                                    <Flatpickr
                                                                        name='start_date'
                                                                        className={`form-control py-1 px-2 ${submit && validation.errors.start_date ? 'is-invalid' : ''}`}

                                                                        options={{
                                                                            allowInput: true,
                                                                            dateFormat: SmallDateFormat(selectedCountry),
                                                                        }}
                                                                        onChange={(selectedDates, dateStr, instance) => {
                                                                            validation.setValues({
                                                                                ...validation.values,
                                                                                start_date: dateStr
                                                                            });
                                                                        }}

                                                                        value={validation.values?.start_date || ""}

                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg={6}>
                                                                <Label htmlFor=" " className="form-label">    </Label>
                                                                <FormGroup tag={Row}>
                                                                    <Col className="d-flex gap-2 align-items-center" >
                                                                        <Label check className="d-flex gap-2 align-items-center">
                                                                            <Input
                                                                                type="radio"
                                                                                name="half_day_period"
                                                                                value="First"
                                                                                checked={validation.values.half_day_period === 'First'}
                                                                                onChange={validation.handleChange}

                                                                            />
                                                                            First half
                                                                        </Label>

                                                                        <Label check className="d-flex gap-2 align-items-center">
                                                                            <Input
                                                                                type="radio"
                                                                                name="half_day_period"
                                                                                value="Last"
                                                                                checked={validation.values.half_day_period === 'Last'}
                                                                                onChange={validation.handleChange}

                                                                            />
                                                                            Last half
                                                                        </Label>
                                                                    </Col>
                                                                </FormGroup>
                                                            </Col>
                                                        </>
                                                        : validation.values.leave_duration === 'Multi' ?
                                                            <>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="start_date" className="form-label">Start date <span className='secondary'>*</span></Label>
                                                                        <Flatpickr
                                                                            name='start_date'
                                                                            className={`form-control py-1 px-2 ${submit && validation.errors.start_date ? 'is-invalid' : ''}`}
                                                                            options={{
                                                                                allowInput: true,
                                                                                dateFormat: SmallDateFormat(selectedCountry),
                                                                            }}
                                                                            onChange={(selectedDates, dateStr, instance) => {
                                                                                validation.setValues({
                                                                                    ...validation.values,
                                                                                    start_date: dateStr
                                                                                });
                                                                            }}
                                                                            value={validation.values?.start_date || ""}
                                                                        />

                                                                    </div>
                                                                </Col>

                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="end_date" className="form-label">End date   <span className='secondary'>*</span></Label>
                                                                        <Flatpickr
                                                                            name='end_date'
                                                                            className={`form-control py-1 px-2 ${submit && validation.errors.end_date ? 'is-invalid' : ''}`}
                                                                            options={{
                                                                                allowInput: true,
                                                                                minDate: FormatMinDate(validation.values.start_date, selectedCountry),
                                                                                maxDate: (((leaveTypeList)?.filter(item => item?.id === validation.values.leave_type_id)[0]?.code === "LOP") ||
                                                                                    ((leaveTypeList)?.filter(item => item?.id === validation.values.leave_type_id)[0]?.code === "WFH")) ? null :
                                                                                    FormatMaxBalanceDate(validation.values.start_date, balanceCount, selectedCountry),
                                                                                dateFormat: SmallDateFormat(selectedCountry),
                                                                                onReady: function (selectedDates, dateStr, instance) {
                                                                                    instance.set('minDate', validation.values.start_date, selectedCountry);
                                                                                }
                                                                            }}

                                                                            onChange={(selectedDates, dateStr, instance) => {
                                                                                validation.setValues({
                                                                                    ...validation.values,
                                                                                    end_date: dateStr
                                                                                });
                                                                            }}
                                                                            value={validation.values?.end_date || ""}

                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </>

                                                            :
                                                            <>
                                                                <Col lg={12}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="start_date" className="form-label">Date <span className='secondary'>*</span></Label>
                                                                        <Flatpickr
                                                                            name='start_date'
                                                                            className={`form-control py-1 px-2 ${submit && validation.errors.start_date ? 'is-invalid' : ''}`}
                                                                            options={{
                                                                                allowInput: true,
                                                                                dateFormat: SmallDateFormat(selectedCountry),
                                                                            }}
                                                                            onChange={(selectedDates, dateStr, instance) => {
                                                                                validation.setValues({
                                                                                    ...validation.values,
                                                                                    start_date: dateStr
                                                                                });
                                                                            }}
                                                                            value={validation.values?.start_date || ""}
                                                                        />
                                                                    </div>
                                                                </Col>

                                                                <Col xs={6}>
                                                                    <div className="mb-3">
                                                                        <Label>Start Time  <span className='secondary'>*</span> </Label>
                                                                        <div className={"input-group"}  >
                                                                            <Input id="start_time" name="start_time" placeholder="Enter start time"
                                                                                className="form-control px-2 py-1"
                                                                                defaultValue={validation.values.start_time || ""}
                                                                                onClick={() => { setModalStartTime(!modalStartTime); }}
                                                                                invalid={
                                                                                    submit && validation.errors.start_time ? true : false
                                                                                }
                                                                            />

                                                                            <div className="modal" tabIndex="-1" aria-hidden="true" >
                                                                                <div className="modal-dialog modal-dialog-centered"    >
                                                                                    <Modal size="md" isOpen={modalStartTime} toggle={() => {
                                                                                        setModalStartTime(!modalStartTime)
                                                                                    }} centered>
                                                                                        <ModalBody >
                                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                                <DemoContainer components={['TimeClock', 'TimeClock', 'TimeClock']}>
                                                                                                    <DemoItem  >
                                                                                                        <h6 className="text-muted" >SELECTED TIME</h6>

                                                                                                        <span className="d-flex justify-content-between align-items-center" >

                                                                                                            <h1> {startTime.format('HH:mm:ss')}</h1>
                                                                                                            <span className="fs-18 text-muted cursor" onClick={() => { setModalStartTime(!modalStartTime) }}>   <FaTimes /> </span>

                                                                                                        </span>
                                                                                                        <TimeClock
                                                                                                            className="w-100"
                                                                                                            value={startTime}
                                                                                                            onChange={handleStartTimeChange}
                                                                                                            ampm={false}
                                                                                                            onFocusedViewChange={(e) => {  if (e === 'seconds') {
                                                                                                                setFocus(e);
                                                                                                              } }}
                                                                                                        />
                                                                                                    </DemoItem>
                                                                                                </DemoContainer>
                                                                                            </LocalizationProvider>
                                                                                        </ModalBody >
                                                                                    </Modal>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={6}>
                                                                    <div className="mb-3">
                                                                        <Label>End Time <span className='secondary'>*</span></Label>
                                                                        <div className={"input-group"}  >
                                                                            <Input id="end_time" name="end_time" placeholder="Enter end time"
                                                                                className="form-control px-2 py-1"
                                                                                defaultValue={validation.values.end_time || ""}
                                                                                onClick={() => { setModalEndTime(!modalEndTime); }}
                                                                                invalid={
                                                                                    submit && validation.errors.end_time ? true : false
                                                                                }
                                                                            />

                                                                            <div className="modal" tabIndex="-1" aria-hidden="true" >
                                                                                <div className="modal-dialog modal-dialog-centered"    >
                                                                                    <Modal size="md" isOpen={modalEndTime} toggle={() => {
                                                                                        setModalEndTime(!modalEndTime)
                                                                                    }} centered>
                                                                                        <ModalBody>
                                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                                <DemoContainer components={['TimeClock', 'TimeClock', 'TimeClock']}>
                                                                                                    <DemoItem  >
                                                                                                        <h6 className="text-muted" >SELECTED TIME</h6>
                                                                                                        <span className="d-flex justify-content-between align-items-center" >
                                                                                                            <h1> {endTime.format('HH:mm:ss')}</h1>
                                                                                                            <span className="fs-18 text-muted cursor" onClick={() => { setModalEndTime(!modalEndTime) }}>   <FaTimes /> </span>

                                                                                                        </span>
                                                                                                        <TimeClock
                                                                                                            className="w-100"
                                                                                                            value={endTime}
                                                                                                            onChange={handleEndTimeChange}
                                                                                                            ampm={false}
                                                                                                            minTime={startTime}
                                                                                                            onFocusedViewChange={(e) => {  if (e === 'seconds') {
                                                                                                                setFocus(e);
                                                                                                              } }}
                                                                                                        />
                                                                                                    </DemoItem>
                                                                                                </DemoContainer>
                                                                                            </LocalizationProvider>
                                                                                        </ModalBody >
                                                                                    </Modal>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </>
                                                }

                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="reason" className="form-label">Reason note  </Label>
                                                        <Input type="textarea" className="form-control" id="reason" placeholder="Enter reason"
                                                            name='reason'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.reason || ""}
                                                            maxLength={250}
                                                            invalid={
                                                                submit && validation.errors.reason ? true : false
                                                            }
                                                            style={{ height: "100px", minHeight: '50px', maxHeight: "100px", }}
                                                            multiline="true"
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={12} className='py-2'>
                                                    <div className="mb-2">
                                                        <Label htmlFor="attachment" className="form-label">Attachments</Label>
                                                        <Input type="file" accept=".pdf, image/*" className="form-control px-2 py-1" id="attachment"
                                                            name='attachment'
                                                            onChange={handleFileChange}
                                                            multiple />
                                                    </div>

                                                    <Row className='py-2  '>
                                                        {(files || [])?.map((item, index) => {
                                                            return [
                                                                <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                                                    <FaTimesCircle className="close-button" onClick={(e) => {
                                                                        setFiles(
                                                                            files?.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                                                        );
                                                                        setFile(
                                                                            file?.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                                                        );
                                                                    }} />
                                                                    <div className="selected-image cursor" onClick={(e) => {
                                                                        setModalFile(true);
                                                                        setFileUrl({ name: item.name, path: item.preview, ext: item.name });
                                                                    }}>
                                                                        {imageExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                                                            <img
                                                                                src={item.preview}
                                                                                alt={item.name}
                                                                                width={100}
                                                                                height={80}
                                                                            />
                                                                            : pdfExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                                                                <FaFilePdf size={30} />
                                                                                : excelExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                                                                    <FaFileExcel size={30} />
                                                                                    : <FaFileAlt size={30} />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            ]
                                                        })}

                                                        {attachmentList?.map((item, index) => {
                                                            return [
                                                                <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                                                    <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                                                        handleDelete(item.id);
                                                                    }} />

                                                                    <div className="selected-image cursor" onClick={(e) => {
                                                                        setModalFile(true); setFileUrl({ name: item.path, path: url + item.path, ext: item.file?.name });
                                                                    }}>
                                                                        {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                            <img
                                                                                src={url + item.path}
                                                                                alt={item.name}
                                                                                width={100}
                                                                                height={80}
                                                                            />
                                                                            : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                                <FaFilePdf size={30} />
                                                                                : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                                    <FaFileExcel size={30} />
                                                                                    : <FaFileAlt size={30} />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            ]
                                                        })}</Row>

                                                    <div className="modal fade" tabIndex="-1" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <Modal size="lg" isOpen={modalFile} toggle={() => {
                                                                setModalFile(false);
                                                            }} centered>

                                                                <ModalHeader className='bpBottom pb-2' toggle={() => { setModalFile(false); }}>  {"View Attachment"} </ModalHeader>
                                                                <ModalBody>
                                                                    {modalFile && <>
                                                                        <div className='text-center py-4' >
                                                                            <h5  >{fileUrl.name}</h5>
                                                                            {imageExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                                                                                <img src={fileUrl.path} alt={fileUrl.name}
                                                                                    width={isDesktop ? 500 : "100%"}
                                                                                    height={"100%"}
                                                                                />
                                                                                : pdfExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                                                                                    <iframe src={fileUrl.path} className='iframe' />
                                                                                    : excelExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                                                                                        <>  {fileUrl.name}</>
                                                                                        : <>  {fileUrl.name}</>

                                                                            }
                                                                        </div>
                                                                    </>}

                                                                    <div className='pt-2 text-center' >
                                                                        <button className="btn btn-primary shadow-lg"  >
                                                                            <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                                                                        </button>
                                                                    </div>
                                                                </ModalBody>
                                                            </Modal>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col lg={12}>
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button disabled={validation.isSubmitting ? true : false}
                                                            type="submit" className="btn btn-primary shadow-lg" onClick={() => { setSubmit(true); }}>{validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Save"}</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                    }
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Leave;