import axios from "axios";

const currentDomain = window.location.origin;
let apiUrl = process.env.REACT_APP_API_URL;
let url = process.env.REACT_APP_PUBLIC_URL;

if (currentDomain === 'http://localhost:3000') {
  apiUrl = 'http://127.0.0.1:8000/api/';
  url = 'http://127.0.0.1:8000/';
} 
 else {
  apiUrl = 'https://apihrm.fairpaytechworks.in/api/';
  url = 'https://apihrm.fairpaytechworks.in/';
}


const api = axios.create({
  baseURL: apiUrl,
});

export { api, url };