import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { getLoggedinUser } from "../helpers/api_helper";
const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const userDetails = getLoggedinUser()?.user;
    const [isApps, setIsApps] = useState(false);
    const [isExpenses, setIsExpenses] = useState(false);
    const [isBlogs, setIsBlogs] = useState(false);
    const [isAssets, setIsAssets] = useState(false);
    const [isDocuments, setIsDocuments] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isEmployee, setIsEmployee] = useState(false);
    const [isHoliday, setIsHoliday] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [isLeave, setIsLeave] = useState(false);
    const [isAttendance, setIsAttendance] = useState(false); 
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false); 
    const [isSettings, setIsSettings] = useState(false); 
    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }

        if (iscurrentState !== 'Expenses') {
            setIsExpenses(false);
        }

        if (iscurrentState !== 'Blogs') {
            setIsBlogs(false);
        }

        if (iscurrentState !== 'Assets') {
            setIsAssets(false);
        }

        if (iscurrentState !== 'Documents') {
            setIsDocuments(false);
        }

        if (iscurrentState !== 'User') {
            setIsUser(false);
        }

        if (iscurrentState !== 'Employee') {
            setIsEmployee(false);
        }

        if (iscurrentState !== 'Holiday') {
            setIsHoliday(false);
        }

        if (iscurrentState !== 'Working') {
            setIsWorking(false);
        }

        if (iscurrentState !== 'Leave') {
            setIsLeave(false);
        }

        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }

        if (iscurrentState !== 'Settings') {
            setIsSettings(false);
        } 

        if (iscurrentState !== 'Attendance') {
            setIsAttendance(false);
        }

    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isEmployee,
        isAssets,
        isExpenses,
        isBlogs,
        isDocuments,
        isUser,
        isHoliday,
        isWorking,
        isLeave,
        isAuth,
        isPages,
        isAttendance,
    ]);

    const menuItems = [
        {
            id: "dashboard",
            label: "Dashboard",
            view: true,
            icon: < i className="ri-line-chart-fill"></i>,
            link: "/dashboard",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "employee",
            label: "Employee Management",
            icon: <i className="ri-user-2-fill"></i>,
            link: "/#",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            click: function (e) {
                e.preventDefault();
                setIsEmployee(!isEmployee);
                setIscurrentState('Employee');
                updateIconSidebar(e);
            },
            stateVariables: isEmployee,
            subItems: [
                {
                    id: "departments",
                    label: "Departments",
                    link: "/departments",
                    isChildItem: false,

                    view: true,
                    icon: <i className="ri-briefcase-4-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "employee",
                },
                {
                    id: "employees",
                    label: "Employees",
                    link: "/employees",
                    isChildItem: false,
                    view: true,
                    icon: <i className="ri-user-2-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "employee",
                },
                // {
                //     id: "schedule",
                //     label: "Shift & Schedule",
                //     link: "/schedule",
                //     isChildItem: false,

                //     view: true,
                //     icon: <i className="ri-timer-fill"></i>, 
                //     click: function (e) {
                //         e.preventDefault();
                //     },
                //     parentId: "employee",
                // },
                // {
                //     id: "user",
                //     label: "User",
                //     link: "/user",
                //     view: true,
                //     isChildItem: false,
                //     icon: <i className="ri-user-3-fill"></i>,
                //     click: function (e) {
                //         e.preventDefault();
                //     },
                //     parentId: "user",
                // },
                {
                    id: "role",
                    label: "Role",
                    link: "/role",
                    icon: <i className="ri-shield-user-fill"></i>,
                    isChildItem: false,
                    view: true,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "employee",
                },
                {
                    id: "permission",
                    label: "Permission",
                    link: "/permission",
                    icon: <i className="ri-lock-fill"></i>,
                    isChildItem: false,
                    view: true,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "employee",
                },
            ],
        },
        {
            id: "attendance",
            label: "Attendance",
            view: userDetails?.roles?.[0]?.name !== "Admin" ? true : false,
            icon: <i className="ri-calendar-check-fill"></i>,
            link: "/attendance",
            click: function (e) {
                e.preventDefault();
            }
        },

        {
            id: "working",
            label: "Attendance",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            icon: <i className="ri-calendar-2-fill"></i>,
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsWorking(!isWorking);
                setIscurrentState('Working');
                updateIconSidebar(e);
            },
            stateVariables: isWorking,
            subItems: [
                {
                    id: "attendance",
                    label: "Attendance",
                    view: true,
                    icon: <i className="ri-calendar-check-fill"></i>,
                    link: "/attendance",
                    click: function (e) {
                        e.preventDefault();
                    }
                },

                // {
                //     id: "work_from_home",
                //     label: "Work From Home",
                //     view: true,
                //     icon: <RiHomeOfficeFill fontSize={20} />,
                //     link: "/work_from_home",
                //     click: function (e) {
                //         e.preventDefault();
        
                //     },
                //     parentId: "working",
                // },

                // {
                //     id: "workinglist",
                //     label: "Work Hours Status",
                //     link: "/workinglist",
                //     icon: < i className="ri-file-list-fill"></i>,
                //     isChildItem: false,
                //     view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                //     click: function (e) {
                //         e.preventDefault();
                //     },
                //     parentId: "working",
                // },

                {
                    id: "workinghours",
                    label: "Work Hours",
                    view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                    icon: <i className="ri-calendar-2-fill"></i>,
                    link: "/workinghours",
                    click: function (e) {
                        e.preventDefault(); 
                    },
                    parentId: "working",
                },
            ],
        },

        {
            id: "leave",
            label: "Leave",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            icon: <i className="ri-time-fill"></i>,
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsLeave(!isLeave);
                setIscurrentState('Leave');
                updateIconSidebar(e);
            },
            stateVariables: isLeave,
            subItems: [
                {
                    id: "leaveType",
                    label: "Leave Types",
                    link: "/leavetypes",
                    icon: < i className="ri-file-list-fill"></i>,
                    isChildItem: false,
                    view: true,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "leave",
                },
                {
                    id: "leave",
                    label: "Leave Status",
                    link: "/leave",
                    icon: <i className="ri-time-fill"></i>,
                    isChildItem: false,
                    view: true,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "leave",
                },
            ],
        },

        {
            id: "leave",
            label: "Leave Status",
            view: userDetails?.roles?.[0]?.name === "Admin" ? false : true,
            icon: <i className="ri-time-fill"></i>,
            link: "/leave",
            click: function (e) {
                e.preventDefault();

            }
        }, 
 
        {
            id: "holiday",
            label: "Holiday",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            icon: <i className="bx bxs-tree-alt"></i>,
            link: "/holiday",
            click: function (e) {
                e.preventDefault();
            }
        },

        {
            id: "holiday",
            label: "Holiday",
            view: userDetails?.roles?.[0]?.name === "Admin" ? false : true,
            icon: <i className="bx bxs-tree-alt"></i>,
            link: "/holidays",
            click: function (e) {
                e.preventDefault();
            }
        }, 
        {
            id: "blogs",
            label: "Blogs",
            icon: <i className="ri-file-list-3-fill"></i>,
            view: userDetails?.roles?.[0]?.name === "Admin" ? true :  false,
            click: function (e) {
                e.preventDefault();
                setIsBlogs(!isBlogs);
                setIscurrentState('Blogs');
                updateIconSidebar(e);
            },
            stateVariables: isBlogs,
            subItems: [
                {
                    id: "blogs",
                    label: "Blogs",
                    link: "/blogs",
                    view: true,
                    isChildItem: false,
                    icon: <i className="ri-file-list-3-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "blogs",
                },
                {
                    id: "category",
                    label: "Category",
                    view: true,
                    isChildItem: false,
                    link: "/blog-category",
                    icon: < i className="ri-file-list-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "blogs",
                },
                {
                    id: "tags",
                    label: "Tags",
                    view: true,
                    isChildItem: false,
                    link: "/blog-tag",
                    icon: < i className="ri-price-tag-3-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "tags",
                },
            ],
        },

        {
            id: "notes",
            label: "Notes",
            view:   true  ,
            icon: <i className="ri-file-text-fill"></i>,
            link: "/notes",
            click: function (e) {
                e.preventDefault();
            }
        },

        {
            id: "eodReport",
            label: "End of Day Report",
            view:   true  ,
            icon: <i className="ri-file-text-fill"></i>,
            link: "/eodReport",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "expenses",
            label: "Expense Management",
            icon: <i className="ri-hand-coin-fill"></i>,
            view: userDetails?.roles?.[0]?.name === "Admin" ? true :  false,
            click: function (e) {
                e.preventDefault();
                setIsExpenses(!isExpenses);
                setIscurrentState('Expenses');
                updateIconSidebar(e);
            },
            stateVariables: isExpenses,
            subItems: [
                {
                    id: "expenses",
                    label: "Expenses",
                    link: "/expenses",
                    view: true,
                    isChildItem: false,
                    icon: <i className="ri-hand-coin-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "expenses",
                },
                {
                    id: "category",
                    label: "Category",
                    view: true,
                    isChildItem: false,
                    link: "/category",
                    icon: < i className="ri-file-list-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "expenses",
                },
            ],
        },

        {
            id: "expenses",
            label: "Expenses",
            view: userDetails?.roles?.[0]?.name === "Admin" ? false : true,
            icon: <i className="ri-hand-coin-fill"></i>,
            link: "/expenses",
            click: function (e) {
                e.preventDefault();
            }
        },

        {
            id: "documents",
            label: "Docs Management",
            icon: <i className="ri-file-fill"></i>,
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            click: function (e) {
                e.preventDefault();
                setIsDocuments(!isDocuments);
                setIscurrentState('Documents');
                updateIconSidebar(e);
            },
            stateVariables: isDocuments,
            subItems: [
                {
                    id: "Documents",
                    label: "Documents",
                    icon: <i className="ri-file-fill"></i>,
                    link: "/documents",
                    view: true,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "documents",
                },
                {
                    id: "category",
                    label: "Category",
                    view: true,
                    isChildItem: false,
                    link: "/documentcategory",
                    icon: < i className="ri-file-list-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "documents",
                },
            ],
        },

        {
            id: "clients",
            label: "Client / Vendor",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            icon: <i className="ri-building-4-fill"></i>,
            link: "/clients",
            click: function (e) {
                e.preventDefault();
            }
        },

        {
            id: "projects",
            label: "Projects",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            icon: <i className="ri-folder-5-fill"></i>,
            link: "/projects",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "assets",
            label: "Assets Management",
            icon: <i className="ri-tv-2-fill"></i>,
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            click: function (e) {
                e.preventDefault();
                setIsAssets(!isAssets);
                setIscurrentState('Assets');
                updateIconSidebar(e);
            },
            stateVariables: isAssets,
            subItems: [
                {
                    id: "assets",
                    label: "Assets",
                    link: "/assets",
                    view: true,
                    isChildItem: false,
                    icon: <i className="ri-tv-2-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "assets",
                },

                // {
                //     id: "asset_user",
                //     label: "Asset User",
                //     link: "/asset_user",
                //     view: true,
                //     isChildItem: false,
                //     icon: <i className="ri-tv-2-fill"></i>,
                //     click: function (e) {
                //         e.preventDefault();
                //     },
                //     parentId: "assets",
                // },

                {
                    id: "category",
                    label: "Category",
                    view: true,
                    isChildItem: false,
                    link: "/assetcategory",
                    icon: < i className="ri-file-list-fill"></i>,
                    click: function (e) {
                        e.preventDefault();
                    },
                    parentId: "assets",
                },
            ],
        },
        {
            id: "settings",
            label: "Settings",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            icon: <i className="ri-list-settings-fill"></i>,
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsSettings(!isSettings);
                setIscurrentState('Settings');
                updateIconSidebar(e);
            },
            stateVariables: isSettings,
            subItems: [ 
                {
                    id: "settings",
                    label: "Settings",
                    icon: <i className="ri-settings-2-fill"></i>,
                    link: "/settings",
                    view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                    click: function (e) {
                        e.preventDefault(); 
                    },
                    parentId: "settings",
                }, 

                {
                    id: "mailsettings",
                    label: "Mail Settings",
                    view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                    icon: <i className="ri-mail-settings-fill"></i>,
                    link: "/mailsettings",
                    click: function (e) {
                        e.preventDefault(); 
                    }, 
                    parentId: "settings",
                },
            ],
        },
    //     {
    //         id:"faq",
    //         label:"Faq",
    //         icon: <i className="ri-questionnaire-fill"></i>,
    //         view:   true  ,
    //         link:"/faq",
    //         click: function (e) {
    //             e.preventDefault();
    //     }
    // }


    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;