import React, { useState, useEffect, useCallback } from 'react';
import {
  Card, CardBody, Col, Container, Offcanvas, OffcanvasHeader,
  OffcanvasBody, Accordion, AccordionBody, AccordionHeader,
  UncontrolledAccordion, AccordionItem, TabContent, TabPane,
  Form, Input, Label, FormGroup, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback,
  Nav, NavItem, NavLink,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import Select from "react-select";
import classnames from "classnames";
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const Category = () => {
  document.title = "Category";
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [activeTab, setactiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const [submit, setSubmit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [main, setMain] = useState(false);
  const [message, setMessage] = useState(false);
  const [categoryView, setCategoryView] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [view, setView] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const selectStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? ' ' : ' ',
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px',

      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    };
  };

  const handleAddClick = (arg) => {
    setView(false);
    setSubmit(false);
    validation.setValues({});
     setIsEdit(false);
    toggleDrawer();
  };

  const handleEditClick = useCallback((arg) => {
    setView(false);
    setSubmit(false);
    viewCategory(arg?.id);
    validation.resetForm();
    setIsEdit(true);
  }, []);

  const onClickData = () => {
    setDeleteModal(true);
  };

  const keywordSearch = async (e) => {
    let inputVal = e;
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'

    try {
      const response = await api.get(`expenseCategory?search=${inputVal}`, {});
      if (response.data?.status === true) {
        setCategoryList(response.data?.data);
      } else {
        setCategoryList([]);
        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

 
  const getCategory = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`expenseCategory?page=${currentPage}&perPage=${rowPerPage}`, {});

      if (response.data.status === true) {

        setCategoryList(response.data?.data);
        setGroupList(response.data?.parent);

      } else {

        setCategoryList([]);
        setGroupList(response.data?.parent);

        ToastMessage("error", response.data?.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const getCategoryPage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`expenseCategory?page=${currentPage}&perPage=${rowPerPage}`, {});

      if (response.data.status === true) {

        setCategoryList(response.data?.data);

      } else {

        setCategoryList([]);

        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const viewCategory = async (id) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('expenseCategory/' + id, {});

      if (response.data.status === true) {
        validation.setValues(response.data?.data);
      }

      setOpen(true);

    } catch (err) {
      console.error(err.message);
    }
  };


  useEffect(() => {
    getCategory();
    //   getGroup();
  }, []);


  const addCategory = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {

      const response = await api.post('expenseCategory', value);
      if (response.data.status === true) {
        getCategory();
        toggleDrawer();
        validation.resetForm();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }

      validation.setSubmitting(false);
      
    } catch (err) {
      console.error(err.message);
    }

  };

  const editCategory = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.put('expenseCategory/' + value.id, value);

      if (response.data.status === true) {
        getCategory();
        validation.resetForm();
        setIsEdit(false);
        toggleDrawer();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);
     

    } catch (err) {
      console.error(err.message);
    }

  };

  const deleteCategory = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('expenseCategory/' + value?.id, {});
      if (response.data.status === true) {
        getCategory();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)

    } catch (err) {
      console.error(err.message);
    }

  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      parent_id: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      parent_id: main ? Yup.string().required('Main category is required') : Yup.string().notRequired(),
      description: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        editCategory({
          id: validation.values?.id,
          name: values?.name,
          parent_id: values?.parent_id,
          description: values?.description ? values?.description : " ",
          status: values?.status,
        });

      } else {
        addCategory({
          name: values?.name,
          parent_id: values?.parent_id,
          description: values?.description ? values?.description : " ",
          status: 1
        });
      }
    },
  });

  const handleClick = (e) => {
    setCurrentPage(e);
    getCategoryPage(e, rowPerPage);
  };

  const handleToggle = (index) => {
    const newExpandedStates = [...isExpanded];
    newExpandedStates[index] = !newExpandedStates[index];
    setIsExpanded(newExpandedStates);
  };

  useEffect(() => {
    setIsExpanded(categoryList?.data?.map(() => false))
  }, [categoryList]);


  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <Modal isOpen={deleteModal} toggle={() => { setDeleteModal(false) }} centered={true}>
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#FFBC0A,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>

            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>

              {main ?
                <p className="text-muted mx-4 mb-0">   Are you sure you want to remove <b>{categoryView?.name}</b> record? It will delete all Expense record related to this category</p>
                :
                <p className="text-muted mx-4 mb-0">
                  {message ? (
                    <>To delete <b>{categoryView?.name}</b> Category before removing all subcategories.</>
                  ) : (
                    <>Are you sure you want to remove the <b>{categoryView?.name}</b> record? </>
                  )}
                </p>}
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={() => setDeleteModal(false)}
            >
              Close
            </button>
            {!message ? <button

              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => deleteCategory(categoryView)}
            >
              Yes, Delete It!
            </button> : ""}
          </div>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>

          <Row>
            <Col xs={12}>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Category Management</h4>
              </div>
            </Col>
          </Row>

          <Row className='d-flex align-items-center'>
            <Col sm={4}>
            </Col>
            <Col className="col-sm-auto ms-auto d-flex justify-content-end gap-2 py-1">

              {/* <div className="search-box">
                <Input type="text" className="form-control" placeholder="Search..." onBlur={(e) => keywordSearch(e.target.value)} />
                <i className="ri-search-line search-icon"></i>
              </div> */}

              <div className="list-grid-nav hstack gap-1">
                <button color="primary" className="btn btn-primary shadow-lg d-flex  gap-2 px-3 py-1" onClick={() => { handleAddClick(); setMain(false); toggle("1"); }} >
                  <i className="ri-add-line search-icon"></i> <span className=''> Category</span></button>

                {/* <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); setMain(false); toggle("1"); }} /> */}

              </div>
            </Col>

          </Row>

          <Row className='py-1 mt-1'>
            {categoryList?.data?.length !== 0 ?
              <UncontrolledAccordion
                defaultOpen={Array.from({ length: 99999 }, (_, index) => String(index))}
                stayOpen >

                {categoryList?.data?.map((item, index) => {

                  return (
                    <AccordionItem key={index} className='mb-2'>
                      <AccordionHeader targetId={String(index)}>
                        <h5 className="fs-15 mb-0">  {item.name} </h5>

                        <a type="button" className="btn  btn-sm btn-icon btn25"
                          onClick={(e) => { handleEditClick(item); setMain(false); toggle("1"); }}>
                          <i className="ri-pencil-fill fs-18 primary"  ></i>
                        </a>

                        <a type="button" className="btn btn-sm btn-icon btn25"
                          onClick={(e) => {
                            onClickData(); setCategoryView(item); setMain(false); toggle("1");
                            setMessage(item.children?.length !== 0 ? true : false);
                          }}
                        >
                          < i className="ri-delete-bin-fill fs-18 secondary"></i>

                        </a>

                      </AccordionHeader>
                      <AccordionBody accordionId={String(index)}>

                        <Row className="row  gy-1 mb-1 px-0 py-0  d-none d-md-none d-lg-block" id="list" >
                          <Col lg={12}  >
                            <Card className="mb-0 mt-1 mt-md-0 border-0 border-bottom rounded-0">
                              <CardBody className="px-1 py-0 px-md-1 py-md-2 ">
                                <div className="d-lg-flex align-items-center row  d-none d-md-none d-lg-block">

                                  <div className="col-7 col-md-3">

                                    <h6 className="fs-14 mb-0"> Sub Category</h6>
                                  </div>

                                  <div className="col-5 col-md-8">

                                    <h6 className="fs-14 mb-0">Description</h6>

                                  </div>

                                  <div className="mb-1 col-12 col-md-1  mx-auto d-flex gap-2 align-items-center justify-content-end">

                                    <h6 className="fs-14 mb-0">Action</h6>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="row gy-1 mb-1" id="list">
                          {
                            item.children?.length !== 0 ?

                              (item.children)?.map((item, index) => (

                                <Col lg={12} key={index} >
                                 <Card className="mb-0 border-0  border-bottom">
                                    <CardBody className="px-1 py-0">

                                      <div className="d-lg-flex align-items-center row">

                                        <div className="col-12 col-md-3">  {item.name} </div>

                                        <div className={`${isExpanded?.[index] ? "d-block" : "d-flex"} col-10 col-md-8`}>

                                          <p className="mb-0">
                                            {isExpanded?.[item?.id] ? item?.description : item?.description?.length > (isMobile ? 25 : 100) ? item?.description?.slice(0, (isMobile ? 25 : 100)) + "..." : item?.description}
                                            {item?.description?.length > (isMobile ? 25 : 100) && (
                                              <a href="#"  key={item.id} onClick={() => handleToggle(item?.id)} >
                                                {isExpanded?.[item?.id] ? ' Read Less' : ' Read More'}
                                              </a>
                                            )}
                                          </p>

                                        </div>

                                        <div className="col-2 col-md-1  mx-auto d-flex gap-0 align-items-center justify-content-end">
                                          <>
                                            <button type="button" className="btn  btn-sm btn-icon btn25"
                                              onClick={(e) => { handleEditClick(item); setMain(true); toggle("2"); }}>
                                              <i className="ri-pencil-fill fs-18 primary"  ></i>
                                            </button>

                                            <button type="button" className="btn btn-sm btn-icon btn25"
                                              onClick={(e) => {
                                                onClickData(); setCategoryView(item); setMain(true);
                                                setMessage(false);
                                              }}>
                                              < i className="ri-delete-bin-fill fs-18 secondary"></i>
                                            </button> </>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              ))

                              :
                              <div className="noresult" >
                                <div className="text-center">

                                  <h6 className="mt-2">Sorry! No Result Found</h6>
                                  {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                </div>
                              </div>
                          }
                        </Row>
                      </AccordionBody>
                    </AccordionItem>
                  );
                }
                )}

              </UncontrolledAccordion>
              :
              <div className="noresult" >
                <div className="text-center">

                  <h6 className="mt-2">Sorry! No Result Found</h6>
                  {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
    orders for you search.</p> */}
                </div>
              </div>
            }


          </Row>

          <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getCategoryPage(1, e.target.value);
                }}
                value={rowPerPage}
              >

                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={categoryList?.total}>All</option>

              </select>

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={categoryList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />

            </div>
          </div>


          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">
                  {/* {!isEdit && main  ? "Add Subcategory" :  isEdit && main? "Edit Subcategory" : 
                          !isEdit && !main  ? "Add Category" : "Edit Category"  }  */}

                  {!isEdit ? "Add Category" : "Update Category"}
                </span>

                <span>
                  {!view ? <span className="fs-18 px-1 text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>

              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              {!isEdit ?
                <div className="px-4 py-3">
                  <div className="   p-0 d-inline-block position-relative w-100" aria-labelledby="page-header-notifications-dropdown" style={{ zIndex: "1" }}>

                    <div className=" pt-2">
                      <Nav tabs className="dropdown-tabs nav-tabs-custom">
                        <NavItem>
                          <NavLink className={classnames({ active: activeTab === "1", }, "cursor-pointer")} onClick={() => { setMain(false); toggle("1"); validation.resetForm(); }} >
                            Add Category
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames({ active: activeTab === "2", }, "cursor-pointer")} onClick={() => { setMain(true); toggle("2"); validation.resetForm(); }} >
                            Add Subcategory
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={activeTab} id="notificationItemsTabContent4">
                      <TabPane tabId="1" id="tab1" className="py-2 ps-2">
                        <Form onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>
                          <Row>
                            {main ?
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="category" className="form-label">Main Category <span className='secondary'>*</span> </Label>
                                  <Select
                                    name="category"

                                    defaultValue={
                                      validation.values.parent?.name ? {
                                        "value": validation.values.parent?.id,
                                        "label": validation.values.parent?.name
                                      } : ""}

                                    onChange={(e) => {

                                      validation.setValues({
                                        ...validation.values,
                                        parent_id: e?.value
                                      });
                                    }}
                                    options={[
                                      { value: "", label: "Select" },

                                      ...groupList?.map((item, index) => {

                                        return (
                                          { value: item.id, label: item.name }
                                        );
                                      })

                                    ]}

                                    styles={selectStyle('parent_id')}
                                  />

                                </div>
                              </Col>
                              : ""}
                            <Col lg={main ? 6 : 12}>
                              <div className="mb-3">
                                <Label htmlFor="categoryName" className="form-label">Name <span className='secondary'>*</span></Label>
                                <Input type="text" className="form-control px-2 py-1" id="categoryName" placeholder="Enter name"
                                  name='name'
                                   
                                  // readOnly={isEdit ? false  : true}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    submit && validation.errors.name ? true : false
                                  }
                                />

                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="mb-3">
                                <Label htmlFor="description" className="form-label">Description</Label>
                                <Input type="textarea" className="form-control " id="description" placeholder="Enter description"
                                  name='description'
                                
                                  // readOnly={isEdit ? false  : true}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  maxLength={250}
                                  value={validation.values.description || ""}
                                  invalid={
                                    submit && validation.errors.description ? true : false
                                  }
                                />

                              </div>
                            </Col>


                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button type="submit" className="btn btn-primary shadow-lg" id="addNewCategory"
                                  onClick={() => {
                                    setSubmit(true);
                                  }}>{"Save"}</button>

                              </div>
                            </Col>
                          </Row>
                        </Form>

                      </TabPane>

                      <TabPane tabId="2" id="tab2" className="py-2 ps-2">

                        <Form onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>

                          <Row>

                            {main ?

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="category" className="form-label">Main Category <span className='secondary'>*</span> </Label>

                                  <Select
                                    name="category"

                                    defaultValue={
                                      validation.values.parent?.name ? {
                                        "value": validation.values.parent?.id,
                                        "label": validation.values.parent?.name
                                      } : ""}


                                    onChange={(e) => {

                                      validation.setValues({
                                        ...validation.values,
                                        parent_id: e?.value
                                      });


                                    }}
                                    options={[
                                      { value: "", label: "Select" },

                                      ...groupList?.map((item, index) => {

                                        return (
                                          { value: item.id, label: item.name }
                                        );
                                      })

                                    ]}

                                    styles={selectStyle('parent_id')}
                                  />

                                </div>
                              </Col>
                              : ""}
                            <Col lg={main ? 6 : 12}>
                              <div className="mb-3">
                                <Label htmlFor="categoryName" className="form-label">Name <span className='secondary'>*</span></Label>
                                <Input type="text" className="form-control px-2 py-1" id="categoryName" placeholder="Enter name"
                                  name='name'
                                  

                                  // readOnly={isEdit ? false  : true}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    submit && validation.errors.name ? true : false
                                  }
                                />

                              </div>
                            </Col>



                            <Col lg={12}>
                              <div className="mb-3">
                                <Label htmlFor="description" className="form-label">Description</Label>
                                <Input type="textarea" className="form-control " id="description" placeholder="Enter description"
                                  name='description'
                                  
                                  // readOnly={isEdit ? false  : true}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  maxLength={250}
                                  value={validation.values.description || ""}
                                  invalid={
                                    submit && validation.errors.description ? true : false
                                  }
                                />

                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button type="submit" className="btn btn-primary shadow-lg" id="addNewCategory" onClick={() => { setSubmit(true); }}>{"Save"}</button>

                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>

                :
                <SimpleBar className="h-100">
                  <div className="px-4 py-3">
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>

                      <Row>

                        {main ?

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="category" className="form-label">Main Category <span className='secondary'>*</span> </Label>
                              <Select
                                name="category"

                                defaultValue={
                                  validation.values.parent?.name ? {
                                    "value": validation.values.parent?.id,
                                    "label": validation.values.parent?.name
                                  } : ""}

                                onChange={(e) => {
                                  validation.setValues({
                                    ...validation.values,
                                    parent_id: e?.value
                                  });

                                }}
                                options={[
                                  { value: "", label: "Select" },
                                  ...groupList?.map((item, index) => {
                                    return (
                                      { value: item.id, label: item.name }
                                    );
                                  })

                                ]}

                                styles={selectStyle('parent_id')}
                              />

                            </div>
                          </Col>
                          : ""}
                        <Col lg={main ? 6 : 12}>
                          <div className="mb-3">
                            <Label htmlFor="categoryName" className="form-label">Name <span className='secondary'>*</span></Label>
                            <Input type="text" className="form-control px-2 py-1" id="categoryName" placeholder="Enter name"
                              name='name'
                               
                              // readOnly={isEdit ? false  : true}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                submit && validation.errors.name ? true : false
                              }
                            />

                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="description" className="form-label">Description</Label>
                            <Input type="textarea" className="form-control " id="description" placeholder="Enter description"
                              name='description'
                            
                              // readOnly={isEdit ? false  : true}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              maxLength={250}
                              value={validation.values.description || ""}
                              invalid={
                                submit && validation.errors.description ? true : false
                              }
                            />

                          </div>
                        </Col>


                        <Col lg={12}>
                          <div className="hstack gap-2 justify-content-end">
                            <button type="submit" className="btn btn-primary shadow-lg" id="addNewCategory"
                              onClick={() => {
                                setSubmit(true);
                              }}>{"Save"}</button>

                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </SimpleBar>
              }

            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Category;