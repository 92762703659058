import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button, Card, CardBody, Nav, FormGroup, NavItem,
  NavLink, Offcanvas, OffcanvasHeader, OffcanvasBody, TabContent, Spinner,
  TabPane, ModalFooter, ModalHeader, Col, Container, Form, Input, Label, Modal, ModalBody, Row,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import FeatherIcon from 'feather-icons-react';
import * as Yup from "yup";
import { useFormik } from "formik";
import Select, { components } from "react-select";
import { FaTimesCircle, FaTimes, FaPlus, FaCloudDownloadAlt, FaFileAlt, FaFilePdf, FaFileExcel } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { getSettingsDetails } from "../../helpers/api_helper";
import { FormatDate, FormatMinDate, FormatMaxDate, SmallDateFormat } from "../../Components/Hooks/Function";
import { ToastMessage, DefaultDate } from "../../Components/Hooks/Function";
import 'react-dropdown-tree-select/dist/styles.css';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";


const Employee = () => {
  document.title = "Employees";
  var moment = require('moment-timezone');
  const [searchList, setSearchList] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [settingsList, setSettingsList] = useState([]);
  const [inputs, setInputs] = useState([{ title: '', file: null, preview: '' }]);
  const [file, setFile] = useState([]);
  const [files, setFiles] = useState([]);
  const handleTitleChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].title = value;
    setInputs(newInputs);
  };
  const handleProofChange = (index, file) => {
    const newInputs = [...inputs];
    newInputs[index].file = file;
    newInputs[index].preview = URL.createObjectURL(file);
    setInputs(newInputs);
  };

  const handleProfileChange = (e) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
    const fileDate = Array.from(e.target.files);
    const totalSize = fileDate.reduce((acc, file) => acc + file.size, 0);
    if (totalSize <= maxSizeInBytes) {
      setFile(fileDate);
      setFiles(fileDate.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));

    } else {
      setFile([]);
      setFiles([]);

      ToastMessage("error", "Uplaoad File is Greater than 10 MB");
    }
  }

  const handleAddInput = () => {
    setInputs([...inputs, { title: '', file: null, preview: '' }]);
  };

  const handleRemoveInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [view, setView] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [departmentAllList, setDepartmentAllList] = useState([]);
  const [designationAllList, setDesignationAllList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeView, setEmployeeView] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [permentCount, setPermentCount] = useState([]);
  const [temporaryCount, setTemporaryCount] = useState([]);
  const [employeeTypeList, setEmployeeTypeList] = useState([]);
  const [modalAttachment, setModalAttachment] = useState({
    show: false,
    file: [],
  });
  const [statusList, setStatusList] = useState([
    { value: "1", label: "Confirmed" },
    { value: "2", label: "Probation" },
    { value: "3", label: "Relieved" },
  ]);
  const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
  const pdfExtensions = new Set(["pdf"]);
  const excelExtensions = new Set(["xls", "xlsx"]);
  const [modalFile, setModalFile] = useState(false);
  const [modalProfile, setModalProfile] = useState(false);
  const history = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      if (tab >= 4 && tab <= 7) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }

  const selectStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? ' ' : ' ',
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px',

      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    };
  };

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    setSettingsList(getSettingsDetails("settings"));
  };


  useEffect(() => {
    getSettings();
  }, []);

  function getExtension(filename) {
    return filename?.split('.').pop()
  }

  const getAllReport = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`employeeAllReport`, {
        params: {
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          selectedCountry: selectedCountry,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
          employeeTypeList: employeeTypeList,
          statusList: statusList,
        },
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Employee Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err.message);
    }
  };

  const [loading, setLoading] = useState(false);

  const getEmployees = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`employee?page=${currentPage}&perPage=${rowPerPage}`, {});

      if (response.data.status === true) {
        setSearchList(response.data?.data);
        setEmployeeList(response.data?.data);
        setPermentCount(response.data?.perment_count);
        setTemporaryCount(response.data?.temporary_count);
      } else {
        setSearchList([]);
        setEmployeeList([]);
        setPermentCount(response.data?.perment_count);
        setTemporaryCount(response.data?.temporary_count);
        ToastMessage("error", response.data?.message);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }

  };


  const handleDelete = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('employeeAttachment/' + id, {});

      if (response.data.status === true) {
        setAttachmentList(attachmentList?.filter(item => item.id !== id));
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const handleRemoveProfile = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post('employeeProfileDelete/' + id, {});

      if (response.data.status === true) {
        setEmployeeView(response.data?.data);
        validation.setValues({
          ...validation.values,
          profile: response.data?.data?.profile
        });
      }

    } catch (err) {
      console.error(err.message);
    }

  };


  const getEmployeePage = async (currentPage, rowPerPage) => {

    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {

      const response = await api.get(`employee?page=${currentPage}&perPage=${rowPerPage}`);

      if (response.data.status === true) {
        setSearchList(response.data?.data);
        setEmployeeList(response.data?.data);
        setPermentCount(response.data?.perment_count);
        setTemporaryCount(response.data?.temporary_count);
      } else {
        setSearchList([]);
        setEmployeeList([]);
        setPermentCount(response.data?.perment_count);
        setTemporaryCount(response.data?.temporary_count);

        ToastMessage("error", response.data?.message);
      }

      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const viewEmployee = async (id) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('employee/' + id, {});

      if (response.data.status === true) {
        handleDepartment(response.data?.data?.department_id);
        validation.setValues({
          ...response.data?.data,

          date_of_birth: DefaultDate(response.data?.data.date_of_birth, selectedCountry),
          date_of_joining: DefaultDate(response.data?.data.date_of_joining, selectedCountry),
          confirmation_date: DefaultDate(response.data?.data.confirmation_date, selectedCountry),
          //   designation_id: response.data?.data.designations?.id || '',
          department_id: response.data?.data.departments?.id || '',
          mobile: response.data?.data.users?.mobile || '',
          role_id: response.data?.data.users?.roles?.[0]?.id || '',
          marital_status: response.data?.data.marital_status || 'Unmarried',
          gender: response.data?.data.gender || 'Male',
        });

        setEmployeeView(response.data?.data);
        setAttachmentList(response.data?.attachment);

      } else {

        setEmployeeView([]);
        setAttachmentList();
      }

      setOpen(true);

    } catch (err) {
      console.error(err.message);
    }

  };

  const viewAttachmet = async (id) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('employeeAttachment/' + id, {});

      if (response.data.status === true) {
        setModalAttachment({ ...modalAttachment, show: true, file: response.data.data });
      } else {
        setModalAttachment({ ...modalAttachment, show: true, file: [], });
      }


    } catch (err) {
      console.error(err.message);
    }

  }

  const getAllDepartment = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`department`, {});

      if (response.data.status === true) {
        setDepartmentAllList(response.data?.data?.data);
      } else {
        setDepartmentAllList([]);
      }

    } catch (err) {
      console.error(err.message);
    }

  };


  const handleDepartment = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`getDesignation/${id}`, {});

      if (response.data.status === true) {
        setDesignationAllList(response.data?.data);
      } else {
        setDesignationAllList([]);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const getRole = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('getAllRole', {});

      if (response.data.status === true) {
        setRoleList(response.data?.data);
      } else {
        setRoleList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };


  const getEmployeeTypeList = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('employeeType', {});

      if (response.data.status === true) {
        setEmployeeTypeList(response.data?.data);
      } else {
        setEmployeeTypeList([]);
      }
    } catch (err) {
      console.error(err.message);
    }

  };

  useEffect(() => {
    getEmployees();
    getAllDepartment();
    getRole();
    getEmployeeTypeList();
  }, []);

  const addEmployee = async (value) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {

      const formData = new FormData();
      for (const key in value) {

        if (value.hasOwnProperty(key)) {
          if (key === "date_of_birth") {
            formData.append('date_of_birth', value.date_of_birth ? FormatDate(value.date_of_birth, selectedCountry) : "");
          } else if (key === "date_of_joining") {
            formData.append('date_of_joining', value.date_of_joining ? FormatDate(value.date_of_joining, selectedCountry) : "");
          } else if (key === "confirmation_date") {
            formData.append('confirmation_date', value.confirmation_date ? FormatDate(value.confirmation_date, selectedCountry) : "");
          } else {
            formData.append(key, value[key] || '');
          }
        }
      }

      if (file) {
        formData.append(`profile`, file[0]);
      }

      formData.append(`platform`, settingsList.filter(type => type?.name === "name")[0]?.value + " site");
      inputs.forEach((input, index) => {
        formData.append(`attachment[${index}][title]`, input.title);
        formData.append(`attachment[${index}][file]`, input.file);
      });

      const response = await api.post('employee', formData);

      if (response.data.status === true) {
        getEmployees();
        setOpen(false);
        setIsEdit(false);
        validation.resetForm();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }

      validation.setSubmitting(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const editEmployee = async (value) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      formData.append('_method', "PUT");
      for (const key in value) {

        if (value.hasOwnProperty(key)) {
          if (key === "date_of_birth") {
            formData.append('date_of_birth', value.date_of_birth ? FormatDate(value.date_of_birth, selectedCountry) : "");
          } else if (key === "date_of_joining") {
            formData.append('date_of_joining', value.date_of_joining ? FormatDate(value.date_of_joining, selectedCountry) : "");
          } else if (key === "confirmation_date") {
            formData.append('confirmation_date', value.confirmation_date ? FormatDate(value.confirmation_date, selectedCountry) : "");
          } else {
            formData.append(key, value[key] || '');
          }
        }
      }

      inputs.forEach((input, index) => {
        formData.append(`attachment[${index}][title]`, input.title);
        formData.append(`attachment[${index}][file]`, input.file);
      });

      if (file) {
        formData.append(`profile`, file[0]);
      }

      const response = await api.post('employee/' + value.id, formData);
      if (response.data.status === true) {
        getEmployees();
        setOpen(false);
        setIsEdit(false);
        validation.resetForm();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }

      validation.setSubmitting(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const deleteEmployee = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('employee/' + value?.id, {});
      if (response.data.status === true) {
        getEmployees();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)

    } catch (err) {
      console.error(err.message);
    }
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % modalAttachment?.file?.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? modalAttachment?.file?.length - 1 : prevSlide - 1
    );
  };

  const handleAddClick = () => {
    setPasswordShow(false);
    setView(false);
    setAttachmentList([]);
    setFiles();
    setFile();
    setInputs([{ title: '', file: null, preview: '' }]);
    setSubmit(false);
    setDesignationAllList([]);
    validation.setValues({
      marital_status: 'Unmarried',
      gender: 'Male',
    });
    setactiveArrowTab(4);
    setIsEdit(false);
    toggleDrawer();
  };

  const handleEditClick = useCallback((arg) => {
    setactiveArrowTab(4);
    setAttachmentList([]);
    setFiles();
    setFile();
    setInputs([{ title: '', file: null, preview: '' }]);
    setView(false);
    setSubmit(false);
    viewEmployee(arg?.id);
    setIsEdit(true);
  }, []);


  // const handleViewClick = (arg) => {
  //   setView(true);
  //   viewEmployee(arg?.id);
  //   validation.resetForm();
  //   setIsEdit(false);
  // }; 

  const onClickData = (team) => {
    setDeleteModal(true);
  };

  const keywordSearch = async (e) => {
    let inputVal = e;
    if (inputVal !== "") {
      const filteredResults = searchList?.data?.filter(item =>
        item.first_name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.users?.email?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.employee_types?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.employee_number_id?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.users?.roles?.[0]?.name?.toLowerCase().includes(inputVal.toLowerCase())
      );

      setEmployeeList({
        ...employeeList,
        data: filteredResults
      });

    } else {

      setEmployeeList({
        ...employeeList,
        data: searchList?.data
      });
    }
  };


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
      confirm: '',
      first_name: '',
      last_name: '',
      //  username: '',
      mobile: '',
      date_of_birth: '',
      gender: '',
      emergency_contact_name: '',
      emergency_contact_number: '',
      employee_type_id: '',
      employee_number_id: '',
      role_id: '',
      status: '',
      date_of_joining: '',
      confirmation_date: '',
      probation_period: '',
      notice_period: '',
      // designation_id: '',
      department_id: '',
      marital_status: 'Unmarried',
      spouse_name: '',
      parents_name: '',
      profile: "",

    },
    validationSchema: Yup.object({
      email: isEdit ? Yup.string() : Yup.string().required("Please enter your Email"),
      first_name: Yup.string().required("Please enter your name"),
      last_name: Yup.string().notRequired(),
      mobile: Yup.string()
        .min(10, "Mobile must be more than 10 characters long")
        .max(10, "Mobile must be less than 10 characters long").notRequired(),
      date_of_birth: Yup.string().required("Please enter"),
      gender: Yup.string().notRequired(),
      // username: isEdit ? Yup.string() : Yup.string().required("Please enter your username"),
      password: isEdit ? Yup.string() : Yup.string().required("Please enter your password"),
      confirm: isEdit ? Yup.string() : Yup.string()
        .oneOf([Yup.ref("password")], "Passwords do not match")
        .required("Please confirm your password"),
      emergency_contact_name: Yup.string().required("Please enter"),
      emergency_contact_number: Yup.string()
        .min(10, "Mobile must be more than 10 characters long")
        .max(10, "Mobile must be less than 10 characters long").required("Please enter"),
      employee_type_id: Yup.string().required("Please enter"),
      employee_number_id: Yup.string().required(),
      role_id: Yup.string().required("Please enter"),
      department_id: Yup.string().required("Please enter"),
      //  designation_id: Yup.string().required("Please enter"),
      date_of_joining: Yup.string().required("Please enter"),
      confirmation_date: Yup.string().required("Please enter"),
      status: Yup.string().required("Please enter"),
      probation_period: Yup.string().when('status', {
        is: (status) => ['2'].includes(status),
        then: (schema) => schema.required("Enter"),
        otherwise: (schema) => schema.notRequired(),
      }),
      notice_period: Yup.string()
        .min(1, "notice period must be more than 3 characters long")
        .max(3, "notice period must be less than 3 characters long").required("Please enter"),
      marital_status: Yup.string().required("Please enter"),
      spouse_name: Yup.string().notRequired(),
      parents_name: Yup.string().notRequired(),
      profile: Yup.string().notRequired(),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isEdit) {
          await editEmployee(values, validation.values?.id);
        } else {
          await addEmployee(values);
        }


        setSubmitting(false);
      } catch (error) {

        console.error("An error occurred:", error);
        setSubmitting(false);
      }
    },

  });


  const handleNext = () => {
    validation.setTouched(validation.values, true);
    setSubmit(true);
    if (!validation.errors.first_name && !validation.errors.email
      && !validation.errors.password
      && !validation.errors.confirm && !validation.errors.date_of_birth
      && !validation.errors.emergency_contact_name && !validation.errors.emergency_contact_number
      && !validation.errors.mobile) {
      setSubmit(false);
      toggleArrowTab(activeArrowTab + 1);
      validation.setTouched({}, false);
    }
  };

  const handlePrevious = () => {
    validation.setTouched(validation.values, true);
    toggleArrowTab(activeArrowTab - 1);
  };

  const handleSubmit = () => {
    setSubmit(true);
    if (validation.isValid) {
      validation.handleSubmit();
    }
  };

  const handleClick = (e) => {
    setCurrentPage(e);
    getEmployeePage(e, rowPerPage);
  };
    


  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        name={employeeView?.name}
        show={deleteModal}
        onDeleteClick={() => deleteEmployee(employeeView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Employees</h4>
              </div>
            </Col>
          </Row>

          <Row className='d-flex align-items-center'  >
            <Col sm={4}> </Col>
            <Col className="col-sm-auto ms-auto d-flex align-items-center gap-2 px-2 py-1">
              <div className="search-box">
                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                <i className="ri-search-line search-icon"></i>
              </div>
              <div className="list-grid-nav hstack gap-1">
                <button color="primary" className="btn btn-primary shadow-lg d-none   d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                  <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Employee</span></button>
                <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} />
              </div>
              <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => { setModalConfirm(!modalConfirm); }} >
                <i className="ri-download-cloud-fill search-icon"></i> <span className='d-none d-md-block'> Download Report </span></button>
              <FaCloudDownloadAlt className="text-primary d-flex d-md-none" size={25} onClick={() => { setModalConfirm(!modalConfirm); }} />

            </Col>
          </Row>


          <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
            <Col lg={12} className='table px-1  mb-0 '  >
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                    <div className="col-7 col-md-3  px-2"  >
                      <h5 className="fs-15 mb-0"> Name</h5>
                    </div>
                    <div className="d-flex   col-6 col-md-4  mt-0 align-items-center  justify-content-end  justify-content-md-start">
                      <h5 className="fs-15 mb-0">Email</h5>
                    </div>
                    <div className="d-flex col-6 ml-0 col-md-2 mt-0 align-items-center  justify-content-end  justify-content-md-start"  >
                      <h5 className="fs-15 mb-0">Employee Type</h5>
                    </div>
                    <div className="  col-12 col-md-2 align-items-center ">
                      <h5 className="fs-15 mb-0" >Role</h5>
                    </div>
                    <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end">
                      <h5 className="fs-15 mb-0">  Actions</h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1" id="employee-list">
            {loading ?
              <div className='text-center'>

                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                  Loading...
                </Spinner>
              </div>

              :
              employeeList?.data?.length !== 0 ?

                (employeeList?.data)?.map((item, index) => (

                  <Col lg={12} key={index} className='mt-0' >

                    <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                      <CardBody className="px-2 py-0">

                        <div className="d-lg-flex align-items-center row">

                          <div className="col-7 col-md-3 order-1 order-md-1">

                            <a href="#!" onClick={() => {
                              history('/profile?user_id=' + item.users?.id);   // handleViewClick(item);
                            }}> {item.users?.name}
                              {item.employee_number_id ? " (" + item.employee_number_id + ")" : ""}

                            </a>
                          </div>

                          {/* <div className=" col-5   mx-auto d-flex d-md-none  gap-0 align-items-center justify-content-end px-2">

                            {item.employee_attachments?.length > 0 ?
                              <button type="button" className="btn  btn-sm btn-icon btn25"
                                onClick={(e) => { viewAttachmet(item.id) }}>
                                <i className="ri-attachment-2 fs-18 primary"  ></i>
                              </button> : " "}

                            <button type="button" className="btn  btn-sm btn-icon btn25"
                              onClick={(e) => { handleEditClick(item); }}>
                              <i className="ri-pencil-fill fs-18 primary"  ></i>
                            </button>

                            <button type="button" className="btn btn-sm btn-icon btn25"
                              onClick={(e) => { onClickData(item); setEmployeeView(item); }}>
                              < i className="ri-delete-bin-fill fs-18 secondary" ></i>
                            </button>
                          </div> */}

                          <div className="col-12 col-md-4 order-3 order-md-2 align-items-center">
                            <p className="mb-0 ">  {item.users?.email}</p>
                          </div>
                          <div className="col-7 col-md-2 order-4 order-md-3">
                            <p className="mb-0 ">  {item.employee_types?.name}</p>
                          </div>

                          <div className="col-5 col-md-2 order-5 order-md-4 d-flex align-items-center justify-content-end  justify-content-md-start">
                            <p className="mb-0 ">  {item.users?.roles?.[0]?.name} </p>
                          </div>

                          <div className=" col-5 col-md-1  order-2 order-md-5 mx-auto d-flex gap-0 align-items-center justify-content-end ">
                            {item.employee_attachments?.length > 0 ?
                              <button type="button" className="btn  btn-sm btn-icon btn25"
                                onClick={(e) => { viewAttachmet(item.id) }}>
                                <i className="ri-attachment-2 fs-18 primary"  ></i>
                              </button> : " "}

                            <button type="button" className="btn  btn-sm btn-icon btn25"
                              onClick={(e) => { handleEditClick(item); }}>
                              <i className="ri-pencil-fill fs-18 primary"  ></i>
                            </button>

                            <button type="button" className="btn btn-sm btn-icon btn25"
                              onClick={(e) => { onClickData(item); setEmployeeView(item); }}>
                              < i className="ri-delete-bin-fill fs-18 secondary" ></i>
                            </button>

                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>


                ))

                :
                <div className="noresult" >
                  <div className="text-center">

                    <h6 className="mt-2">Sorry! No Result Found</h6>
                    {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                  </div>
                </div>
            }
          </Row>


          <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getEmployeePage(1, e.target.value);
                }}
                value={rowPerPage}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={employeeList?.total}>All</option>
              </select>

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={employeeList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>

          </div>



          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered"    >
              <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => {
                setModalConfirm(!modalConfirm)
              }} centered>
                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                  setModalConfirm(!modalConfirm)
                }}  >
                  {"Confirm"}
                </ModalHeader>

                <ModalBody >
                  <p> Are you sure you want to download Employee Report  </p>
                </ModalBody >

                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => { setModalConfirm(false); }} > No </Button>
                    <Button type="submit" color="success" id="add-btn" onClick={(e) => {
                      getAllReport();
                      setModalConfirm(false);
                    }} > Yes  </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>


          <div className="modal fade" id="showAttachment" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="lg" isOpen={modalAttachment.show} toggle={() => {
                setModalAttachment({
                  ...modalAttachment,
                  show: false,
                  file: [],
                });
              }} centered>

                <ModalHeader className='bpBottom pb-2' toggle={() => {
                  setModalAttachment({
                    ...modalAttachment,
                    show: false,
                    file: [],
                  });
                }}  >
                  {"View Proof"}

                </ModalHeader>

                <ModalBody >

                  {modalAttachment?.show ? <>
                    <div className='text-center pb-2' >
                      <h5 >{modalAttachment?.file?.[currentSlide].name}</h5>
                      {modalAttachment?.file?.length > 1 &&
                        <div className="d-flex justify-content-center py-2">
                          <div className="pagination-wrap hstack gap-2">
                            <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                              <FeatherIcon icon="arrow-left" size="20" />
                            </Link>
                            <ul className="pagination listjs-pagination mb-0"></ul>
                            <Link className="page-item pagination-next" onClick={goToNextSlide}>
                              <FeatherIcon icon="arrow-right" size="20" />
                            </Link>
                          </div>
                        </div>
                      }

                      {modalAttachment?.file?.[0]?.path !== undefined ?

                        getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase() !== ("pdf" || "doc") ?
                          <img src={url + modalAttachment?.file?.[currentSlide]?.path} className='img' />

                          :
                          <iframe src={url + modalAttachment?.file?.[currentSlide]?.path} className='iframe' />


                        : "No Attachment Found"}


                    </div>
                  </>
                    : ""}


                  {modalAttachment?.file?.[0]?.path !== undefined ?

                    <div className='pb-2 text-center' >
                      <button className="btn btn-primary shadow-lg"  >
                        <a className='text-white' href={url + modalAttachment?.file?.[currentSlide]?.path} target='_blank' download>  Download</a>
                      </button>
                    </div>

                    : ""}


                  {modalAttachment?.file?.length > 1 &&
                    <div className="d-flex justify-content-center">
                      <div className="pagination-wrap hstack gap-2">
                        <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                          <FeatherIcon icon="arrow-left" size="20" />
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" onClick={goToNextSlide}>
                          <FeatherIcon icon="arrow-right" size="20" />
                        </Link>
                      </div>
                    </div>
                  }

                </ModalBody>
              </Modal>
            </div>
          </div>


          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement}
            className={`offcanvas-${placement} border-0 offcanvas-600`}    >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">{!view ? !isEdit ? "Add Employee" : "Update Employee" : "View Employee"}</span>
                <span>
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>

              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">

                  {view ? <>

                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Employee Type </Label>  <br />
                          {employeeView.employee_types?.name}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Employee Number </Label>   <br />
                          {employeeView?.employee_number_id}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Role </Label>   <br />
                          {employeeView?.users?.roles[0]?.name}

                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> First Name </Label> <br />
                          {employeeView?.first_name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Last Name </Label> <br />
                          {employeeView?.last_name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Email </Label> <br />
                          {employeeView?.users?.email}
                        </div>
                      </Col>


                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Mobile </Label>   <br />
                          {employeeView?.users?.mobile}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Department  </Label>   <br />
                          {employeeView?.departments?.name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Designation </Label>   <br />
                          {employeeView?.designations?.name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Date of Birth </Label>  <br />
                          {employeeView.date_of_birth ? DefaultDate(employeeView.date_of_birth, selectedCountry) : ""}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Gender </Label>   <br />
                          {employeeView?.gender}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Emergency Contact Name</Label>   <br />
                          {employeeView?.emergency_contact_name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Emergency Contact Number </Label>  <br />
                          {employeeView?.emergency_contact_number}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Parents Name</Label>   <br />
                          {employeeView?.parents_name}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Marital Status </Label>  <br />
                          {employeeView?.marital_status}
                        </div>

                      </Col>
                      {employeeView?.marital_status === "Married" &&
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Spouse Name </Label>  <br />
                            {employeeView?.spouse_name}
                          </div>

                        </Col>}


                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Status  </Label>  <br />

                          {statusList?.filter(name => name?.value === employeeView.status)[0]?.label}
                        </div>
                      </Col>


                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Date of Joining  </Label>  <br />

                          {employeeView.date_of_joining ? DefaultDate(employeeView.date_of_joining, selectedCountry) : ""}
                        </div>

                      </Col>

                      {employeeView.status === "2" &&
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Probation Period   </Label>  <br />
                            {employeeView?.probation_period + " In Days"}
                          </div>

                        </Col>}

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Confirmation Date  </Label>  <br />
                          {employeeView.confirmation_date ? DefaultDate(employeeView.confirmation_date, selectedCountry) : ""}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Notice Period  </Label>  <br />
                          {employeeView?.notice_period + " In Days"}
                        </div>

                      </Col>

                    </Row>



                  </>
                    :
                    <Form className="form-steps"  >

                      <div className="step-arrow-nav mb-4">
                        <Nav
                          className="nav-pills custom-nav nav-justified"
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              href="#"
                              id="steparrow-gen-info-tab"
                              className={classnames({
                                active: activeArrowTab === 4,
                                done: activeArrowTab <= 6 && activeArrowTab > 3,
                                "p-2": true,
                              })}
                              onClick={() => { toggleArrowTab(4); }}

                            >
                              Personal Information
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              href="#"
                              id="steparrow-gen-info-tab"
                              className={classnames({
                                active: activeArrowTab === 5,
                                done: activeArrowTab <= 6 && activeArrowTab > 4,
                                "p-2": true,
                              })}
                              onClick={() => { handleNext(); }}  >
                              Employee Details
                            </NavLink>
                          </NavItem>

                        </Nav>
                      </div>

                      <TabContent activeTab={activeArrowTab}>
                        <TabPane id="steparrow-gen-info" tabId={4}>
                          <div>

                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="first_name" className="form-label">First Name  <span className='secondary'>*</span> </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="first_name" placeholder="Enter first name"
                                    name='first_name'
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.first_name || ""}
                                    invalid={
                                      submit && validation.errors.first_name ? true : false
                                    }
                                  />

                                </div>

                              </Col>

                              <Col lg={6}>

                                <div className="mb-3">
                                  <Label htmlFor="last_name" className="form-label">Last Name   </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="last_name" placeholder="Enter last name"
                                    name='last_name'
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.last_name || ""}
                                    invalid={
                                      submit && validation.errors.last_name ? true : false
                                    }
                                  />

                                </div>

                              </Col>

                              {!isEdit && <>       <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="email" className="form-label">Email  <span className='secondary'>*</span></Label>
                                  <Input type="text" className="form-control px-2 py-1" id="email" placeholder="Enter email"
                                    name='email'
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      submit && validation.errors.email ? true : false
                                    }
                                  />

                                </div>

                              </Col>


                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="mobile" className="form-label">Mobile   </Label>
                                    <Input type="number" className="form-control px-2 py-1" id="mobile" placeholder="Enter mobile" name='mobile'
                                      validate={{
                                        required: { value: true },
                                      }}

                                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                      onChange={(e) => {
                                        let inputValue = e.target.value;

                                        if (inputValue.length > 10) {
                                          inputValue = inputValue.slice(0, 10);
                                        }

                                        validation.handleChange({
                                          target: {
                                            name: 'mobile',
                                            value: inputValue,
                                          },
                                        });

                                      }}

                                      onBlur={validation.handleBlur}
                                      value={validation.values.mobile || ""}
                                      invalid={
                                        submit && validation.errors.mobile ? true : false
                                      }
                                    />

                                  </div>
                                </Col>

                                {/* <Col lg={6}>


                                  <div className="mb-3">
                                    <Label htmlFor="username" className="form-label">Username <span className='secondary'>*</span> </Label>
                                    <Input type="text" className="form-control px-2 py-1" id="username" placeholder="Enter username"
                                      name='username'
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.username || ""}
                                      invalid={
                                        submit && validation.errors.username ? true : false
                                      }
                                    />

                                  </div>

                                </Col> */}


                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="password" className="form-label">Password
                                      <span className='secondary'>*</span> </Label>
                                    <Input type={passwordShow ? "text" : "password"} className="form-control px-2 py-1" id="password" placeholder="Enter password" name='password'
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.password || ""}
                                      invalid={
                                        submit && validation.errors.password ? true : false
                                      }
                                    />

                                    <button
                                      className="btn btn-link position-absolute top-50 end-0 pt-1 px-4 mt-2 translate-middle-y text-decoration-none text-muted"
                                      onClick={() => setPasswordShow(!passwordShow)}
                                      type="button"

                                    >
                                      <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                    </button>
                                  </div>
                                </Col>

                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="confirm" className="form-label">Confirm Password <span className='secondary'>*</span> </Label>
                                    <Input type={passwordShow ? "text" : "password"} className="form-control px-2 py-1" id="confirm" placeholder="Enter confirm password" name='confirm'
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.confirm || ""}
                                      invalid={
                                        submit && validation.errors.confirm ? true : false
                                      }
                                    />

                                    <button
                                      className="btn btn-link position-absolute top-50 end-0 pt-1 px-4 mt-2 translate-middle-y text-decoration-none text-muted"

                                      onClick={() => setPasswordShow(!passwordShow)}
                                      type="button"

                                    >
                                      <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                    </button>
                                  </div>
                                </Col>

                              </>}


                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="date_of_birth" className="form-label">Date of Birth <span className='secondary'>*</span>   </Label>
                                  <Flatpickr
                                    name='date_of_birth'
                                    className={`form-control py-1 px-2 ${submit && validation.errors.date_of_birth ? 'is-invalid' : ''}`}

                                    options={{
                                      allowInput: true,
                                      dateFormat: SmallDateFormat(selectedCountry),
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                      validation.setValues({
                                        ...validation.values,
                                        date_of_birth: dateStr
                                      });
                                    }}
                                    value={validation.values.date_of_birth || ""}

                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">

                                  <Label htmlFor="gender" className="form-label">Gender     </Label>

                                  <FormGroup tag={Row}>
                                    <Col className="d-flex gap-2 align-items-center" >

                                      <Label check className="d-flex gap-2 align-items-center">
                                        <Input
                                          type="radio"
                                          name="gender"
                                          value="Male"
                                          checked={validation.values.gender === 'Male'}
                                          onChange={validation.handleChange}

                                        />
                                        Male
                                      </Label>

                                      <Label check className="d-flex gap-2 align-items-center">
                                        <Input
                                          type="radio"
                                          name="gender"
                                          value="Female"
                                          checked={validation.values.gender === 'Female'}
                                          onChange={validation.handleChange}

                                        />
                                        Female
                                      </Label>
                                    </Col>

                                  </FormGroup>

                                </div>
                              </Col>





                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="emergency_contact_name" className="form-label">Emergency Contact Name   <span className='secondary'>*</span>  </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="emergency_contact_name" placeholder="Enter emergency contact name" name='emergency_contact_name'
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.emergency_contact_name || ""}
                                    invalid={
                                      submit && validation.errors.emergency_contact_name ? true : false
                                    }
                                  />

                                </div>
                              </Col>


                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="emergency_contact_number" className="form-label">Emergency Contact Number   <span className='secondary'>*</span>  </Label>
                                  <Input type="number" className="form-control px-2 py-1" id="emergency_contact_number"
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                    placeholder="Emergency contact number" name='emergency_contact_number'
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={(e) => {

                                      let inputValue = e.target.value;

                                      if (inputValue.length > 10) {
                                        inputValue = inputValue.slice(0, 10);
                                      }

                                      validation.handleChange({
                                        target: {
                                          name: 'emergency_contact_number',
                                          value: inputValue,
                                        },
                                      });

                                    }
                                    }
                                    onBlur={validation.handleBlur}
                                    value={validation.values.emergency_contact_number || ""}
                                    invalid={
                                      submit && validation.errors.emergency_contact_number ? true : false
                                    }
                                    max={10}
                                  />

                                </div>
                              </Col>



                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="parents_name" className="form-label">Parents Name </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="parents_name" placeholder="Enter parents name" name='parents_name'

                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.parents_name || ""}

                                  />

                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="profile" className="form-label">Profile Picture  </Label>


                                  <Input type="file" accept="image/*" className="form-control px-2 py-1" id="profile"
                                    name='profile'

                                    onChange={handleProfileChange}

                                  />

                                </div>

                                <Row  >

                                  {(files || [])?.map((item, index) => {
                                    return [
                                      <Col xs={4} md={6} key={index} className='d-flex align-items-center justify-content-center  ' >
                                        <FaTimesCircle className="close-button" onClick={(e) => {

                                          setFiles([]);
                                          setFile([]);
                                        }} />
                                        <div className="selected-image cursor" key={index} onClick={(e) => {
                                          setModalProfile(true); setFileUrl({ profile: true, name: item.name, path: item.preview });
                                        }}>
                                          {imageExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                            <img
                                              src={item.preview}
                                              alt={item.name}
                                              width={100}
                                              height={80}
                                            />
                                            : pdfExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                              <FaFilePdf size={30} />
                                              : excelExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                                <FaFileExcel size={30} />
                                                : <FaFileAlt size={30} />

                                          }
                                        </div>
                                      </Col>

                                    ]
                                  })}

                                  {validation.values.profile &&
                                    <Col xs={4} md={6} className='text-center ' >

                                      <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                        handleRemoveProfile(validation.values.id);
                                      }} />

                                      <div className="selected-image cursor d-flex text-center" onClick={(e) => {
                                        setModalFile(true); setFileUrl({ profile: true, name: "", path: url + validation.values.profile, ext: url + validation.values.profile });
                                      }}>


                                        {imageExtensions.has(getExtension(validation.values.profile)?.toLowerCase()) ?
                                          <img

                                            src={url + validation.values.profile}
                                            alt={validation.values.profile}
                                            width={100}
                                            height={80}


                                          />
                                          : pdfExtensions.has(getExtension(validation.values.profile)?.toLowerCase()) ?
                                            <FaFilePdf size={30} />
                                            : excelExtensions.has(getExtension(validation.values.profile)?.toLowerCase()) ?
                                              <FaFileExcel size={30} />
                                              : <FaFileAlt size={30} />

                                        }



                                      </div>
                                    </Col>
                                  }


                                </Row>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">

                                  <Label htmlFor="marital_status" className="form-label">Marital Status     </Label>

                                  <FormGroup tag={Row}>
                                    <Col className="d-flex gap-2 align-items-center" >

                                      <Label check className="d-flex gap-2 align-items-center">
                                        <Input
                                          type="radio"
                                          name="marital_status"
                                          value="Unmarried"
                                          checked={validation.values.marital_status === 'Unmarried'}
                                          onChange={validation.handleChange}

                                        />
                                        Unmarried
                                      </Label>

                                      <Label check className="d-flex gap-2 align-items-center">
                                        <Input
                                          type="radio"
                                          name="marital_status"
                                          value="Married"
                                          checked={validation.values.marital_status === 'Married'}
                                          onChange={validation.handleChange}

                                        />
                                        Married
                                      </Label>


                                    </Col>

                                  </FormGroup>

                                </div>
                              </Col>

                              {validation.values.marital_status === 'Married' && <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="spouse_name" className="form-label">Spouse Name </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="spouse_name" placeholder="Enter spouse name" name='spouse_name'

                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.spouse_name || ""}

                                  />

                                </div>
                              </Col>}




                            </Row>

                          </div>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-primary shadow-lg btn-label right ms-auto nexttab nexttab"
                              onClick={() => {

                                handleNext();
                              }}
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                              Next
                            </button>
                          </div>
                        </TabPane>

                        <TabPane id="steparrow-description-info" tabId={5}>
                          <div>

                            <Row>



                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="employee_type_id" className="form-label">Employee Type  <span className='secondary'>*</span> </Label>

                                  <Select
                                    value={validation.values.employee_type_id ? employeeTypeList?.filter(
                                      item => item?.id === validation.values.employee_type_id)?.map((item, index) => {
                                        return ({ value: item.id, label: item.name });
                                      }) : ""}


                                    onChange={(e) => {
                                      validation.setValues({
                                        ...validation.values,
                                        employee_type_id: e?.value,
                                        employee_number_id:
                                          isEdit ?
                                            ((e?.value === 2) ? e?.value === employeeView.employee_type_id ? employeeView.employee_number_id
                                              : settingsList.filter(type => type?.name === "permanent_employee")[0]?.value + Number("000") + Number(permentCount + 1).toString().padStart("00".length, '0')
                                              : (e?.value === 3) ? e?.value === employeeView.employee_type_id ? employeeView.employee_number_id
                                                : settingsList.filter(type => type?.name === "temporary_employee")[0]?.value + Number("000") + Number(temporaryCount + 1).toString().padStart("00".length, '0')
                                                : "")

                                            : ((e?.value === 2) ? settingsList.filter(type => type?.name === "permanent_employee")[0]?.value + Number("000") + Number(permentCount + 1).toString().padStart("00".length, '0') :
                                              (e?.value === 3) ? settingsList.filter(type => type?.name === "temporary_employee")[0]?.value + Number("000") + Number(temporaryCount + 1).toString().padStart("00".length, '0') : "")

                                      });
                                    }}

                                    options={employeeTypeList?.map((item) => {
                                      return (
                                        { value: item.id, label: item.name }
                                      );
                                    })}

                                    styles={selectStyle('employee_type_id')}
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="employee_number_id" className="form-label">Employee Number <span className='secondary'>*</span>   </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="employee_number_id" placeholder="Enter employee number"
                                    name='employee_number_id'

                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.employee_number_id || ""}
                                    invalid={submit && validation.errors.employee_number_id ? true : false}

                                    style={{
                                      background: (validation.values.employee_type_id === 2 || validation.values.employee_type_id === 3) ? "#8080802b" : "",
                                    }}

                                    readOnly={(validation.values.employee_type_id === 2 || validation.values.employee_type_id === 3) ? true : false}
                                  />

                                </div>

                              </Col>



                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="department_id" className="form-label">Department <span className='secondary'>*</span></Label>

                                    <Select
                                    value={validation.values.department_id ? departmentAllList?.filter(
                                      item => item?.id === validation.values.department_id)?.map((item) => {

                                        return (
                                          { value: item.id, label: item.name }
                                        );
                                      }) : ""}


                                    onChange={(e) => {
                                      validation.setValues({
                                        ...validation.values,
                                        department_id: e?.value
                                      });

                                      handleDepartment(e?.value);

                                    }}

                                    options={departmentAllList?.map((item) => {

                                      return (
                                        { value: item.id, label: item.name }
                                      );
                                    })}
                                    styles={selectStyle('department_id')}
                                  />  

                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="designation_id" className="form-label">Designation
                                    <span className='secondary'>*</span> </Label>

                                  <Select


                                    value={validation.values.designation_id ? designationAllList?.filter(
                                      item => item?.id === validation.values.designation_id)?.map((item, index) => {

                                        return (
                                          { value: item.id, label: item.name }
                                        );
                                      }) : ""}


                                    onChange={(e) => {

                                      validation.setValues({
                                        ...validation.values,
                                        designation_id: e?.value
                                      });
                                    }}

                                    options={designationAllList?.map((item) => {

                                      return (
                                        { value: item.id, label: item.name }
                                      );
                                    })}


                                    styles={selectStyle('designation_id')}
                                  />
                                </div>
                              </Col>  

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="role_id" className="form-label">Role <span className='secondary'>*</span> </Label>

                                  <Select
                                    value={validation.values.role_id ? roleList?.filter(
                                      item => item?.id === validation.values.role_id)?.map((item, index) => {

                                        return (
                                          { value: item.id, label: item.name }
                                        );
                                      }) : ""}


                                    onChange={(e) => {

                                      validation.setValues({
                                        ...validation.values,
                                        role_id: e?.value
                                      });


                                    }}

                                    options={roleList?.filter(item => item?.name !== "Admin")?.map((item) => {

                                      return (
                                        { value: item.id, label: item.name }
                                      );
                                    })}


                                    styles={selectStyle('role_id')}
                                  />
                                </div>
                              </Col>




                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="status" className="form-label">Status  <span className='secondary'>*</span> </Label>

                                  <Select

                                    value={validation.values.status ? statusList?.filter(
                                      item => item?.value === validation.values.status)?.map((item, index) => {

                                        return ({ value: item.value, label: item.label });

                                      }) : ""}


                                    onChange={(e) => {
                                      validation.setValues({
                                        ...validation.values,
                                        status: e?.value
                                      });
                                    }}

                                    options={statusList?.map((item) => {
                                      return ({ value: item.value, label: item.label });
                                    })}


                                    styles={selectStyle('status')}
                                  />
                                </div>
                              </Col>




                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="date_of_joining" className="form-label">Date of Joining  <span className='secondary'>*</span>  </Label>

                                  <Flatpickr
                                    name='date_of_joining'
                                    className={`form-control py-1 px-2 ${submit && validation.errors.date_of_joining ? 'is-invalid' : ''}`}

                                    options={{
                                      allowInput: true,
                                      dateFormat: SmallDateFormat(selectedCountry),
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                      validation.setValues({
                                        ...validation.values,
                                        date_of_joining: dateStr
                                      });
                                      if (validation.values.probation_period !== undefined) {
                                        const probationPeriod = parseInt(validation.values.probation_period);
                                        if (!isNaN(probationPeriod)) {
                                          const date_of_joining = new Date(FormatDate(dateStr, selectedCountry));
                                          date_of_joining.setDate(date_of_joining.getDate() + probationPeriod);
                                          validation.setValues({
                                            ...validation.values,
                                            confirmation_date: moment(date_of_joining, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY')
                                          });
                                        }
                                      }
                                    }}

                                    value={validation.values.date_of_joining || ""}

                                  />

                                </div>

                              </Col>


                              {validation.values.status === "2" &&
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="probation_period" className="form-label">Probation Period (In Days)   <span className='secondary'>*</span>  </Label>
                                    <Input type="number" className="form-control px-2 py-1" id="probation_period" placeholder="In Days"
                                      name='probation_period'
                                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                      onChange={validation.handleChange}
                                      onBlur={(e) => {
                                        validation.handleBlur(e);
                                        if (validation.values.date_of_joining !== undefined) {
                                          const probationPeriod = parseInt(e.target.value, 10);

                                          if (!isNaN(probationPeriod)) {
                                            const date_of_joining = new Date(FormatDate(validation.values.date_of_joining, selectedCountry));
                                            date_of_joining.setDate(date_of_joining.getDate() + probationPeriod);


                                            validation.setValues({
                                              ...validation.values,
                                              confirmation_date: moment(date_of_joining, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY')
                                            });

                                          }
                                        }
                                      }}
                                      value={validation.values.probation_period || ""}
                                      invalid={submit && validation.errors.probation_period ? true : false}
                                    />

                                  </div>

                                </Col>}


                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="confirmation_date" className="form-label">Confirmation Date <span className='secondary'>*</span>   </Label>

                                  <Flatpickr
                                    name='confirmation_date'
                                    className={`form-control py-1 px-2 ${submit && validation.errors.confirmation_date ? 'is-invalid' : ''}`}
                                    options={{
                                      allowInput: true,
                                      minDate: FormatMinDate(validation.values.date_of_joining, selectedCountry),
                                      dateFormat: SmallDateFormat(selectedCountry),
                                      onReady: function (selectedDates, dateStr, instance) {
                                        instance.set('minDate', FormatMinDate(validation.values.date_of_joining, selectedCountry));
                                      }
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                      validation.setValues({
                                        ...validation.values,
                                        confirmation_date: dateStr
                                      });
                                    }}

                                    disabled={validation.values.status === "2"}
                                    value={validation.values.confirmation_date || ""}
                                    style={{ background: validation.values.status === "2" ? "#8080802b" : "" }}
                                  />
                                </div>

                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="notice_period" className="form-label">Notice Period  (In Days) <span className='secondary'>*</span>  </Label>
                                  <Input type="number" className="form-control px-2 py-1" id="notice_period" placeholder="Enter notice period"
                                    name='notice_period'
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                    onChange={(e) => {

                                      let inputValue = e.target.value;

                                      if (inputValue.length > 3) {
                                        inputValue = inputValue.slice(0, 3);
                                      }

                                      validation.handleChange({
                                        target: {
                                          name: 'notice_period',
                                          value: inputValue,
                                        },
                                      });

                                    }}

                                    onBlur={validation.handleBlur}
                                    value={validation.values.notice_period || ""}
                                    invalid={
                                      submit && validation.errors.notice_period ? true : false
                                    }
                                  />

                                </div>
                              </Col>


                              <Col lg={12} className='py-2'>
                                <div className="mb-2">
                                  <div className='d-flex justify-content-between align-items-end'>
                                    <Label className="form-label">Upload Proof</Label>
                                    <button
                                      type="button"
                                      className="btn btn-primary shadow-lg px-2 py-1 mb-2 d-flex align-items-center gap-1"
                                      onClick={handleAddInput}
                                    >
                                      <FaPlus /> More
                                    </button>
                                  </div>


                                  {inputs.map((input, index) => (
                                    <div key={index} className='d-flex gap-3 py-1 align-items-center'>
                                      <Input type="text" className="form-control px-2 py-1" placeholder="Enter title" name='title'
                                        onChange={(e) => handleTitleChange(index, e.target.value)}

                                      />
                                      <Input type="file" accept=".pdf, image/*" className="form-control px-2 py-1" name='attachment'
                                        onChange={(e) => handleProofChange(index, e.target.files[0])}
                                      />
                                      <FaTimesCircle className="cursor text-danger" size={25} style={{ minWidth: "20px" }} onClick={() => handleRemoveInput(index)} />
                                    </div>
                                  ))}
                                </div>

                                <Row className='py-2  '>
                                  {(inputs || [])?.filter(item => item.preview !== "")?.map((item, index) => {
                                    return [
                                      <Col xs={4} md={3} key={index} className='text-center p-2' >
                                        {index !== 0 &&
                                          <FaTimesCircle className="close-button" onClick={(e) => {
                                            handleRemoveInput(index);
                                          }} />}
                                        <div className="selected-image cursor d-flex text-center mb-2" onClick={(e) => {
                                          setModalFile(true);
                                          setFileUrl({ profile: false, name: item.title, path: item.preview, ext: item.file?.name });
                                        }}>
                                          {imageExtensions.has(getExtension(item.file?.name)?.toLowerCase()) ?
                                            <img
                                              src={item.preview}
                                              alt={item.file?.name}
                                              width={100}
                                              height={80}
                                            />
                                            : pdfExtensions.has(getExtension(item.file?.name)?.toLowerCase()) ?
                                              <FaFilePdf size={30} />
                                              : excelExtensions.has(getExtension(item.file?.name)?.toLowerCase()) ?
                                                <FaFileExcel size={30} />
                                                : <FaFileAlt size={30} />
                                          }
                                        </div>

                                        {item.title}
                                      </Col>
                                    ]
                                  })}


                                  {attachmentList?.map((item, index) => {

                                    return [
                                      <Col xs={4} md={3} key={index} className='text-center p-2' >
                                        <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                          handleDelete(item.id);
                                        }} />

                                        <div className="selected-image cursor d-flex text-center mb-2" onClick={(e) => {
                                          setModalFile(true); setFileUrl({ profile: false, name: item.name, path: url + item.path, ext: url + item.path });
                                        }}>

                                          {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                            <img
                                              src={url + item.path}
                                              alt={item.name}
                                              width={100}
                                              height={80}

                                            />
                                            : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                              <FaFilePdf size={30} />
                                              : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                <FaFileExcel size={30} />
                                                : <FaFileAlt size={30} />
                                          }



                                        </div>

                                        {item.name}
                                      </Col>
                                    ]
                                  })}

                                </Row>
                              </Col>

                              <div className="modal fade" tabIndex="-1" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                  <Modal size="lg" isOpen={modalFile} toggle={() => {
                                    setModalFile(false);
                                  }} centered>

                                    <ModalHeader className='bpBottom pb-2' toggle={() => {
                                      setModalFile(false);
                                    }}  >
                                      {fileUrl.profile ? "View Profile" : "View Proof"}

                                    </ModalHeader>
                                    <ModalBody >
                                      {modalFile && <>
                                        <div className='text-center py-4' >
                                          <h5  >{fileUrl.name}</h5>
                                          {imageExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                                            <img
                                              src={fileUrl.path}
                                              alt={fileUrl.name}
                                              width={isDesktop ? 500 : "100%"}
                                              height={"100%"}
                                            />
                                            : pdfExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                                              <iframe src={fileUrl.path} className='iframe' />
                                              : excelExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                                                <>  {fileUrl.name}</>
                                                : <>  {fileUrl.name}</>

                                          }


                                        </div>
                                      </>
                                      }

                                      <div className='pt-2 text-center' >
                                        <button className="btn btn-primary shadow-lg"  >
                                          <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                                        </button>
                                      </div>

                                    </ModalBody>
                                  </Modal>
                                </div>
                              </div>


                              <div className="modal fade" tabIndex="-1" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                  <Modal size="lg" isOpen={modalProfile} toggle={() => {
                                    setModalProfile(false);
                                  }} centered>

                                    <ModalHeader className='bpBottom pb-2' toggle={() => {
                                      setModalProfile(false);
                                    }}  >
                                      {"View Profile Picture"}
                                    </ModalHeader>
                                    <ModalBody >
                                      {modalProfile && <>
                                        <div className='text-center py-4' >
                                          <h5  >{fileUrl.name}</h5>
                                          <img
                                            src={fileUrl.path}
                                            alt={fileUrl.name}
                                            width={isDesktop ? 500 : "100%"}
                                            height={"100%"}
                                          />
                                        </div>
                                      </>
                                      }

                                      <div className='pt-2 text-center' >
                                        <button className="btn btn-primary shadow-lg"  >
                                          <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                                        </button>
                                      </div>

                                    </ModalBody>
                                  </Modal>
                                </div>
                              </div>

                            </Row>
                          </div>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-light btn-label previestab"
                              onClick={() => {
                                handlePrevious();
                              }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                              Previous
                            </button>
                            <button
                              type="button"
                              disabled={validation.isSubmitting ? true : false}
                              className="btn btn-primary shadow-lg btn-label right ms-auto nexttab nexttab"
                              onClick={() => {
                                handleSubmit();

                              }}
                            >
                              {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                              <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                              Submit
                            </button>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Form>
                  }
                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Employee;