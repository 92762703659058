import React from "react";
import { Card, CardBody } from "reactstrap";

function UpcommingEvents(props) {

 
  return (
    <Card className="mb-3">
      <CardBody>
        
        <h6 className="card-title fs-16">{props.event.title}</h6>
          <p className="text-muted text-truncate-two-lines mb-0">
          {props.event.date === "N.A." ? "" : props.DefaultDate(props.event.date, props.selectedCountry) }
       </p> 
      </CardBody>
    </Card>
  );
}

export default UpcommingEvents;