import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, CardBody, Col, Container, Dropdown, ModalHeader,   Offcanvas,
  OffcanvasHeader,  OffcanvasBody, Collapse, Form, Input, Label, Modal, ModalBody, FormGroup, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import { FaCheck, FaTimesCircle, FaTimes, FaPlus } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const Category = () => {
  document.title = "Category ";
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [isExpanded, setIsExpanded] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [categoryView, setCategoryView] = useState([]);
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [searchList, setSearchList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);

  const handleAddClick = (arg) => {
    setSubmit(false);
    validation.resetForm();
    validation.setValues({});
    setIsEdit(false);
    toggleDrawer();
  };

  const handleEditClick = useCallback((arg) => {
    setSubmit(false);
    viewCategory(arg?.id);
    validation.resetForm();
    setIsEdit(true);
  }, []);

  const onClickData = () => {
    setDeleteModal(true);
  };

  const [categoryList, setCategoryList] = useState([]);

  const keywordSearch = async (e) => {
    let inputVal = e;
    if (inputVal !== "") {
      const filteredResults = searchList?.data?.filter(item =>
        item.name?.toLowerCase().includes(inputVal.toLowerCase()) || 
        item?.description?.toLowerCase().includes(inputVal.toLowerCase())
      );

      setCategoryList({
        ...categoryList,
        data: filteredResults
      });

    } else {
      setCategoryList({
        ...categoryList,
        data: searchList?.data
    });
    }
  };
  
  const getCategory = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`documentCategory`);
      if (response.data.status === true) {
        setCategoryList(response.data?.data);
        setSearchList(response.data?.data);
      } else {
        setCategoryList([]);
        setSearchList([]);

        ToastMessage("error", response.data?.message);
      }
      setCurrentPage(1);

    } catch (err) {
      console.error(err.message);
    }

  };

  const getCategoryPage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`documentCategory?page=${currentPage}&perPage=${rowPerPage}`);
      if (response.data.status === true) {
        setCategoryList(response.data?.data);
        setSearchList(response.data?.data);
      } else {
        setCategoryList([]);
        setSearchList([]);

       ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }
  };


  const viewCategory = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('documentCategory/' + id, {});
      if(response.data.status === true) {
        validation.setValues(response.data?.data);
        setCategoryView(response.data?.data);
      } else {
        setCategoryView([]);
      }
      setOpen(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);


  const addCategory = async (values) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.post('documentCategory', values);
      if (response.data.status === true) {
        getCategory();
        validation.resetForm();
        setIsEdit(false);
        setOpen(false);
        ToastMessage("success", response.data?.message);

      } else {
       ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const editCategory = async (values, id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.put('documentCategory/' + id, values);
      if (response.data.status === true) {
        getCategory();
        validation.resetForm();
        setIsEdit(false);
        setOpen(false);
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }

      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteCategory = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('documentCategory/' + value?.id, {});
      if (response.data.status === true) {
        getCategory();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)

    } catch (err) {
      console.error(err.message);
    }
  };


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please name"),
      description: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        editCategory(values, validation.values?.id);
      } else {
        addCategory({
          name: values?.name,
          description: values?.description ? values?.description : " ",
          status: 1
        });
      }
    },
  });

  const handleClick = (e) => {
    setCurrentPage(e);
    getCategoryPage(e, rowPerPage);
  };

  const handleToggle = (index) => {
    const newExpandedStates = [...isExpanded];
    newExpandedStates[index] = !newExpandedStates[index];
    setIsExpanded(newExpandedStates);
  };

  useEffect(() => {
    setIsExpanded(categoryList?.data?.map(() => false))
  }, [categoryList]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        name={categoryView?.name}
        show={deleteModal}
        onDeleteClick={() => deleteCategory(categoryView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Category</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
            </Col>
            <Col className="col-sm-auto ms-auto d-flex justify-content-end gap-2">
              {/* <div className="search-box">
                <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                <i className="ri-search-line search-icon"></i>
              </div> */}
              <div className="list-grid-nav hstack gap-1">

                <button color="primary" className="btn btn-primary shadow-lg  d-flex   gap-2 px-3 py-1" onClick={() => handleAddClick()} >
                  <i className="ri-add-line"  ></i>  <span> Category</span></button>
                {/* <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); }} /> */}
              </div>
            </Col>
          </Row>

          <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
            <Col lg={12} className='table px-1  mb-0 '  >
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                    <div className="col-6 col-md-3   px-2"  >
                      <h5 className="fs-15 mb-0">Name</h5>
                    </div>

                    <div className="d-flex  col-6 col-md-8 mt-0 align-items-center  justify-content-end  justify-content-md-start px-md-2">
                      <h5 className="fs-15 mb-0">Description</h5>
                    </div>

                    <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end ">
                      <h5 className="fs-15 mb-0">  Action</h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1" id="category-list">
            {
              categoryList?.data?.length !== 0 ?

                (categoryList?.data)?.map((item, index) => (

                  <Col lg={12} key={index} className='mt-0 '  >

                    <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                      <CardBody className="px-2 py-0">

                        <div className="d-flex align-items-center row px-2">

                          <div className="col-10 col-md-3 order-1 order-md-1 px-1">
                            <p className="mb-0"> {item.name}  </p>
                          </div>

                          <div className={`${isExpanded?.[index] ? "d-block" : "d-flex"} col-12 col-md-8 order-3 order-md-2  gap-4 px-1 px-md-2 align-items-center`}>
                            <p className="mb-0">
                              {isExpanded?.[index] ? item?.description : item?.description?.length > (isMobile ? 35 : 80) ? item?.description?.slice(0, (isMobile ? 35 : 80)) + "..." : item?.description}
                              {item?.description?.length > (isMobile ? 35 : 80) && (
                                <a href="#!" key={index} onClick={() => handleToggle(index)}>
                                  {isExpanded?.[index] ? ' Read Less' : ' Read More'}
                                </a>
                              )}
                            </p>
                          </div>

                          <div className={`col-2 col-md-1  order-2 order-md-3 d-flex gap-0 align-items-center justify-content-end px-0 px-md-1`}>
                            <button type="button" className="btn  btn-sm btn-icon btn25"
                              onClick={(e) => { handleEditClick(item); }}>
                              <i className="ri-pencil-fill fs-18 primary"  ></i>
                            </button>

                            <button type="button" className="btn btn-sm btn-icon btn25"
                              onClick={(e) => { onClickData(item); setCategoryView(item); }}>
                              <i className="ri-delete-bin-fill fs-18 secondary" ></i>

                            </button>

                          </div>
                        </div>
                      </CardBody>
                    </Card>

                  </Col>

                ))

                :
                <div className="noresult" >
                  <div className="text-center">

                    <h6 className="mt-2">Sorry! No Result Found</h6>

                  </div>
                </div>
            }
          </Row>

 

          <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">

            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getCategoryPage(1, e.target.value);
                }}
                value={rowPerPage}
              >

                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={categoryList?.total}>All</option>

              </select>


              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={categoryList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />

            </div>

          </div>


          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">{!isEdit ? "Add Category" : "Update Category"}</span>
                <span>
                  <span className="fs-18 px-1  text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span>
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>
              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">
                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-2">
                          <Label htmlFor="name" className="form-label">Name <span className='secondary'>*</span></Label>
                          <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                            name='name'

                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={submit && validation.errors.name ? true : false
                            }
                          />

                        </div>

                      </Col>

                      <Col lg={12}>
                        <div className="mb-2">
                          <Label htmlFor="description" className="form-label">Description</Label>
                          <Input type="textarea" className="form-control px-2 py-1" id="description" placeholder="Enter description"
                            name='description'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={submit && validation.errors.description ? true : false}
                            maxLength={250}
                            style={{ height: "100px", minHeight: "100px", }}
                            multiline="true"

                          />

                        </div>
                      </Col>

                      <Col lg={12}  >
                        <div className="hstack gap-2 justify-content-end">
                          <button type="submit" className="btn btn-primary shadow-lg" id="addNewCategory" onClick={() => {
                            setSubmit(true);
                          }}>
                            {!isEdit ? "Add Category" : "Update Category"}</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Category;