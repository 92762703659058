import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody,  Container,  Row, Col } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import listPlugin from '@fullcalendar/list';
import { useSelector, useDispatch } from "react-redux";
import {api , url} from "../../helpers/api";
import SimpleBar from "simplebar-react";
import UpcommingEvents from './Events';
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import {  DefaultDate  } from "../../Components/Hooks/Function";

import {
  // getEvents as onGetEvents,
  // getCategories as onGetCategories,
  //  addNewEvent as onAddNewEvent,
  // deleteEvent as onDeleteEvent,
  // updateEvent as onUpdateEvent,
  //resetCalendar,
} from "../../slices/thunks";
// import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";

const Calender = () => {
  const history = useNavigate();
  const dispatch = useDispatch(); 
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const isMobile = window.innerWidth <= 768; 
  const userDetails = getLoggedinUser()?.user; 

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  };

  useEffect(() => {
    getSettings();
  }, []);
 
  const [holidayList, setHolidayList] = useState([]);

  const getHoliday = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`getAllHoliday`, {});

      if (response.data.status === true) {
        setHolidayList(response.data?.data);
      } else {
        setHolidayList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const filteredUpcomingEvents = holidayList.filter(event => {
    const eventDate = new Date(event.date);
    return eventDate > yesterday;
  });

  useEffect(() => {
    getHoliday();
  }, []);
 
  const onDrag = (event) => {
    event.preventDefault();
  };

 
  const onDrop = (event) => {
    const date = event["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );

    const draggedEl = event.draggedEl;
    const draggedElclass = draggedEl.className;
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") === -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 1000),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      };
      dispatch(onAddNewEvent(modifiedData));
    }
  };


  document.title = "Calendar";
  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <Container fluid>

          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Calendar</h4>

                {userDetails?.roles?.[0]?.name === "Admin" && <button color="primary" className="btn btn-primary shadow-lg d-none   d-md-flex gap-2 px-3 py-1" onClick={() => { history("/holiday"); }} >
                  <i className="ri-arrow-left-line"></i> <span > Back to Holidays </span></button>
                }
              </div>
            </Col>
          </Row>

 
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3} className="order-2 order-md-1" >
                  <Card className="card-h-100">
                    <CardBody>
                      <div id="external-events">
                        <div>
                          <h5 className="mb-2">Upcoming Holidays</h5>

                          <SimpleBar
                            className="pe-2 me-n1 mb-3"
                            style={{ height: "100vh" }}
                          >
                            <div id="upcoming-event-list">
                              {filteredUpcomingEvents &&
                                filteredUpcomingEvents.map((event, key) => (
                                  <UpcommingEvents event={event} key={key}
                                  DefaultDate={DefaultDate}
                                    selectedCountry={selectedCountry}
                                  />
                                ))}
                            </div>
                          </SimpleBar>
                        </div>
                      </div>
                    </CardBody>
                  </Card>


                </Col>

                <Col xl={9} className="order-1 order-md-2" >
                  <Card className="card-h-100">
                    <CardBody className="calender">
                      <FullCalendar
                       initialView={isMobile? "listWeek": "dayGridMonth"}
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin
                        ]}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "prev,next today",
                        }}
                        
                        // slotDuration={"00:15:00"}
                        // handleWindowResize={true}
                        themeSystem="bootstrap"

                      

                        events={holidayList ? holidayList.map((item, index) => {
                          return ({ 
                            "title" : item.title,
                            "start": item.date,
                            "end": item.date,
                            "className": "bg-primary-subtle",
                            "allDay": true,
                             
                            }
                          );
                        }) : []}

                        
                        editable={false}
                        droppable={false}
                        selectable={false}
                      //  dateClick={handleDateClick}
                      //  eventClick={handleEventClick}
                      //  drop={onDrop}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div style={{ clear: "both" }}></div>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Calender;
