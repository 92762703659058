import React from "react";
import { Card, CardBody } from "reactstrap";

function UpcommingEvents(props) {

  return (
    <Card className="mb-3">
      <CardBody>
        {/* <div className="d-flex mb-3">
          <div className="flex-grow-1">
            <i  className={"mdi mdi-checkbox-blank-circle me-2 text-info"}  ></i>
            <span className="fw-medium">
              {startDate} {end_dt}
            </span>
          </div>
          <div className="flex-shrink-0">
            <small className="badge bg-primary-subtle text-primary ms-auto">
              {e_time_s} {e_time_e}
            </small>
          </div>
        </div> */}
        <h6 className="card-title fs-16">{props.event.title}</h6>
        <p className="text-muted text-truncate-two-lines mb-0"> {props.event.date === "N.A." ? "" : props.DefaultDate(props.event.date, props.selectedCountry)}  </p>
      </CardBody>
    </Card>
  );
}

export default UpcommingEvents;