import React, { useState, useEffect } from 'react';
import { Col, Container,Button, Offcanvas, Table, OffcanvasHeader, Spinner, OffcanvasBody, Badge, Form, Input, Label, Row, } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { api, url } from "../../helpers/api";
import { FaCheck, FaTimes, } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import SimpleBar from "simplebar-react";
import { ToastContainer, toast } from 'react-toastify';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import FeatherIcon from "feather-icons-react";
import { FcFilledFilter, FcClearFilters } from "react-icons/fc";
import { getLoggedinUser, getSettingsDetails, getAddressFromCoordinates } from "../../helpers/api_helper";
import AttendanceData from "../../Components/Common/AttendanceData";
import { TimeDifference, ToastMessage, DefaultDate } from "../../Components/Hooks/Function";
import { utils, writeFile,read } from 'xlsx';
import axios from "axios";

const Attendance = () => {
    document.title = "Attendance";
    const history = useNavigate();
    const [location, setLocation] = useState([]);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [deviceType, setDeviceType] = useState(null);
    const [shift, setShift] = useState([]);
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    var data = getAddressFromCoordinates(latitude, longitude);
                    setLocation({
                        address: data?.display_name,
                        location: data?.address?.suburb
                    });
                },
                (error) => {
                    console.error("Error getting location:", error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser");
        }
    };

    // const getAddressFromCoordinates = (latitude, longitude) => {
    //   const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

    //     fetch(apiUrl)
    //         .then((response) => response.json())
    //         .then((data) => {
    //             if (data.display_name) {
    //                 const address = data.display_name;
    //                 setLocation({
    //                     address: address,
    //                     location: data.address?.suburb
    //                 });

    //             } else {
    //                 console.error("No address found for the given coordinates");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching address:", error);
    //         });
    // };

    const getDeviceType = () => {
        const isMobile = window.innerWidth <= 768;
        setDeviceType(isMobile ? "Mobile" : "PC");
    };

    useEffect(() => {
        getDeviceType();
        getUserLocation();
    }, [])

    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [loading, setLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    const userDetails = getLoggedinUser()?.user;
    // const [userDetails, setUserDetails] = useState([]); 
    const [userList, setUserList] = useState([]);
    const [help, setHelp] = useState(false);
    const [show, setShow] = useState(false);
    var moment = require('moment-timezone');
    const [holidayList, setHolidayList] = useState([]);
    const [workingList, setWorkingList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [attendanceView, setAttendanceView] = useState([]);
    const [hoursList, setHoursList] = useState([]);
    const [leaveView, setLeaveView] = useState([]);
    const [holidayView, setHolidayView] = useState([]);

    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
    ]

    const startYear = 2024;
    const endYear = new Date().getFullYear();
    const yearList = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
        history('/attendance');
    };

    const [leaveOpen, setLeaveOpen] = useState(false);
    const toggleLeaveOpen = () => {
        setLeaveOpen(!leaveOpen);
        history('/attendance');
    };

    const [holidayOpen, setHolidayOpen] = useState(false);
    const toggleHolidayOpen = () => {
        setHolidayOpen(!holidayOpen);
    };

    const today = new Date();
    const selectStyles = {
        container: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
            "&:before": {
                backgroundColor: "white"
            }
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            margin: "0px 0px 5px 0px",
            minHeight: "30px",
        }),
        dropdownButton: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
            height: "30px",
            padding: "5px 8px",
            boxWidth: "none",
            boxShadow: "none",
            color: "rgb(118, 118, 118)",
            border: "1px solid #ced4da",
            "& svg": {
                color: "hsl(0, 0%, 80%)",
                height: "35",
                width: "35"
            }
        }),

        groupHeading: (provided) => ({
            fontWeight: 'bold',
            // color: '#019aff',
            padding: "5px 12px",
            paddingLeft: '6px',
            cursor: "pointer",
            alignItems: 'center',
            display: 'flex',
        }),

        option: (baseStyles, state) => ({
            ...baseStyles,
            cursor: "pointer",
            paddingLeft: state.data.label === 'Select All' ? '6px' : '16px',
            fontWeight: state.data.label === 'Select All' && 'bold',
            // color: state.data.label === 'Select All' && '#019aff',
            // padding:"5px 12px",
            height: "30px",
            boxShadow: "none",
            display: 'flex',
            alignItems: 'center',
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: "transparent",
            },
            "&:active": {
                backgroundColor: "unset",
            },
        }),
    };

    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : '#ced4da'
            }),
            menu: base => ({ ...base, zIndex: 2 }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                color: '#019aff',
                paddingLeft: '10px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px', // Change padding on focus
            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        }
    }


    const getAllWorkingHour = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`attendance`, {
                params: {
                    user_id: user_idParam,
                    year: today.getFullYear(),
                    month: today.getMonth() + 1,
                }
            });

            if (response.data.status === true) {
                setWorkingList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setWorkingList([]);
                setSearchList([]);
            }

            setLoading(false);

        } catch (err) {
            console.error(err.message);
        }

    };

    const getSettings = async () => {
        setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    };

    // useEffect(() => {
    //     const viewUser = async () => {
    //         api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    //         api.defaults.headers.common['Content-Type'] = 'application/json'
    //         try {
    //             const response = await api.get('userDetails', {});
    //             if (response.data.status === true) {
    //                 setUserDetails(response.data.data);
    //             }
    //         } catch (err) {
    //             console.error(err.message);
    //         }
    //     };
    //     viewUser();
    // }, [])

    useEffect(() => {
        getHoliday();
        getSettings();
    }, []);

    const viewAttendance = async (id, date) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('attendance/' + date + '/' + id, {});
            if (response.data.status === true) {
                setAttendanceView(response.data?.data);
                setShift(response.data?.shift);
                setHoursList(response.data?.hoursList);
                setOpen(true);
            } else {
                ToastMessage("error", response.data?.message);

                setAttendanceView([]);
                setHoursList([]);
                setShift([]);
            }

        } catch (err) {
            console.error(err.message);
        }
    }

    const viewLeave = async (id, date) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('leave/' + date + '/' + id, {});
            if (response.data.status === true) {
                setLeaveView(response.data?.data);
            } else {
                setLeaveView([]);
            }

            toggleLeaveOpen();
        } catch (err) {
            console.error(err.message);
        }
    }

    const viewHoliday = async (date) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('holiday/' + date + '/' + 1, {});
            if (response.data.status === true) {
                setHolidayView(response.data?.data);
            } else {
                setHolidayView([]);
            }

            toggleHolidayOpen();

        } catch (err) {
            console.error(err.message);
        }
    }

    const editWorking = async (id, user_id, date) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.put('workingHours/' + id, {
                end_date: moment(new Date()).tz(moment.tz.guess()).format('YYYY-MM-DD'),
                end_time: moment(new Date()).tz(moment.tz.guess()).format('HH:mm:ss'),
                end_time_latitude: latitude,
                end_time_longitude: longitude,
                end_time_device_type: deviceType,
                location: location?.location,
                address: location?.address,
                is_admin: 1,
            });

            if (response.data.status === true) {
                viewAttendance(user_id, date);
                window.dispatchEvent(new Event("workingHourChange"));
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getAllUser = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('getAllUser', {});
            if (response.data.status === true) {
                setUserList(response.data?.data);
            } else {
                setUserList([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getAllUser();
    }, []);

    const filterValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_id: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
                return (
                    { value: item.id, label: item.name, }
                );
            }),
            year: today.getFullYear(),
            month: today.getMonth() + 1,
        },
        validationSchema: Yup.object({
            user_id: Yup.array(),
            year: Yup.string().notRequired(),
            month: Yup.string().notRequired(),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
            api.defaults.headers.common['Content-Type'] = 'application/json';
            try {
                const response = await api.get(`attendance`, {
                    params: {
                        user_id: user_idParam,
                        year: values.year,
                        month: values.month,
                    }
                });

                if (response.data.status === true) {

                    setWorkingList(response.data?.data);
                    setSearchList(response.data?.data);

                } else {
                    setWorkingList([]);
                    setSearchList([]);
                }

                setLoading(false);

            } catch (err) {
                console.error(err.message);
            }
        },
    });


    useEffect(() => {
        getAllWorkingHour();
    }, []);

    function getDaysInMonth() {
        const lastDay = new Date(filterValidation.values.year, filterValidation.values.month, 0).getDate();
        return Array.from({ length: lastDay }, (_, index) => index + 1);
    }

    const days = getDaysInMonth();

    const user_idParam = userDetails?.roles?.[0]?.name === "Admin" ?
        filterValidation?.values?.user_id ? filterValidation.values.user_id?.map((value) => (value.value)).filter(value => value !== "") : ''
        : '';

    const handleUserSelectionChange = (selected) => {
        filterValidation.setValues({
            ...filterValidation.values,
            user_id: selected.length > 0 ? selected : [{
                name: "Select...",
                value: 0
            }]
        });

        filterValidation.handleSubmit();
    };


    const getHoliday = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`holiday?year=${filterValidation.values.year}`, {});
            if (response.data.status === true) {
                setHolidayList(response.data?.data);
            } else {
                setHolidayList([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };


    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.filter(item =>
                item.user?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.user?.employee_number_id?.toLowerCase().includes(inputVal.toLowerCase()) ||
                (item.user?.name + " (" + item.user?.employee_number_id + ")")?.toLowerCase().includes(inputVal.toLowerCase())
            );

            setWorkingList(filteredResults);

        } else {
            setWorkingList(searchList);
        }
    };

    // const calculateOverTime = (totalWorkHours, regularWorkHours) => {  
    //     const total = parseFloat(totalWorkHours);
    //     const regular = parseFloat(regularWorkHours);

    //     if (!isNaN(total) && !isNaN(regular) && total >= regular) {
    //         const overtime = total - regular;
    //         return overtime.toFixed(2);
    //     } else {
    //         return '0';
    //     }
    // };


    function getDay(date) {
        const specificDate = new Date(date);
        const dayString = specificDate.toLocaleDateString('en-US', { weekday: 'short' });
        return dayString;
    }

    function convertToSeconds(timeString) {
        const [hours, minutes] = timeString.split(':');
        return parseInt(hours) * 3600 + parseInt(minutes) * 60;
    }

    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let intervalId;

        setTimer(attendanceView?.reduce((accumulator, work) => {
            return accumulator + convertToSeconds(TimeDifference(work));
        }, 0));

        if (open) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [open]);

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploadedFile(file);
        }
    };

    const uploadFile = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    
        if (!uploadedFile) {
            setMessage("Please select a file to upload.");
            return;
        }
    
        const formData = new FormData();
        formData.append("file", uploadedFile);
    
        setIsLoading(true);
        setMessage("");
    
        try {
            const response = await api.post("working-hours/import", formData, {});
    
            if (response.data.status === true) {
                setMessage("File imported successfully!");
            } else {
                setMessage("Failed to import file. Please try again.");
            }
        } catch (error) {
            console.error("Error importing file:", error);
            setMessage("Failed to import file. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };
    


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12} className='mb-1 mb-md-0 '>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Attendance</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6}   >
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                filterValidation.handleSubmit();
                                return false;
                            }}>
                                <Row>
                                    {userDetails?.roles?.[0]?.name === "Admin" &&
                                        <Col xs={12} md={4} className='py-1 py-md-0' >
                                            <ReactMultiSelectCheckboxes
                                                value={filterValidation.values.user_id?.filter(item => (item?.value !== 0))?.map((item, index) => {
                                                    return ({ value: item.value, label: item.label });
                                                })}

                                                options={[{
                                                    label: "Select All",
                                                    options: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
                                                        return ({
                                                            value: item.id, label: item.name,
                                                        });
                                                    }),
                                                }]}

                                                onChange={handleUserSelectionChange}
                                                styles={selectStyles}
                                            />
                                        </Col>}

                                    <Col xs={6} md={3} className={`${userDetails?.roles?.[0]?.name === "Admin" ? "py-1 pt-0 py-md-0 px-md-0 pe-0" : "py-1 py-md-1 pt-0 pe-0 pe-md-2"}`} >

                                        <Select defaultValue={filterValidation.values.year ? yearList?.filter(
                                            item => item === filterValidation.values.year)?.map((item, index) => {
                                                return (
                                                    { value: item, label: item }
                                                );
                                            }) : ""}

                                            onChange={(e) => {
                                                filterValidation.setValues({
                                                    ...filterValidation.values,
                                                    year: e?.value,
                                                    month: 1
                                                });
                                                filterValidation.handleSubmit();

                                            }}
                                            options={yearList.map((year) => ({ value: year, label: year }))}
                                            styles={selectStyle('year')}
                                        />
                                    </Col>

                                    <Col xs={6} md={3} className={`${userDetails?.roles?.[0]?.name !== "Admin" ? "py-1 pt-0 py-md-1 px-md-1" : "py-1 pt-0 py-md-0"}`} >

                                        <Select
                                            value={filterValidation.values.month ? months?.filter(
                                                item => item?.value === filterValidation.values.month)?.map((item, index) => {
                                                    return ({ value: item.value, label: item.label });
                                                }) : ""}

                                            onChange={(e) => {
                                                filterValidation.setValues({
                                                    ...filterValidation.values,
                                                    month: e?.value
                                                });

                                                filterValidation.handleSubmit();
                                            }}
                                            options={

                                                filterValidation.values.year == endYear ? months.filter(month => {
                                                    return month.value <= new Date().getMonth() + 1;
                                                })
                                                    : months
                                            }

                                            styles={selectStyle('month')}
                                        />

                                    </Col>
                                </Row>
                            </Form>
                        </Col>


                        <Col xs={12} md={6} className='d-block d-md-flex justify-content-end'>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <Input 
                                    type="text" 
                                    className="form-control px-2 py-1" 
                                    id="name" 
                                    placeholder='Search...' 
                                    name='name'
                                    onChange={(e) => { keywordSearch(e.target.value) }}
                                />
                            </Col>
                            <Col xs={6}>
                                <div className="d-flex gap-2 align-items-center">
                                    <div className="custom-file-upload">
                                        <Label 
                                            htmlFor="file-upload" 
                                            className="btn btn-outline-primary btn-sm"
                                            style={{ 
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <FeatherIcon icon="upload" size={16} />
                                            {isLoading ? "Importing..." : "Import"}
                                        </Label>
                                        <Input
                                            type="file"
                                            id="file-upload"
                                            accept=".xlsx, .xls"
                                            onChange={handleFileUpload}
                                            disabled={isLoading}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        onClick={uploadFile}
                                        disabled={isLoading || !uploadedFile}
                                        style={{ 
                                            padding: '6px 12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px'
                                        }}
                                    >
                                        {isLoading ? (
                                            <Spinner size="sm" className="me-1" />
                                        ) : (
                                            <FeatherIcon icon="check" size={16} />
                                        )}
                                        Upload
                                    </Button>
                                </div>
                                {message && (
                                    <div className="mt-1">
                                        <small 
                                            style={{ 
                                                color: message.includes("Failed") ? "#dc3545" : "#28a745",
                                                fontSize: '0.85rem'
                                            }}
                                        >
                                            {message}
                                        </small>
                                    </div>
                                )}
                                {uploadedFile && !isLoading && (
                                    <div className="mt-1">
                                        <small className="text-muted">
                                            Selected: {uploadedFile.name}
                                        </small>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    </Row>

                    <Row className='mt-1 mb-3' >
                        <Col>
                            {loading ?
                                <div className='text-center'>

                                    <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                        Loading...
                                    </Spinner>
                                </div> :
                                <div style={{ height: "70vh", overflowX: "auto" }}>
                                    <Table className='mb-0 table-hover table-striped table-bordered fixed-header'>
                                        <thead className='table-info text-dark'  >
                                            <tr>
                                                <th className={`sticky-head`} >
                                                    {userDetails?.roles?.[0]?.name === "Admin" && "Employees"}
                                                    <div className='d-flex justify-content-end'>
                                                        {show ?
                                                            <FcClearFilters className="cursor fs-3" onClick={() => { setShow(!show) }} />
                                                            :
                                                            <FcFilledFilter className="cursor fs-3" onClick={() => { setShow(!show) }} />
                                                        }
                                                    </div>
                                                </th>

                                                {show &&
                                                    <>
                                                        <th className='text-center bg-success text-dark' >Paid Days     </th>
                                                        <th className='text-center bg-info text-dark' >P      </th>
                                                        <th className='text-center bg-info text-dark' >WFH </th>
                                                        <th className='text-center bg-info text-dark' >H </th>
                                                        <th className='text-center bg-info text-dark' >CL </th>
                                                        <th className='text-center bg-info text-dark' >EL </th>
                                                        <th className='text-center bg-danger text-white' >A  </th>
                                                        <th className='text-center  bg-danger text-white' >LOP   </th>
                                                    </>
                                                }

                                                {days.map((day, index) => (
                                                    <th className='text-center' key={index} > {day}  <br />
                                                        <span className='fs-12 fw-normal' > {getDay(filterValidation.values.year + "-" + filterValidation.values.month + "-" + day)}</span>
                                                    </th>
                                                )
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {workingList?.map((item, index) => {
                                                return (
                                                    <tr key={index} className='bg-white'>
                                                        <td className='sticky-column' >
                                                            {userDetails?.roles?.[0]?.name === "Admin" &&
                                                                <a href="#!" onClick={() => {
                                                                    history('/profile?user_id=' + item.user?.id);
                                                                }}>
                                                                    {item.user?.name} {item.user?.employee_number_id ? " (" + item.user?.employee_number_id + ")" : ""}
                                                                </a>
                                                            }
                                                        </td>
                                                        {show &&
                                                            <>
                                                                <td className='text-center bg-success bg-opacity-25'>
                                                                    {
                                                                        Object.keys(item?.working_hours).reduce((sum, key) => {
                                                                            const row = item?.working_hours[key];
                                                                            const date = row.date;

                                                                            if (!new Set().has(date)) {
                                                                                const isSunday = new Date(date).getDay() === 0;
                                                                                const holiday = holidayList.some(dateObj => dateObj?.date === date);
                                                                                const work_hour = row.work_days?.length === 0;
                                                                                if (isSunday || holiday || !work_hour) {
                                                                                    new Set().add(date);
                                                                                    return sum + 1;
                                                                                }
                                                                            }

                                                                            return sum;
                                                                        }, 0)
                                                                        +
                                                                        Number(item.PaidLeave)
                                                                    }
                                                                </td>

                                                                <td className='text-center bg-info-subtle  '>
                                                                    {Object.keys(item?.working_hours).filter((key) => {
                                                                        const row = item?.working_hours[key];
                                                                        return row.work_days?.length > 0;
                                                                    }).length - Number(item.WFH)}
                                                                </td>
                                                                <td className='text-center bg-info-subtle ' >{item.WFH} </td>
                                                                <td className='text-center bg-info-subtle  ' >
                                                                    {
                                                                        Object.keys(item?.working_hours).reduce((sum, key) => {
                                                                            const row = item?.working_hours[key];
                                                                            const date = row.date;
                                                                            const uniqueDatesSet = new Set();

                                                                            if (!uniqueDatesSet.has(date)) {
                                                                                const isSunday = new Date(date).getDay() === 0;
                                                                                const holiday = holidayList.some(dateObj => dateObj?.date === date);
                                                                                const work_hour = row.work_days?.length > 0;

                                                                                if ((isSunday || holiday) && !work_hour) {
                                                                                    uniqueDatesSet.add(date);
                                                                                    return sum + 1;
                                                                                }
                                                                            }

                                                                            return sum;
                                                                        }, 0)

                                                                    } </td>


                                                                <td className='text-center bg-info-subtle  ' >{item.CL} </td>
                                                                <td className='text-center bg-info-subtle ' >{item.EL} </td>

                                                                <td className='text-center bg-danger-subtle  '>
                                                                    {Object.keys(item?.working_hours).filter((key) => {
                                                                        const row = item?.working_hours[key];
                                                                        const isSunday = new Date(row.date).getDay() === 0;
                                                                        const date = row.date <= moment().tz(moment.tz.guess()).format('YYYY-MM-DD');
                                                                        const holiday = holidayList.filter(date => date?.date === row.date)?.length > 0;
                                                                        const work_hour = row.work_days?.length == 0
                                                                        return date && !isSunday && !holiday && work_hour || row.leave == true;
                                                                    }).length}

                                                                </td>
                                                                <td className='text-center bg-danger-subtle ' >{item.LOP} </td>
                                                            </>
                                                        }

                                                        {Object.keys(item?.working_hours).map((index) => {
                                                            const row = item?.working_hours[index];
                                                            const user_id = item.user?.id;
                                                            const dayOfWeek = new Date(row.date);
                                                            const sundayDate = dayOfWeek.getDay();
                                                            return (

                                                                <td key={index} className='text-center'>
                                                                    <div className='d-flex align-items-center justify-content-center gap-2'>

                                                                        {
                                                                            row.work_days && row.work_days?.length > 0 ? (
                                                                                <div >
                                                                                    <FaCheck className='cursor text-success' onClick={() => viewAttendance(user_id, row.date)} />
                                                                                </div>

                                                                            ) : (

                                                                                sundayDate === 0 ?
                                                                                    <div className="circle-letter cursor">
                                                                                        <div className="circle bg-danger">
                                                                                            <p className='mb-0'>H</p>
                                                                                        </div>
                                                                                    </div>

                                                                                    : holidayList.filter(date => date?.date === row.date)?.length > 0 ?
                                                                                        <div className="circle-letter cursor" onClick={() => viewHoliday(row.date)}>
                                                                                            <div className="circle bg-danger">
                                                                                                <p className='mb-0'>H</p>
                                                                                            </div>
                                                                                        </div>

                                                                                        : row.date <= moment().tz(moment.tz.guess()).format('YYYY-MM-DD') ?
                                                                                            row.leave !== null ?
                                                                                                <div className="circle-letter cursor" onClick={() => viewLeave(user_id, row.date)}>
                                                                                                    <div className={`circle ${(row.leave?.leavetype?.code === "PR"
                                                                                                        || row.leave?.leavetype?.code === "LOP") ? "bg-danger" : "bg-success"}`}>
                                                                                                        <p className='mb-0'> {row.leave?.leavetype?.code}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div  >
                                                                                                    <FaTimes className='text-danger' />
                                                                                                </div>
                                                                                            : "-"
                                                                            )}

                                                                        {row.work_days && row.work_days?.length > 0 && row.date <= moment().tz(moment.tz.guess()).format('YYYY-MM-DD') ?
                                                                            row.leave !== null ?
                                                                                <>
                                                                                    {row.loss_of_pay > 0 ?
                                                                                        <div className="circle-letter cursor" onClick={() => viewLeave(user_id, row.date)}>
                                                                                            <div className="circle bg-danger">
                                                                                                <p className='mb-0'> LOP</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="circle-letter cursor" onClick={() => viewLeave(user_id, row.date)}>
                                                                                            <div className={`circle ${row.leave?.leavetype?.code === "PR" ? "bg-danger" : "bg-success"}`}>
                                                                                                <p className='mb-0'> {row.leave?.leavetype?.code}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </> : ""
                                                                            : ""}
                                                                    </div>
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>

                                    </Table>

                                    {(workingList)?.length === 0 &&
                                        <div className="noresult" >
                                            <div className="text-center">
                                                <h6 className="mt-2">Sorry! No Result Found</h6>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col className='d-flex justify-content-end '>
                            <FeatherIcon icon="help-circle" className="icon-dual primary cursor" onClick={() => { setHelp(!help); }} />
                        </Col>
                    </Row>

                    <Row>
                        {help ? <>
                            <Col xs={12} md={6} className='border-end'>
                                <Row>
                                    <Col xs={12} md={6} className='border-end'>
                                        <p className="mb-2">  <FaCheck className='cursor text-success' />  - Present </p>
                                        <p className="mb-2">  <FaTimes className='text-danger' /> - Absent </p>
                                        <div className="d-flex mb-2 gap-2">  <div className="circle bg-danger">
                                            <p className='mb-0'>H</p>  </div> - Holiday or Sunday </div>
                                        <div className="d-flex mb-2 gap-2">  <div className="circle bg-success">
                                            <p className='mb-0'>WFH</p>  </div> - Work From Home </div>
                                        {/* <p> <span className='rounded-circle cursor bg-danger text-white mb-0' style={{ fontSize: "12px", padding: "3px 3px 3px 6px", }}   >  H </span>   </p> */}
                                        {/* <p> <span className='rounded-circle cursor bg-danger  text-white  mb-0' style={{ fontSize: "10px", padding: "8px 2px ", }}   > LOP </span>  - Loss of Pay </p> */}
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <div className="d-flex mb-2 gap-2">  <div className="circle bg-success">
                                            <p className='mb-0'>CL</p>  </div> - Casual Leave </div>
                                        <div className="d-flex mb-2 gap-2">  <div className="circle bg-success">
                                            <p className='mb-0'>EL</p>  </div> - Earned Leave </div>
                                        <div className="d-flex mb-2 gap-2">  <div className="circle bg-danger">
                                            <p className='mb-0'>LOP</p>  </div> - Loss of Pay</div>
                                        <div className="d-flex mb-2 gap-2">  <div className="circle bg-danger">
                                            <p className='mb-0'>PR</p>  </div> - Permission</div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} md={6} className='pt-2 pt-md-0' >
                                <Row>
                                    <Col xs={12} md={6} className='border-end'>
                                        <p>  P	 - Present </p>
                                        <p>  A	 - Absent </p>
                                        <p>  CL	 - Casual Leave </p>
                                    </Col>
                                    <Col xs={12} md={6} >
                                        <p>  EL - Earned Leave </p>
                                        <p> LOP - Loss of Pay </p>
                                        <p> H - Holiday or Sunday </p>
                                    </Col>
                                </Row>
                            </Col>

                        </> : ""}

                    </Row>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <h5 className="m-0 me-2 text-white">  {attendanceView?.[0]?.user?.name} Attendance Info </h5>
                                <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer(); clearInterval(timer); }}>   <FaTimes style={{ color: "white" }} /> </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">
                                    <AttendanceData attendanceView={attendanceView} editWorking={editWorking} hoursList={hoursList} shift={shift} selectedCountry={selectedCountry} />
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                    <Offcanvas isOpen={leaveOpen} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}    >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={toggleLeaveOpen}>
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white"> Leave Info </span>
                                <span className="fs-18  text-color-white cursor" onClick={() => { toggleLeaveOpen() }}>   <FaTimes style={{ color: "white" }} /> </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Employee Name</Label> <br />
                                                {leaveView?.user?.name}
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Leave Type </Label> <br />
                                                {leaveView?.leave_type?.name}
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Date </Label> <br />
                                                {leaveView?.leave_duration === "Single" ?
                                                    DefaultDate(leaveView?.start_date, selectedCountry)
                                                    : leaveView?.leave_duration === "Multi" ?
                                                        DefaultDate(leaveView?.start_date, selectedCountry) + " - " + DefaultDate(leaveView.end_date, selectedCountry)
                                                        : leaveView?.leave_duration === "Half" ?
                                                            DefaultDate(leaveView?.start_date, selectedCountry) + " (" + leaveView?.half_day_period + " half)"
                                                            :
                                                            DefaultDate(leaveView?.start_date, selectedCountry) + " (" +
                                                            leaveView?.start_time + " - " + leaveView?.end_time + ")"
                                                }
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Reason </Label> <br />
                                                {leaveView?.reason}
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Status </Label>  <br />
                                                <Badge color={`${leaveView?.status === "Approved" ? "success-subtle text-success" :
                                                    "danger-subtle text-danger"}`} className="ms-auto">
                                                    {leaveView?.status}</Badge>
                                            </div>

                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Status Reason </Label>  <br />
                                                {leaveView?.admin_reason}
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold"> Applied On </Label>   <br />
                                                {DefaultDate(leaveView?.created_at, selectedCountry)}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>

                    <Offcanvas isOpen={holidayOpen} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}    >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={toggleLeaveOpen}>
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white"> Holiday Info </span>
                                <span className="fs-18  text-color-white cursor" onClick={() => { toggleHolidayOpen() }}>   <FaTimes style={{ color: "white" }} /> </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold">Occasion  </Label> <br />
                                                {holidayView?.title}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-bold">Date  </Label> <br />
                                                {DefaultDate(holidayView?.date, selectedCountry)}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Attendance;