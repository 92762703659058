import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card, CardBody, Container, Offcanvas, Table, OffcanvasHeader, OffcanvasBody, Badge,
  Row, Spinner, Label, Col
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import listPlugin from '@fullcalendar/list';
import { FaTimes } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Components/Common/DeleteModal";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import SimpleBar from "simplebar-react";
import AttendanceData from "../../Components/Common/AttendanceData";
import { getLoggedinUser, getSettingsDetails, getAddressFromCoordinates } from "../../helpers/api_helper";
import { ToastMessage, DefaultDate } from "../../Components/Hooks/Function";

const Calender = (props) => {
  var moment = require('moment-timezone');
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [loading, setLoading] = useState(false);
  const [attendanceView, setAttendanceView] = useState([]);
  const [shift, setShift] = useState([]);
  const [hoursList, setHoursList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [view, setView] = useState("");
  const [open, setOpen] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [location, setLocation] = useState({});

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          var data = getAddressFromCoordinates(latitude, longitude);
          setLocation({
            address: data?.display_name,
            location: data?.address?.suburb
          });
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
    }
  };

  const isMobile = window.innerWidth <= 768;
  const getDeviceType = () => {
    setDeviceType(isMobile ? "Mobile" : "PC");
  };

  useEffect(() => {
    getDeviceType();
    getUserLocation();
  }, [])

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userList, setUserList] = useState([]);

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
    } else {
      setModal(true);
    }
  };

  const [leaveView, setLeaveView] = useState([]);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event.id));
    setDeleteModal(false);
    toggle();
  };

  const handleEventClick = (arg) => {
    setView("");
    setLoading(false);
    viewUserDetails(arg.event?._def?.extendedProps?.today_date);
    setOpen(true);
  };

  const handleDateClick = (arg) => {
    const eventsOnDate = getEventsOnDate(arg?.dateStr);
    if (eventsOnDate) {
      setView("");
      setLoading(false);
      viewUserDetails(arg?.dateStr);
      setOpen(true);
    }
  };

  const getEventsOnDate = (date) => {
    var moment = require('moment-timezone');
    const today = new Date();
    const eventDate = moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD');
    const todayDate = moment(today).tz(moment.tz.guess()).format('YYYY-MM-DD');
    return eventDate <= todayDate;
  };

  const viewUserDetails = async (date) => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('userViewWorkStatus/' + date, {});
      if (response.data.status === true) {
        setUserList(response.data?.data);
      } else {
        setUserList([]);
      }
      setLoading(false);


    } catch (err) {
      console.error(err.message);
    }
  }

  const editWorking = async (id, user_id, date) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.put('workingHours/' + id, {
        end_date: moment(new Date()).tz(moment.tz.guess()).format('YYYY-MM-DD'),
        end_time: moment(new Date()).tz(moment.tz.guess()).format('HH:mm:ss'),
        end_time_latitude: latitude,
        end_time_longitude: longitude,
        end_time_device_type: deviceType,
        location: location?.location,
        address: location?.address,
        is_admin: 1,
      });

      if (response.data.status === true) {
        viewAttendance(user_id, date);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const viewAttendance = async (id, date) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('attendance/' + date + '/' + id, {});
      if (response.data.status === true) {
        setAttendanceView(response.data?.data);
        setShift(response.data?.shift);
        setHoursList(response.data?.hoursList);
        setView("Attendance");
      } else {

        ToastMessage("error", response.data?.message);

        setAttendanceView([]);
        setHoursList([]);
        setShift([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  }

  const viewLeave = async (id, date) => {
    setView("");
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('leave/' + date + '/' + id, {});
      if (response.data.status === true) {
        setLeaveView(response.data?.data);
        setView("Leave");
      } else {
        setLeaveView([]);
      }
    } catch (err) {
      console.error(err.message);
    }

  }

  const calendarRef = React.createRef();

  const handlePrevButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
      const currentDate = calendarRef.current.getApi().getDate();
      const prevYear = currentDate.getFullYear().toString();
      const prevMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      props.getUserStatusCount(prevYear, prevMonth);
    }
  };

  const handleNextButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
      const currentDate = calendarRef.current.getApi().getDate();
      const prevYear = currentDate.getFullYear().toString();
      const prevMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      props.getUserStatusCount(prevYear, prevMonth);
    }
  };

  const handleTodayButtonClick = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().today();
      const currentDate = calendarRef.current.getApi().getDate();
      const prevYear = currentDate.getFullYear().toString();
      const prevMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      getWorkingHours(prevYear, prevMonth);
    }
  };

  function renderEventContent(eventInfo) {

    const dayOfWeek = new Date(eventInfo?.event?._def?.extendedProps?.today_date);
    const sundayDate = dayOfWeek.getDay();
    return (
      <div className="fc-event-main-frame " onClick={(e) => {
        handleEventClick(eventInfo);
      }}>

        {/* {loading ?
          <div className='text-center'>
            <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
              Loading...
            </Spinner>
          </div> : */}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky d-flex gap-2 justify-content-center">
            {
              (sundayDate === 0 || eventInfo?.event?._def?.extendedProps?.holiday_count > 0) &&
              <div className="circle-letter cursor mt-1"    >
                <div className="circle bg-danger">
                  <p className='mb-0 text-white'>  H</p>
                </div>
              </div>
            }

            {eventInfo?.event?._def?.extendedProps?.present_count > 0 &&
              <div className="circle-letter cursor mt-1"   >
                <div className="circle bg-success">
                  <p className='mb-0 text-white'> {eventInfo?.event?._def?.extendedProps?.present_count}  P</p>
                </div>
              </div>}

            {sundayDate !== 0 && eventInfo?.event?._def?.extendedProps?.holiday_count === 0
              && eventInfo?.event?._def?.extendedProps?.leave_count > 0
              && eventInfo?.event?._def?.extendedProps?.today_date <= moment().tz(moment.tz.guess()).format('YYYY-MM-DD')
              && <div className="circle-letter cursor mt-1"  >
                <div className="circle bg-danger">
                  <p className='mb-0 text-white'> {
                    eventInfo?.event?._def?.extendedProps?.leave_count
                  }  A</p>
                </div>
              </div>}

          </div></div>

        {/* } */}
      </div>

    )
  }


  useEffect(() => {
    getSettings()
  }, []);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Container fluid className="px-0">
        <Card className="card-h-100">
          <CardBody className="calender ">
            <FullCalendar

              initialView={isMobile ? "listWeek" : "dayGridMonth"}
              ref={calendarRef}
              plugins={[
                BootstrapTheme,
                dayGridPlugin,
                interactionPlugin,
                listPlugin
              ]}
              // headerToolbar={{
              //   start: '', // remove the left part of the header
              //   center: 'title',
              //   end: '', // remove the right part of the header
              // }}
              // initialView="dayGridMonth"
              // slotDuration={"00:15:00"}
              // handleWindowResize={true}
              themeSystem="bootstrap"
              eventContent={renderEventContent}

              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "prev,next today",
              }}

              customButtons={{
                prev: {
                  text: 'prev',
                  click: handlePrevButtonClick,
                },
                next: {
                  text: 'next',
                  click: handleNextButtonClick,
                },
                today: {
                  text: 'today',
                  click: handleTodayButtonClick,
                },
              }}
              // events={[
              //   ...(Array.isArray(holidayList) ? holidayList : []),
              //   ...(Array.isArray(leaveList?.data)
              //     ? (leaveList.data).filter(type => type?.status === "Approved").map((item, index) => ({
              //         title: item.user?.name,
              //         start: item.start_date,
              //         end: item.end_date,
              //         date: item.start_date,
              //         leave_type: item.leave_type?.name
              //       }))
              //     : [])
              // ]}

              events={props.userStatusList?.map((item, index) => {
                return ({
                  "allDay": true,
                  "start": item.date,
                  "end": item.date,
                  "className": isMobile ? "bg-primary-subtle" : "bg-transparent",
                  "today_date": item.date,
                  "holiday_count": item.holiday_count,
                  "leave_count": item.leave_count,
                  "present_count": item.present_count,
                  "wfh_count": item.wfh_count,
                }
                );
              })

              }
              editable={false}
              droppable={false}
              selectable={false}
              dateClick={handleDateClick}
            // eventClick={handleEventClick}
            // drop={onDrop}
            />
          </CardBody>
        </Card>

        <div style={{ clear: "both" }}></div>
      </Container>

      <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
          <span className="d-flex justify-content-between" >
            <span className="m-0 me-2 text-white">{`Attendance Details on ${(DefaultDate(userList?.date, selectedCountry))} `}</span>
            <span>
              <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
            </span>
          </span>
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <SimpleBar className="h-100">

            {loading ?
              <div className='text-center'>
                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                  Loading...
                </Spinner>
              </div> :
              <div className="px-4 py-3">

                <div className="d-flex justify-content-end">
                  {view &&
                    <button onClick={(e) => { setView(""); }} className="btn btn-primary text-white w-bold px-1 py-1 my-1" >  Back </button>
                  }
                </div>
                {view === "Attendance" ?
                  <AttendanceData editWorking={editWorking} attendanceView={attendanceView} hoursList={hoursList} shift={shift} selectedCountry={selectedCountry} />
                  : view === "Leave" ?
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Employee Name</Label> <br />
                          {leaveView?.user?.name}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Leave Type </Label> <br />
                          {leaveView?.leave_type?.name}

                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Date </Label> <br />
                          {leaveView?.leave_duration === "Single" ?
                            DefaultDate(leaveView?.start_date, selectedCountry)
                            : leaveView?.leave_duration === "Multi" ?
                              DefaultDate(leaveView?.start_date, selectedCountry) + " - " + DefaultDate(leaveView.end_date, selectedCountry)
                              : leaveView?.leave_duration === "Half" ?
                                DefaultDate(leaveView?.start_date, selectedCountry) + " (" + leaveView?.half_day_period + " half)"
                                :
                                DefaultDate(leaveView?.start_date, selectedCountry) + " (" +
                                leaveView?.start_time + " - " + leaveView?.end_time + ")"
                          }
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Reason </Label>
                          <br />
                          {leaveView?.reason}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Status </Label>
                          <br />
                          <Badge color={`${leaveView?.status === "Approved" ? "success-subtle text-success" :
                            "danger-subtle text-danger"}`} className="ms-auto">
                            {leaveView?.status}</Badge>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Status Reason </Label>
                          <br />
                          {leaveView?.admin_reason}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Applied On </Label>
                          <br />
                          {DefaultDate(leaveView?.created_at, selectedCountry)}
                        </div>

                      </Col>

                    </Row>

                    :
                    <>
                      <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                          <Card className="mb-0  d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                            <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                              <div className="row d-lg-flex align-items-center   px-0 py-2">
                                <div className="col-8  px-1">
                                  <h5 className="fs-15 mb-0"> Name </h5>  </div>
                                <div className="d-flex col-2  align-items-center justify-content-end  px-md-1">
                                  <h5 className="fs-15 mb-0">Status</h5>
                                </div>
                                <div className="d-flex col-2 align-items-center justify-content-end   px-md-1">
                                  <h5 className="fs-15 mb-0">Action</h5>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                      <Row className="row gy-1 mb-1" id="employee-list">

                        {(userList?.holidays)?.map((item, index) => {
                          return (

                            <Col lg={12} key={index} className='mt-0' >

                              <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                <CardBody className="px-1 py-0">

                                  <div className="d-lg-flex align-items-center row">

                                    <div className="col-12 col-md-8 "> {item.title}   </div>

                                    <div className=" col-6 col-md-2 d-flex align-items-center justify-content-start justify-content-md-end"> {"Holiday"} </div>

                                    <div className="col-6 col-md-2 d-flex  align-items-center justify-content-end ">

                                    </div>

                                  </div>

                                </CardBody>
                              </Card>
                            </Col>

                          );
                        })}


                        {new Date(userList?.date).getDay() === 0 &&

                          <Col lg={12} className='mt-0' >

                            <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                              <CardBody className="px-1 py-0">

                                <div className="d-lg-flex align-items-center row">

                                  <div className="col-12 col-md-8"> {"Sunday"}   </div>

                                  <div className="col-6 col-md-2 d-flex align-items-center justify-content-start justify-content-md-end ">
                                    {"Holiday"}

                                  </div>
                                </div>


                              </CardBody>
                            </Card>
                          </Col>}

                        {(userList?.workingHours)?.map((item, index) => {
                          return (
                            <Col lg={12} key={index} className='mt-0' >

                              <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                <CardBody className="px-1 py-0">

                                  <div className="d-lg-flex align-items-center row">

                                    <div className="col-12 col-md-8">


                                      {item.user?.name}  ({item.user?.employee?.employee_number_id})


                                    </div>

                                    <div className="col-6 col-md-2 d-flex align-items-center justify-content-start justify-content-md-end">

                                      {(userList?.leave)?.filter(row => (
                                        (row?.user?.id === item?.users?.id) &&
                                        (row?.leave_type?.code === "WFH")))?.[0]
                                        ?
                                        "Present" + " (" + (userList?.leave)?.filter(row => (
                                          (row?.user?.id === item.users?.id) &&
                                          (row?.leave_type?.code === "WFH")))?.[0]?.leave_type?.code + ")"
                                        :
                                        "Present"}

                                    </div>

                                    <div className="col-6 col-md-2  d-flex align-items-center justify-content-end ">
                                      <a href="#!" onClick={() => {
                                        setView("");
                                        viewAttendance(item.user?.id, item?.start_date)
                                      }}>
                                        <i className="ri-eye-fill fs-18 primary"  ></i>
                                      </a>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>);
                        })}

                        {new Date(userList?.date).getDay() !== 0 &&
                          (userList?.holidays)?.length === 0 && (userList?.leave)?.map((item, index) => (
                            <Col lg={12} key={index} className='mt-0'>
                              <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                <CardBody className="px-1 py-0">
                                  <div className="d-lg-flex align-items-center row">
                                    <div className="col-12 col-md-8">
                                      {
                                        (userList?.leaveList)
                                          ?.filter(row => (
                                            (row?.user_id === item?.users?.id) &&
                                            (row?.leave_type?.code !== "PR") &&
                                            (row?.leave_type?.code !== "WFH")
                                          ))?.length > 0 ? (
                                          (userList?.leaveList)
                                            ?.filter(row => (
                                              (row?.user_id === item?.users?.id) &&
                                              (row?.leave_type?.code !== "PR") &&
                                              (row?.leave_type?.code !== "WFH")
                                            ))
                                            ?.map((leaveItem, leaveIndex) => {
                                              return (

                                                < >
                                                  {leaveItem.user?.name} ({leaveItem?.user?.employee?.employee_number_id})
                                                </>
                                              )
                                            }
                                            )

                                        ) : (
                                          `${item?.first_name} ${item?.last_name || ""} (${item?.employee_number_id || ""})`
                                        )
                                      }
                                    </div>
                                    <div className="col-6 col-md-2 d-flex align-items-center justify-content-start justify-content-md-end">


                                      {(userList?.leaveList)
                                        ?.filter(row => (
                                          (row?.user_id === item?.users?.id) &&
                                          (row?.leave_type?.code !== "PR") &&
                                          (row?.leave_type?.code !== "WFH")
                                        ))?.length > 0 ? (
                                        (userList?.leaveList)
                                          ?.filter(row => (
                                            (row?.user_id === item?.users?.id) &&
                                            (row?.leave_type?.code !== "PR") &&
                                            (row?.leave_type?.code !== "WFH")
                                          ))
                                          ?.map((leaveItem, leaveIndex) => {
                                            return (

                                              "Leave"
                                            )
                                          })

                                      ) : (
                                        "Absent"
                                      )}

                                    </div>

                                    <div className="col-6 col-md-2 d-flex align-items-center justify-content-end">
                                      {
                                        (userList?.leaveList)
                                          ?.filter(row => (
                                            (row?.user_id === item?.users?.id) &&
                                            (row?.leave_type?.code !== "PR") &&
                                            (row?.leave_type?.code !== "WFH")
                                          ))?.length > 0 ? (
                                          (userList?.leaveList)
                                            ?.filter(row => (
                                              (row?.user_id === item?.users?.id) &&
                                              (row?.leave_type?.code !== "PR") &&
                                              (row?.leave_type?.code !== "WFH")
                                            ))
                                            ?.map((leaveItem, leaveIndex) => {
                                              return (

                                                <a href="#!" onClick={() => {
                                                  viewLeave(leaveItem.user?.id, leaveItem?.start_date)
                                                }}>
                                                  <i className="ri-eye-fill fs-18 primary"  ></i>
                                                </a>

                                              )
                                            })

                                        ) : (
                                          <></>
                                        )
                                      }
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}



                      </Row>

                    </>
                }

              </div>
            }

          </SimpleBar>

        </OffcanvasBody>

      </Offcanvas>

    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Calender;
