import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Card, CardBody, Col, Container, ModalHeader, Offcanvas, OffcanvasHeader, Spinner, OffcanvasBody,
  Collapse, Form, Input, Label, Modal, ModalBody, ModalFooter, FormGroup, Row, Badge,
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';
import Flatpickr from "react-flatpickr";
import Select, { components } from 'react-select';
import SimpleBar from "simplebar-react";
// import { DatePicker } from 'reactstrap-date-picker';
import { useNavigate } from "react-router-dom";
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimesCircle, FaTimes, FaPlus, FaFilePdf, FaFileAlt, FaFileExcel } from "react-icons/fa";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import Pagination from "react-js-pagination";
import { DefaultDate, FormatDate, FormatNumber, SmallDateFormat, ToastMessage } from "../../Components/Hooks/Function";

const Asset = () => {
  document.title = "Assets";
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const history = useNavigate();
  const [submit, setSubmit] = useState(false);
  var moment = require('moment-timezone');
  const [view, setView] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [modalFile, setModalFile] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assetsList, setAssetsList] = useState([]);
  // const [allAssetsList, setAllAssetsList] = useState([]);
  const [downloadFormat, setDownloadFormat] = useState({
    value: "", label: "Select Report "
  });
  const [categoryList, setCategoryList] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [assetsTotal, setAssetsTotal] = useState(0);
  const [assetsCount, setAssetsCount] = useState(0);
  const [assetsView, setAssetsView] = useState(null)
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  // const [assignOpen, setAssignOpen] = useState(false);
  // const toggleAssignOpen = () => {
  //   setAssignOpen(!assignOpen);
  // };
  const [isEdit, setIsEdit] = useState(false);
  const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
  const pdfExtensions = new Set(["pdf"]);
  const excelExtensions = new Set(["xls", "xlsx"]);
  // const [modalView, setModalView] = useState(false);
  const [hide, setHide] = useState(false);
  const [modalAttachment, setModalAttachment] = useState({
    show: false,
    file: [],
  });
  const options = [
    { value: '', label: 'Select' },
    { value: 'Working', label: 'Working' },
    { value: 'Repair/Service', label: 'Repair/Service' },
    { value: 'Dead', label: 'Dead' }
  ]
  const userDetails = getLoggedinUser()?.user;
  // const [userDetails, setUserDetails] = useState([]);
  // const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState("50");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fromValue, setFromValue] = useState(moment().clone().tz(moment.tz.guess()).startOf('month'));
  const [toValue, setToValue] = useState(moment().clone().tz(moment.tz.guess()).endOf('month'));

  const selectStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? '#ced4da' : ' ',
        color: state.isFocused ? '#000' : "",
        backgroundColor: "transparent",
        "&:hover": {
          color: "#000",
          backgroundColor: "#ced4da",
        },
        "&:active": {
          color: "#000",
          // backgroundColor: "#019aff", 
        },
      }),
      groupHeading: (provided) => ({
        fontWeight: 'bold',
        // color: '#019aff',
        padding: '0 6px',
        paddingLeft: '10px',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px', // Change padding on focus

      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    }
  }


  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setFromValue(selectedDates[0]);
      setToValue(selectedDates[1]);
      filterValidation.handleSubmit();
    }
  };

  const [file, setFile] = useState([]);
  const [files, setFiles] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);

  const handleFileChange = (e) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    const files = Array.from(e.target.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize <= maxSizeInBytes) {
      const allowedFiles = files.filter((file) =>
        file.type === 'application/pdf' || file.type.startsWith('image/')
      );

      setFile(allowedFiles);
      setFiles(
        allowedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    } else {
      setFile([]);
      setFiles([]);
      ToastMessage("error", "Uplaoad file is greater than 10 MB");
    }
  }

  const getReport = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`assetReport`, {
        params: {
          startDate: startDateParam,
          endDate: endDateParam,
          condition: conditionParam,
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
          selectedCountry: selectedCountry,
        }, responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'One-Time Asset Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (err) {
      console.error(err.message);
    }
  };

  const getReportSum = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`assetReportSum`, {
        params: {
          startDate: startDateParam,
          endDate: endDateParam,
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          totalamount: assetsTotal,
          selectedCountry: selectedCountry,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
          totalcount: assetsCount,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Recurring Asset Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleDownload = async (value) => {
    value === "Recurring" ? getReportSum() : getReport()
    setDownloadFormat({ value: "", label: "Select Report   " });
    setModalConfirm(false);
  };

  const handleAddClick = (arg) => {
    setView(false);
    setSubmit(false);
    setAttachmentList([]);
    setFile([]);
    setFiles([]);
    setHide(false);
    validation.resetForm();
    validation.setValues({
      purchase_date: new Date().toISOString(),
      status: true
    });
    setIsEdit(false);
    toggleDrawer();
  };

  const handleEditClick = useCallback((arg) => {
    setView(false);
    setHide(false);
    setFile([]);
    setFiles([]);
    setAttachmentList([]);
    validation.resetForm();
    setSubmit(false);
    viewAssets(arg?.id);
    setIsEdit(true);
  }, []);

  const handleViewClick = (arg) => {
    setView(true);
    validation.resetForm();
    viewAssets(arg?.id);
    setIsEdit(false);
  };

  // delete
  const onClickData = () => {
    setDeleteModal(true);
    // viewAssets(arg?.id);
  };

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    setSettingsList(getSettingsDetails("settings"));
  };


  const keywordSearch = async (e) => {
    let inputVal = e;
    if (inputVal !== "") {
      const filteredResults = searchList?.data?.data?.filter(item =>
        item.user?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.asset_name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.asset_category?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        FormatNumber(item?.value, selectedCountry)?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.asset_id?.toLowerCase()?.includes(inputVal.toLowerCase()) ||
        DefaultDate(item.purchase_date, selectedCountry)?.toLowerCase().includes(inputVal.toLowerCase()) ||
        item.warranty?.toLowerCase().includes(inputVal.toLowerCase()) ||
        (item.status === 1 ? "Active" : "Inactive").toLowerCase().includes(inputVal.toLowerCase())
      );

      setAssetsList({
        ...assetsList,
        data: filteredResults
      });

      setAssetsTotal(filteredResults?.filter((item) => item.status === 1)?.reduce((row, { value }) => row + Number(value), 0));
    } else {
      setAssetsList({
        ...assetsList,
        data: searchList?.data?.data
      });

      setAssetsTotal(searchList?.total);
    }
  };


  const viewAttachmet = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('assetAttachment/' + id, {});

      if (response.data.status === true) {
        setModalAttachment({ ...modalAttachment, show: true, file: response.data.data });
      } else {
        setModalAttachment({ ...modalAttachment, show: true, file: [], });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  const handleDelete = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('assetAttachment/' + id, {});

      if (response.data.status === true) {
        setAttachmentList(attachmentList?.filter(item => item.id !== id));
        setFile(file?.filter(item => item.id !== id));
        setFiles(files?.filter(item => item.id !== id));
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAssetType = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('assetCategoryList', {});

      if (response.data.status === true) {
        const formattedOptions = formatOptions(response.data?.data);
        setCategoryList(formattedOptions);
      } else {
        setCategoryList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const formatOptions = (apiData) => {
    const groupedOptions = {};
    apiData.forEach(option => {
      const group = option.parent?.name;
      const value = option.id;
      const label = option.name;
      if (!groupedOptions[group]) {
        groupedOptions[group] = [];
      }

      groupedOptions[group].push({ value, label });
    });

    const formattedOptions = Object.keys(groupedOptions).map(group => ({
      label: group,
      options: groupedOptions[group],
    }));
    return formattedOptions;
  };

  const [loading, setLoading] = useState(false);
  const getAssets = async () => {

    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`asset`, {
        params: {
          condition: conditionParam,
          startDate: startDateParam,
          endDate: endDateParam,
          page: currentPage,
          rowPerPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setSearchList(response.data);
        setAssetsList(response.data?.data);
        setAssetsTotal(response.data?.total);
        setAssetsCount(response.data?.count);

      } else {
        setSearchList([]);
        setAssetsList([]);
        setAssetsTotal(0);
        setAssetsCount(0);

        ToastMessage("error", response.data?.message);
      }
      setCurrentPage(1);
      setLoading(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  const getAssetsPage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`asset`, {
        params: {
          condition: conditionParam,
          startDate: startDateParam,
          endDate: endDateParam,
          page: currentPage,
          rowPerPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setSearchList(response.data);
        setAssetsTotal(response.data?.total);
        setAssetsList(response.data?.data);
        setAssetsCount(response.data?.count);

      } else {
        setAssetsList([]);
        setSearchList([]);
        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }
  };


  const viewAssets = async (id) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('asset/' + id, {});

      if (response.data.status === true) {
        // validation.setValues(response.data?.data);
        validation.setValues({
          ...response.data?.data,
          purchase_date: DefaultDate(response.data?.data?.purchase_date, selectedCountry),
          status: response.data?.data?.status === 1 ? true : false
        });


        setAssetsView(response.data?.data);
        setAttachmentList(response.data?.attachment);
      } else {
        setAssetsView([]);
        setAttachmentList([]);
      }

      setOpen(true);

    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getSettings();
    getAssetType();
  }, []);


  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % modalAttachment?.file?.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? modalAttachment?.file?.length - 1 : prevSlide - 1
    );
  };


  const addAssets = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      for (const key in value) {
        if (value.hasOwnProperty(key) && key !== 'asset_category') {
          if (key === "purchase_date") {
            formData.append('purchase_date', value.purchase_date ? FormatDate(value.purchase_date, selectedCountry) : "");
          } else if (key === "status") {
            formData.append('status', value?.status ? 1 : 0 || 0);
          }
          else {
            formData.append(key, value[key] || '');
          }
        }
      }

      Object.keys(file).forEach(key => {
        const files = file[key];
        if (files) {
          formData.append('attachment[]', files);
        }
      });

      const response = await api.post('asset', formData);

      if (response.data.status === true) {
        setIsEdit(false);
        setOpen(false);
        validation.resetForm();
        getAssets();
        ToastMessage("success", response.data?.message);

      } else {
        ToastMessage("error", response.data?.message);
      }

      validation.setSubmitting(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const editAssets = async (value, id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      formData.append('_method', "PUT");
      for (const key in value) {
        if (value.hasOwnProperty(key) && key !== 'asset_category') {
          if (key === "purchase_date") {
            formData.append('purchase_date', value.purchase_date ? FormatDate(value.purchase_date, selectedCountry) : "");
          } else if (key === "status") {
            formData.append('status', value?.status ? 1 : 0 || 0);
          } else {
            formData.append(key, value[key] || '');
          }
        }
      }

      Object.keys(file).forEach(key => {
        const files = file[key];
        if (files) {
          formData.append('attachment[]', files);
        }
      });
      const response = await api.post('asset/' + id, formData);
      if (response.data.status === true) {
        getAssets();
        setIsEdit(false);
        validation.resetForm();
        setOpen(false);

        ToastMessage("success", response.data?.message);

      } else {
        ToastMessage("error", response.data?.message);
      }
    } catch (err) {
      console.error(err.message);
    }

  };

  const deleteAssets = async (value) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('asset/' + value?.id, {});

      if (response.data.status === true) {
        getAssets();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)

    } catch (err) {
      console.error(err.message);
    }
  };

  function getExtension(filename) {
    return filename?.split('.').pop()
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      asset_name: '',
      asset_category_id: '',
      asset_id: '',
      purchase_date: "",
      purchase_from: '',
      manufacturer: '',
      model: '',
      serial_number: '',
      supplier: '',
      condition: '',
      warranty: '',
      value: '',
      status: true,
      description: '',

    },
    validationSchema: Yup.object({
      asset_name: Yup.string().required("Please enter"),
      asset_category_id: Yup.string().required("Please enter"),
      asset_id: Yup.string().notRequired(),
      purchase_date: Yup.string().notRequired(),
      purchase_from: Yup.string().notRequired(),
      manufacturer: Yup.string().notRequired(),
      model: Yup.string().notRequired(),
      serial_number: Yup.string().notRequired(),
      supplier: Yup.string().notRequired(),
      condition: Yup.string().notRequired(),
      warranty: Yup.string().notRequired(),
      value: Yup.string().notRequired(),
      status: Yup.string().notRequired(),
      description: Yup.string().notRequired(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isEdit) {
          await editAssets(values, validation.values?.id);
        } else {
          await addAssets(values);
        }
        setSubmitting(false);
      } catch (error) {
        console.error("An error occurred:", error);
        setSubmitting(false);
      }
    },
  });




  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      status: '',
      startDate: '',
      endDate: '',
    },

    validationSchema: Yup.object({
      status: Yup.string().notRequired(),
      startDate: Yup.date().notRequired(),
      endDate: Yup.date().notRequired(),
    }),
    onSubmit: async (values) => {

      setLoading(true);

      api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
      api.defaults.headers.common['Content-Type'] = 'application/json';

      try {
        const response = await api.get(`asset`,
          {
            params: {
              condition: conditionParam,
              startDate: startDateParam,
              endDate: endDateParam,
              page: currentPage,
              rowPerPage: rowPerPage
            }
          });

        if (response.data.status === true) {
          setSearchList(response.data);
          setAssetsList(response.data?.data);
          setAssetsTotal(response.data?.total);
          setAssetsCount(response.data?.count);
        } else {
          setSearchList([]);
          setAssetsTotal(0);
          setAssetsList([])
        }

        setLoading(false);

      } catch (err) {
        console.error(err.message);
      }
    },
  });


  useEffect(() => {
    getAssets();
  }, []);


  const getDefaultDate = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return [firstDayOfMonth, lastDayOfMonth];
  };

  const conditionParam = filterValidation.values.condition ? `${filterValidation.values.condition}` : '';
  const startDateParam = fromValue ? `${moment(fromValue).format('YYYY-MM-DD')}` : '';
  const endDateParam = toValue ? `${moment(toValue).format('YYYY-MM-DD')}` : '';

  const handleClick = (e) => {
    setCurrentPage(e);
    getAssetsPage(e, rowPerPage);
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        name={assetsView?.asset_id}
        show={deleteModal}
        onDeleteClick={() => deleteAssets(assetsView)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Assets</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} >
              <Form onSubmit={(e) => {
                e.preventDefault();
                filterValidation.handleSubmit();
                return false;
              }}>
                <Row>
                  <Col xs={6} className='py-1 py-md-0'>
                    <Flatpickr
                      className="form-control py-1 px-2"
                      placeholder="Select Date Range"
                      options={{
                        allowInput: true,
                        mode: 'range',
                        dateFormat: SmallDateFormat(selectedCountry),
                        defaultDate: getDefaultDate(),
                      }}

                      onChange={handleDateChange}
                    />
                  </Col>

                  <Col xs={6} md={4} className='py-1 py-md-0  px-md-0 ps-1' >
                    <Select
                      defaultValue={filterValidation.values.condition ? options?.filter(
                        item => item?.value === filterValidation.values.condition)?.map((item, index) => {
                          return ({ value: item.value, label: item.label });
                        }) : ""}


                      onChange={(e) => {
                        filterValidation.setValues({
                          ...filterValidation.values,
                          condition: e?.value
                        });

                        filterValidation.handleSubmit()
                      }}
                      options={options}
                      styles={selectStyle('condition')}
                    />

                  </Col>
                </Row>
              </Form>
            </Col>


            <Col xs={12} md={8}>
              <Row >
                <Col className="d-flex align-items-center gap-2 justify-content-end">
                  <div className="search-box">
                    <Input type="text" className="form-control" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                    <i className="ri-search-line search-icon"></i>
                  </div>

                  <div className="list-grid-nav hstack gap-1">
                    <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => { handleAddClick(); }} >
                      <i className="ri-add-line search-icon"></i> <span className='d-none d-md-block'> Assets</span></button>
                    <button color="primary" className="btn btn-primary shadow-lg d-none d-md-flex gap-2 px-3 py-1" onClick={() => { history('/asset_user'); }} >
                      <span className='d-none d-md-block'> Assign Assets</span></button>
                    {/* <FaPlus className="text-primary d-flex d-md-none " size={18}    onClick={() => {handleAddClick();     }}/> */}
                  </div>

                  <div className="d-flex list-grid-nav hstack gap-1">
                    <Select value={{ "value": downloadFormat.value, "label": downloadFormat.label }}
                      name='download'
                      onChange={(e) => {
                        if (e?.value !== "") {
                          setDownloadFormat({
                            value: e?.value, label: e?.label
                          });
                          setModalConfirm(true);
                        }
                      }}

                      options={[
                        { value: "", label: "Select Report     " },
                        { value: "One-Time", label: "One-Time Asset Report" },
                        { value: "Recurring", label: "Recurring Asset Report" },
                        // { value: "Annual", label: "Annual Asset Report" },   
                      ]}

                      styles={selectStyle("download")}

                    />

                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1  mt-1 d-flex d-md-none" >
            <Col lg={12} className='pt-1' >
              <Row className='align-items-center'>
                <Col xs={6} sm={6} md={6} className='px-3' >

                  {assetsList?.data?.length > 0 && <p className={`mb-0 fw-bold`}><span> Total: </span> <span> {FormatNumber(assetsTotal, selectedCountry)}  </span></p>}

                </Col>
                <Col xs={6} sm={6} md={6} className='px-3 d-flex justify-content-end gap-2 align-items-center ' >
                  <button color="primary" className="btn btn-primary shadow-lg d-flex   d-md-none gap-2 px-3 py-1" onClick={() => {
                    history('/asset_user');
                  }}>
                    <span className=''> Assign</span></button>
                  <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => handleAddClick()} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1 mt-1 px-2" >
            <Col lg={12} className='table px-1 mb-0' >
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0 rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center px-0 py-2 d-none d-md-none d-lg-block">
                    <div className="col-7 col-md-2 px-2">
                      <h5 className="fs-15 mb-0">Name</h5>
                    </div>

                    <div className="  col-7 col-md-2 px-1 align-items-center">
                      <h5 className="fs-15 mb-0">Type</h5>
                    </div>

                    <div className=" col-12 col-md-2 align-items-center d-none d-md-flex   justify-content-end  ">
                      <h5 className="fs-15 mb-0">Purchase Value</h5>
                    </div>

                    <div className="  col-12 col-md-1 align-items-center">
                      <h5 className="fs-15 mb-0">Asset ID</h5>
                    </div>

                    <div className=" col-6 col-md-2 align-items-center d-flex justify-content-end justify-content-md-start px-0">
                      <h5 className="fs-15 mb-0">Date</h5>
                    </div>

                    <div className="  col-12 col-md-1 px-1 align-items-center">

                      <h5 className="fs-15 mb-0">Warranty</h5>

                    </div>

                    <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end justify-content-md-start px-0">
                      <h5 className="fs-15 mb-0">Status </h5>
                    </div>

                    <div className=" col-12 col-md-1 align-items-center d-none d-md-flex justify-content-end px-3">
                      <h5 className="fs-15 mb-0">Actions</h5>

                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="row gy-1 mb-1" id="assets-list">
            {loading ?
              <div className='text-center'>
                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                  Loading...
                </Spinner>
              </div>
              :
              assetsList?.data?.length !== 0 ?

                (assetsList?.data)?.map((item, index) => (

                  <Col lg={12} key={index} className='mt-0 '  >

                    <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                      <CardBody className="px-2 py-0">

                        <div className="d-lg-flex align-items-center row px-2">

                          <div className="col-6 col-md-2 px-1 px-md-2">

                            {/* <p className=" mb-0 ">  {item.user?.name}   </p>

                            </div>


                            <div className="col-6 col-md-2  d-flex justify-content-end justify-content-md-start px-1 px-md-2 "> */}

                            <a href="#!" onClick={() => { handleViewClick(item); }}>  {item.asset_name} </a>

                          </div>

                          <div className="col-6 col-md-2 d-flex align-items-center justify-content-end justify-content-md-start px-1">

                            <p className="mb-0 ">{item.asset_category?.name} </p>

                          </div>

                          <div className="col-6  col-md-2 d-flex align-items-center justify-content-start justify-content-md-end px-1 px-md-2" >

                            <p className={`mb-0 fw-bold`}>{FormatNumber(item?.value, selectedCountry)}</p>

                          </div>

                          <div className="  col-6 col-md-1  align-items-center d-none d-md-flex ">

                            <p className=" mb-0 ">{item.asset_id}</p>

                          </div>

                          <div className=" d-flex  col-6 col-md-2 mt-0 d-flex align-items-center justify-content-end  justify-content-md-start px-1 px-md-0">

                            <p className="mb-0 ">

                              {DefaultDate(item.purchase_date, selectedCountry)}

                            </p>

                          </div>

                          <div className=" col-6 col-md-1 px-1 px-md-2">

                            <p className=" mb-0 ">{item.warranty}</p>

                          </div>

                          <div className=" col-6 col-md-1 d-flex justify-content-end  justify-content-md-start px-1">

                            {item.status === 1 ?

                              <Badge color="success-subtle text-success" >Active</Badge>
                              :
                              <Badge color="danger-subtle text-danger"  > Inactive  </Badge>

                            }
                          </div>

                          <div className="col-12   col-md-1  align-items-center d-flex justify-content-end px-0 px-md-1">
                            {item.asset_attachments?.length > 0 ?
                              <button type="button" className="btn  btn-sm btn-icon btn25"
                                onClick={(e) => { viewAttachmet(item.id) }}>
                                <i className="ri-attachment-2 fs-18 primary"  ></i>
                              </button> : " "}

                            <button type="button" className="btn  btn-sm btn-icon btn25"
                              onClick={(e) => { handleEditClick(item); }}>
                              <i className="ri-pencil-fill fs-18 primary"  ></i>
                            </button>

                            <button type="button" className="btn btn-sm btn-icon btn25"
                              onClick={(e) => { onClickData(item); setAssetsView(item); }}>
                              <i className="ri-delete-bin-fill fs-18 secondary" ></i>
                            </button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
                :
                <div className="noresult" >
                  <div className="text-center">
                    <h6 className="mt-2">Sorry! No Result Found</h6>
                  </div>
                </div>
            }
          </Row>

          {!loading && assetsList?.data?.length > 0 ?
            <Row className="row  gy-1 mb-1 d-none d-md-flex" >
              <Col lg={12} className='mt-0'>
                <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                  <CardBody className="px-1 py-0">

                    <div className="d-none d-md-flex align-items-center row">
                      <div className="col-7 col-md-2">

                        <p className="mb-0"> </p>

                      </div>

                      <div className="col-5 col-md-2 d-flex justify-content-end justify-content-md-start">

                      </div>

                      <div className="col-5  col-md-2 align-items-center d-flex justify-content-end" >

                        <p className={`mb-0 fw-bold`}><span> Total: </span> <span> {FormatNumber(assetsTotal, selectedCountry)}  </span></p>

                      </div>
                      <div className="d-flex col-4 col-md-1 mt-0 align-items-center ">

                      </div>

                      <div className="d-flex col-4 col-md-1 align-items-center justify-content-end  justify-content-md-start">


                      </div>


                      <div className="col-6 col-md-1 d-flex gap-1 align-items-center">
                      </div>

                      <div className="col-6 col-md-1 align-items-center d-flex justify-content-end"  >

                      </div>

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            : ""}


          <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getAssetsPage(1, e.target.value);
                }}
                value={rowPerPage}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={assetsList?.total}>All</option>

              </select>

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={assetsList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />

            </div>
          </div>


          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered"    >
              <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => {
                setModalConfirm(!modalConfirm)
              }} centered>

                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                  setModalConfirm(!modalConfirm)
                }} >
                  {"Confirm"}

                </ModalHeader>

                <ModalBody >
                  <p> Are you sure you want to download {downloadFormat.value === "One-Time" ?
                    'One-Time Asset Report.pdf'
                    : downloadFormat.value === "Recurring" ?
                      'Recurring Asset Report.pdf'
                      : downloadFormat.value === "Annual" ?
                        'Annual Asset Report.pdf' : 'asset.pdf'}  </p>
                </ModalBody >

                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => {
                      setModalConfirm(false);
                      setDownloadFormat({
                        value: "", label: "Select Report   "
                      });
                    }} > No </Button>
                    <Button type="submit" color="success" id="add-btn" onClick={(e) => {
                      handleDownload(downloadFormat?.value);
                      setDownloadFormat({
                        value: "", label: "Select Report   "
                      });
                    }} > Yes  </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>

          <div className="modal fade" id="showAttachment" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="lg" isOpen={modalAttachment.show} toggle={() => {
                setModalAttachment({
                  ...modalAttachment,
                  show: false,
                  file: [],
                });
              }} centered>

                <ModalHeader className='bpBottom pb-2' toggle={() => {
                  setModalAttachment({
                    ...modalAttachment,
                    show: false,
                    file: [],
                  });
                }}  >
                  {"View Attachment"}

                </ModalHeader>

                <ModalBody >

                  {modalAttachment?.show ? <>

                    <div className='text-center pb-2' >

                      {/* <h5 className='text-secondary'>{modalAttachment?.file?.[currentSlide].name}</h5> <br/> */}

                      {modalAttachment?.file?.[0]?.path !== undefined ?

                        getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase() !== ("pdf" || "doc") ?
                          <img src={url + modalAttachment?.file?.[currentSlide]?.path} className='img' />

                          :
                          <iframe src={url + modalAttachment?.file?.[currentSlide]?.path} className='iframe' />


                        : "No Attachment Found"}


                    </div>
                  </>
                    : ""}


                  {modalAttachment?.file?.[0]?.path !== undefined ?

                    <div className='pb-2 text-center' >
                      <button className="btn btn-primary shadow-lg">
                        <a className='text-white' href={url + modalAttachment?.file?.[currentSlide]?.path} target='_blank' download>  Download</a>
                      </button>
                    </div>

                    : ""}


                  {modalAttachment?.file?.length > 1 ?
                    <div className="d-flex justify-content-center">
                      <div className="pagination-wrap hstack gap-2">
                        <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                          <FeatherIcon icon="arrow-left" size="20" />
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" onClick={goToNextSlide}>
                          <FeatherIcon icon="arrow-right" size="20" />
                        </Link>
                      </div>
                    </div>
                    : ""}
                </ModalBody>
              </Modal>
            </div>
          </div>


          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">
                  {!view ? !isEdit ? <>
                    Add Assets - {settingsList.filter(type => type?.name === "asset_id_code")[0]?.value + Number("000") + Number(assetsCount + 1).toString().padStart("00".length, '0')}
                  </> : "Update Asset" : "View Asset"} </span>
                <span>
                  {!view ? <span className="fs-18 px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>
              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">

              <SimpleBar className="h-100">
                <div className="px-4 py-3">
                  {view ? <>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Name </Label> <br />
                          {assetsView?.asset_name}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Type </Label> <br />
                          {assetsView?.asset_category?.name}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Purchase Value </Label> <br />
                          {FormatNumber(assetsView?.value, selectedCountry)}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Asset ID </Label>
                          <br />
                          {assetsView?.asset_id}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Purchase Date  </Label>
                          <br />
                          {DefaultDate(assetsView?.purchase_date, selectedCountry)}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Vendor </Label>
                          <br />
                          {assetsView?.purchase_from}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Manufacturer </Label>
                          <br />
                          {assetsView?.manufacturer}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Model </Label>
                          <br />
                          {assetsView?.model}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Serial Number </Label>
                          <br />
                          {assetsView?.serial_number}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Vendor </Label>
                          <br />
                          {assetsView?.supplier}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Warranty </Label>
                          <br />
                          {assetsView?.warranty}
                        </div>

                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label fw-bold"> Description </Label>
                          <br />
                          {assetsView?.description}
                        </div>
                      </Col>

                    </Row>

                  </>
                    :
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <Row>

                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="asset_name" className="form-label ">Name <span className='secondary'>*</span></Label>
                            <Input type="text" className="form-control px-2 py-1" id="asset_name" placeholder="Enter name"
                              name='asset_name'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.asset_name || ""}
                              invalid={submit && validation.errors.asset_name ? true : false}
                            />
                          </div>
                        </Col>

                        <Col>
                          <div className="mb-2">
                            <Label htmlFor="asset_name" className="form-label">Type <span className='secondary'>*</span> </Label>
                            <Select
                              defaultValue={
                                validation.values.asset_category?.name ? {
                                  "value": validation.values.asset_category_id,
                                  "label": validation.values.asset_category?.name
                                } : ""}

                              options={categoryList}

                              placeholder="Select..."
                              name='asset_category_id'
                              onChange={(e) => {
                                validation.setValues({
                                  ...validation.values,
                                  asset_category_id: e?.value
                                });
                              }}

                              styles={selectStyle('asset_category_id')}
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="value" className="form-label">Purchase Value</Label>
                            <Input type="number" className="form-control px-2 py-1" id="value" placeholder="Enter purchase value"
                              name='value'
                              onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                              onChange={(e) => {
                                let inputValue = e.target.value;
                                if (inputValue.length > 10) {
                                  inputValue = inputValue.slice(0, 10);
                                }

                                validation.handleChange({
                                  target: {
                                    name: 'value',
                                    value: inputValue,
                                  },
                                });
                              }}

                              onBlur={validation.handleBlur}
                              value={validation.values.value || ""}
                              invalid={submit && validation.errors.value ? true : false
                              }
                            />

                          </div>

                        </Col>

                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="roleName" className="form-label"> Purchase Date   </Label>

                            <Flatpickr
                              name='purchase_date'
                              className={`form-control py-1 px-2 ${submit && validation.errors.purchase_date ? 'is-invalid' : ''}`}

                              options={{
                                allowInput: true,
                                dateFormat: SmallDateFormat(selectedCountry),
                              }}
                              onChange={(selectedDates, dateStr, instance) => {
                                validation.setValues({
                                  ...validation.values,
                                  purchase_date: dateStr
                                });
                              }}

                              value={validation.values.purchase_date || ""}
                            //  invalid={submit && validation.errors.purchase_date ? true : false}
                            />

                          </div>
                        </Col>


                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="purchase_from" className="form-label"> Vendor</Label>
                            <Input type="text" className="form-control px-2 py-1" id="purchase_from" placeholder="Enter vendor"
                              name='purchase_from'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.purchase_from || ""}
                              invalid={submit && validation.errors.purchase_from ? true : false
                              }
                            />

                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-2">
                            <Label htmlFor="manufacturer" className="form-label"> Manufacturer</Label>

                            <Input type="text" className="form-control px-2 py-1" id="manufacturer" placeholder="Enter manufacturer"
                              name='manufacturer'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.manufacturer || ""}
                              invalid={submit && validation.errors.manufacturer ? true : false}

                            />
                          </div>
                        </Col>

                        {hide === true ?
                          <>
                            <Col lg={6}>
                              <div className="mb-2">
                                <Label htmlFor="model" className="form-label"> Model</Label>

                                <Input type="text" className="form-control px-2 py-1" id="model" placeholder="Enter model"
                                  name='model'

                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.model || ""}
                                  invalid={submit && validation.errors.model ? true : false
                                  }

                                />

                              </div>
                            </Col>


                            <Col lg={6}>
                              <div className="mb-2">
                                <Label htmlFor="serial_number" className="form-label"> Serial Number</Label>

                                <Input type="text" className="form-control px-2 py-1" id="serial_number" placeholder="Enter serial number"
                                  name='serial_number'

                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.serial_number || ""}
                                  invalid={submit && validation.errors.serial_number ? true : false
                                  }

                                />

                              </div>
                            </Col>


                            <Col lg={6}>
                              <Row>
                                <Col lg={12}>
                                  <div className="mb-2">
                                    <Label htmlFor="supplier" className="form-label"> Vendor</Label>

                                    <Input type="text" className="form-control px-2 py-1" id="supplier" placeholder="Enter vendor"
                                      name='supplier'
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.supplier || ""}
                                      invalid={submit && validation.errors.supplier ? true : false
                                      }

                                    />

                                  </div>
                                </Col>
                                <Col xs={12}>
                                  <div className="mb-2">
                                    <Label htmlFor="warranty" className="form-label"> Warranty</Label>

                                    <Input type="text" className="form-control px-2 py-1" id="warranty" placeholder="Enter warranty in month or year"
                                      name='warranty'
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.warranty || ""}
                                      invalid={submit && validation.errors.warranty ? true : false
                                      }

                                    />

                                  </div>

                                </Col>
                                <Col xs={12}>
                                  <div className="mb-2">
                                    <Label htmlFor="condition" className="form-label"> Condition</Label>

                                    <Select
                                      name='condition'
                                      defaultValue={validation.values.condition ? options?.filter(
                                        item => item?.value === validation.values.condition)?.map((item, index) => {

                                          return (
                                            { value: item.value, label: item.label }
                                          );
                                        }) : ""}

                                      onChange={(e) => {

                                        validation.setValues({
                                          ...validation.values,
                                          condition: e?.value
                                        });
                                      }}


                                      options={options}

                                      styles={selectStyle('condition')}
                                    />

                                  </div>
                                </Col>

                              </Row>
                            </Col>

                            <Col lg={6}>
                              <div className="mb-2">
                                <Label htmlFor="description" className="form-label">Description{validation.errors.description}</Label>
                                <Input type="textarea" className="form-control px-2 py-1" id="description" placeholder="Enter description"
                                  name='description'

                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.description || ""}
                                  invalid={submit && validation.errors.description ? true : false
                                  }
                                  maxLength={250}
                                  style={{ height: userDetails?.roles?.[0]?.name === "Admin" ? '165px' : "100px", minHeight: '50px', maxHeight: userDetails?.roles?.[0]?.name === "Admin" ? '165px' : "100px", }}
                                  multiline="true"

                                />

                              </div>

                            </Col>


                            <Col xs={6}>

                              <div className="mb-2">
                                <Label htmlFor="roleName" className="form-label">Status</Label>

                                <div className="form-check form-switch form-switch-left form-switch-md">

                                  <Input
                                    type="switch"
                                    id="status_id"
                                    name="status"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    defaultValue={validation.values.status}
                                    checked={validation.values.status}
                                  />

                                  {validation.values.status ? "Active" : "Inactive"}

                                </div>

                              </div>

                            </Col>

                            <Col lg={12}>
                              <div className="mb-2">
                                <Label htmlFor="attachment" className="form-label">Upload Attachment</Label>
                                <Input type="file" accept=".pdf, image/*" className="form-control px-2 py-1" id="attachment"
                                  name='attachment'
                                  onChange={handleFileChange}
                                  multiple />

                              </div>

                              <Row className='py-2  '>
                                {(files || [])?.map((item, index) => {

                                  return [

                                    <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >

                                      <FaTimesCircle className="close-button" onClick={(e) => {

                                        setFiles(
                                          files.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                        );
                                        setFile(
                                          file.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                        );
                                      }} />

                                      <div className="selected-image cursor" onClick={(e) => {
                                        setModalFile(true); setFileUrl({ name: item.name, path: item.preview });
                                      }}>
                                        {imageExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                          <img
                                            src={item.preview}
                                            alt={item.name}
                                            width={100}
                                            height={80}
                                          />
                                          : pdfExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                            <FaFilePdf size={30} />
                                            : excelExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                              <FaFileExcel size={30} />
                                              : <FaFileAlt size={30} />

                                        }

                                      </div>
                                    </Col>

                                  ]
                                })}

                                {attachmentList?.map((item, index) => {

                                  return [

                                    <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >

                                      <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                        handleDelete(item.id);
                                      }} />

                                      <div className="selected-image cursor" onClick={(e) => {
                                        setModalFile(true); setFileUrl({ name: item.path, path: url + item.path });
                                      }}>

                                        {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                          <img

                                            src={url + item.path}
                                            alt={item.name}
                                            width={100}
                                            height={80}


                                          />
                                          : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                            <FaFilePdf size={30} />
                                            : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                              <FaFileExcel size={30} />
                                              : <FaFileAlt size={30} />

                                        }

                                      </div>
                                    </Col>
                                  ]
                                })}</Row>
                            </Col>
                          </>

                          : ""}

                        <Link onClick={() => { setHide(!hide); }}> {hide ? "Less..." : "More..."} </Link>
                        <div className="modal fade" tabIndex="-1" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <Modal size="lg" isOpen={modalFile} toggle={() => {
                              setModalFile(false);
                            }} centered>

                              <ModalHeader className='bpBottom pb-2' toggle={() => {
                                setModalFile(false);
                              }}  >
                                {"View Attachment"}

                              </ModalHeader>

                              <ModalBody >

                                {modalFile && <>

                                  <div className='text-center py-4' >

                                    {imageExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                      <img

                                        src={fileUrl.path}
                                        alt={fileUrl.name}
                                        width={isDesktop ? 500 : "100%"}
                                        height={"100%"}

                                      />
                                      : pdfExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                        <iframe src={fileUrl.path} className='iframe' />
                                        : excelExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                          <>  {fileUrl.name}</>
                                          : <>  {fileUrl.name}</>

                                    }

                                  </div>
                                </>
                                }

                                <div className='pt-2 text-center' >
                                  <button className="btn btn-primary shadow-lg">
                                    <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                                  </button>
                                </div>
                              </ModalBody>
                            </Modal>
                          </div>
                        </div>

                        <Col lg={12}>
                          <div className="hstack gap-2 justify-content-end">
                            <button type="submit" className="btn btn-primary shadow-lg"
                              disabled={validation.isSubmitting ? true : false} onClick={() => {
                                setSubmit(true);
                                !isEdit ?
                                  validation.setValues({
                                    ...validation.values,
                                    asset_id: settingsList.filter(type => type?.name === "asset_id_code")[0]?.value + Number("000") + Number(assetsCount + 1).toString().padStart("00".length, '0')
                                  })
                                  : ""
                              }}>
                              {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                              {!view ? !isEdit ? "Add Assets" : "Update Assets" : "View Assets"}</button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  }

                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Asset;