import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  Card, CardBody, Col, Container, Offcanvas, ListGroup, ListGroupItem, Spinner,
  OffcanvasHeader, OffcanvasBody, AccordionBody, AccordionHeader, UncontrolledAccordion,
  AccordionItem, TabContent, TabPane, Input, Modal, ModalBody, Row, Nav, NavItem, NavLink,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import DepartmentFunction from './DepartmentFunction.js';
import DesignationFunction from './DesignationFunction.js';
import EmployeeDesignationFunction from './EmployeeDesignationFunction.js';
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";
import Select from "react-select";

const Department = () => {
  document.title = "Department";
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const placement = isDesktop ? 'end' : 'start';
  const [loading, setLoading] = useState(false);

  const [activeTab, setactiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const selectStyle = (fieldName) => {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        minHeight: '30px',
        padding: '0px',
        borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'

      }),
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? ' ' : ' ',

      }),
      groupHeading: (provided) => ({
        fontWeight: 'bold',
        color: '#019aff',
        paddingLeft: '10px',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? '4px' : '0px', // Change padding on focus

      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: '-4px',
      }),
    }
  }


  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelectChange = (value, level) => {
    setSelectedOptions({ ...selectedOptions, [level]: value });

    employeeDesignationValidation.setValues({
      ...employeeDesignationValidation.values,
      parent_id: value
    });

  };

  const renderOptions = (data, level) => {
    if (!data) return null;

    return (
      Object.keys(data)?.length > 0 && <Col lg={6} >
        <div className='mb-3'>
          <Select
            defaultValue={selectedOptions[level]}

            options={[
              { value: "", label: "Select" },
              ...typeof data === 'object' && Object.keys(data)?.map((key) => {
                return (
                  { value: key, label: data[key].label }
                );
              })]}

            placeholder="Select..."
            name='blog_category_id'
            onChange={(e) => {
              handleSelectChange(e?.value, level)
            }}

            styles={selectStyle('blog_category_id')}
          />

        </div>
      </Col>
    );
  };

  const renderNestedOptions = (data, level) => {
    if (!data) return null;

    return (
      <>
        {renderOptions(data, level)}
        {selectedOptions[level] && data[selectedOptions[level]] && data[selectedOptions[level]].children && (
          renderNestedOptions(data[selectedOptions[level]].children, Object.keys(data[selectedOptions[level]].children)[0])
        )}
      </>
    );
  };

  const [submit, setSubmit] = useState(false);
  // const [team, setTeam] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentAllList, setDepartmentAllList] = useState([]);
  const [main, setMain] = useState(false);
  const [message, setMessage] = useState(false);
  const [departmentView, setDepartmentView] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [view, setView] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleAddClick = (arg) => {
    setView(false);
    setSubmit(false);
    validation.setValues({});
    setSelectedOptions({});
    employeeDesignationValidation.setValues({});
    setIsEdit(false);
    toggleDrawer();
  };

  const handleEditClick = useCallback((arg) => {
    setView(false);
    setSubmit(false);
    viewDepartment(arg?.id);
    setSelectedOptions({})


    validation.resetForm();
    employeeDesignationValidation.resetForm();
    setIsEdit(true);
  }, []);



  const onClickData = () => {
    setDeleteModal(true);
  };



  const transformData = (data) => {
    const result = {};

    data?.forEach(item => {

      result[item.id] = {
        id: item.id,
        label: item.name,
        children: item.descendants ? transformData(item.descendants) : {}
      };
    });

    return result;
  };

  const getDepartment = async () => {
    setLoading(true);

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`department?page=${currentPage}&perPage=${rowPerPage}`, {});

      if (response.data.status === true) {
        setDepartmentList(transformData(response.data?.data?.data));

      } else {
        setDepartmentList([]);
        ToastMessage("error", response.data?.message);
      }

      setLoading(false);

    } catch (err) {
      console.error(err.message);
    }
  };


  const getAllDepartment = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`getAllDepartment`, {});
      if (response.data.status === true) {
        setDepartmentAllList(response.data?.data);

      } else {
        setDepartmentAllList([]);
        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }
  };




  const viewDepartment = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('department/' + id, {});
      if (response.data.status === true) {
        validation.setValues(response.data?.data);
      }

      setOpen(true);

    } catch (err) {
      console.error(err.message);
    }

  };



  useEffect(() => {

    getDepartment();
    getAllDepartment();
  }, []);


  // const addDepartment = async (value) => {
  //   api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  //   api.defaults.headers.common['Content-Type'] = 'application/json'
  //   try {

  //     const response = await api.post('department', value);
  //     if (response.data.status === true) {
  //       getDepartment();
  //   getAllDepartment();
  //       validation.resetForm();
  //       toggleDrawer();
  //       ToastMessage("success", response.data?.message);
  //     } else {
  //       ToastMessage("error", response.data?.message);
  //     }

  //     validation.setSubmitting(false);
  //   } catch (err) {
  //     console.error(err.message);
  //   }

  // };

  const editDepartment = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.put('department/' + value.id, value);

      if (response.data.status === true) {
        getDepartment();

        getAllDepartment();
        validation.resetForm();
        setIsEdit(false);
        toggleDrawer();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }


      validation.setSubmitting(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const deleteDepartment = async (value) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('department/' + value?.id, {});

      if (response.data.status === true) {
        getDepartment();

        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);
      }
      setDeleteModal(false)

    } catch (err) {
      console.error(err.message);
    }
  }; 


  const addEmployeeDesignation = async (value) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post('employeeDesignation', value);
      if (response.data.status === true) {
        getDepartment();
        getAllDepartment();
        toggleDrawer();
        employeeDesignationValidation.resetForm();
        ToastMessage("success", response.data?.message);

      } else {
        ToastMessage("error", response.data?.message);
      }

      employeeDesignationValidation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  // const editEmployeeDesignation = async (value) => {
  //   api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
  //   api.defaults.headers.common['Content-Type'] = 'application/json'
  //   try {
  //     const response = await api.put('employeeDesignation/' + value.id, value);

  //     if (response.data.status === true) {
  //       getDepartment();
  //       getAllDepartment();
  //       employeeDesignationValidation.resetForm();
  //       setIsEdit(false);
  //       toggleDrawer();
  //       ToastMessage("success", response.data?.message);
  //     } else {
  //       ToastMessage("error", response.data?.message);
  //     }

  //     employeeDesignationValidation.setSubmitting(false);

  //   } catch (err) {
  //     console.error(err.message);
  //   }

  // };


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
    }),
    onSubmit: (values) => {
      editDepartment(values, validation.values?.id);
    },
  });




  const employeeDesignationValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      parent_id: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      parent_id: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      addEmployeeDesignation(values);
    },
  });

 

  const renderChildren = (children) => {
    if (!children) return null;

    return (
      <ul className="sub-menu list-unstyled">
        {Object.entries(children).map(([childKey, childValue]) => {
          return (
            Object.entries(childValue.children).length > 0 ?
              <React.Fragment key={childKey}>
                {renderParent([childValue])}
              </React.Fragment>
              : <ul key={childKey} className="list-unstyled   mb-1 ps-3 ">
                <li className='first-list'> <a href="#!" className='d-flex fw-medium text-nowrap'>{childValue?.label}
                  <button type="button" className="btn  btn-sm btn-icon btn25 ps-2"
                    onClick={(e) => { handleEditClick(childValue); setMain(true); toggle("2"); }}>
                    <i className="ri-pencil-fill fs-14 primary"  ></i>
                  </button>

                  <button type="button" className="btn btn-sm btn-icon btn25"
                    onClick={(e) => {
                      setMain(true); setMessage(false); onClickData(); setDepartmentView(childValue);
                    }}>
                    < i className="ri-delete-bin-fill fs-14 secondary"></i>

                  </button>
                </a> </li>  </ul>

          );
        }
        )}
      </ul>
    );
  };


  const renderParent = (parent) => {
    if (!parent) return null;

    return (
      Object.entries(parent).map(([key, value]) => (
        <div className="first-list" key={key}>
          <div className="list-wrap">
            <a to="#" className="d-flex fw-medium text-nowrap"> {value.label}   <button type="button" className="btn  btn-sm btn-icon btn25 ps-2"
              onClick={(e) => { handleEditClick(value); setMain(true); toggle("2"); }}>
              <i className="ri-pencil-fill fs-14 primary"  ></i>
            </button>

              <button type="button" className="btn btn-sm btn-icon btn25"
                onClick={(e) => {
                  setMain(true); setMessage(false); onClickData(); setDepartmentView(value);
                }}>
                < i className="ri-delete-bin-fill fs-14 secondary"></i>

              </button></a>
          </div>
          <ul className="second-list list-unstyled">

            {renderChildren(value.children)}
          </ul>
        </div>

      ))
    );
  };



  const renderSubMenu = (children) => {
    if (!children || Object.keys(children).length === 0) return null;

    return (
      <ul className="second-list list-unstyled">
        {Object.values(children).map(({ id, label, children }) => (
          <li key={id}> {label}   {renderSubMenu(children)}  </li>
        ))}
      </ul>
    );
  };





  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <Modal isOpen={deleteModal} toggle={() => { setDeleteModal(false) }} centered={true}>
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#FFBC0A,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>

            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>

              {main ?
                <p className="text-muted mx-4 mb-0">   Are you sure you want to remove <b>{departmentView?.name}</b> record? </p>
                :
                <p className="text-muted mx-4 mb-0">
                  {message ? (
                    <>To delete <b>{departmentView?.name}</b> Department before removing all designation.</>
                  ) : (
                    <>Are you sure you want to remove the <b>{departmentView?.name}</b> record? </>
                  )}
                </p>}
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={() => setDeleteModal(false)}
            >
              Close
            </button>
            {!message ? <button

              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() =>
                // main ?
                // deleteDesignation(designationView)
                // : deleteDepartment(departmentView)

                deleteDepartment(departmentView)
              }
            >
              Yes, Delete It!
            </button> : ""}
          </div>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>

          <Row>
            <Col xs={12}>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Department Management</h4>

              </div>
            </Col>
          </Row>

          <Row className='d-flex align-items-center'>
            <Col sm={4}> </Col>

            <Col className="col-sm-auto ms-auto d-flex justify-content-end gap-2 py-1 py-md-0 ">

              <div className="list-grid-nav hstack gap-1">
                <button color="primary" className="btn btn-primary shadow-lg d-flex  gap-2 px-3 py-1" onClick={() => {

                  handleAddClick(); setMain(false); toggle("1");

                }} >
                  <i className="ri-add-line search-icon"></i> <span  > Department</span></button>

                {/* <FaPlus className="text-primary d-flex d-md-none " size={18} onClick={() => { handleAddClick(); setMain(false); toggle("1"); }} /> */}

              </div>
            </Col>

          </Row>

          <Row className='py-1'>
            {loading ?
              <div className='text-center'>

                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                  Loading...
                </Spinner>
              </div>
              :
              departmentList?.length !== 0 ?
                <Col lg={12} >
                  <Card className="mb-0 border-0 border-bottom">
                    <CardBody className="px-1 py-0">
                      <SimpleBar className="my-3 px-4">
                        <div className="verti-sitemap">
                          <ul className="list-unstyled mb-0">
                            <li className="p-0 parent-title"><Link to="#" className="fw-bold fs-14">Department</Link></li>
                            {renderParent(departmentList)}
                          </ul>
                        </div>

                      </SimpleBar>
                    </CardBody>
                  </Card>
                </Col>
                :
                <div className="noresult" >
                  <div className="text-center">
                    <h6 className="mt-2">Sorry! No Result Found</h6>
                  </div>
                </div>
            }

          </Row>



          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`} >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">

                  {/* {(!isEdit && !main) ? "Add Department"

                    : (!isEdit && main) ? "Add Designation"

                      : (isEdit && !main) ? "Update Department"

                        : "Update Designation"} */}

                  {!isEdit ? "Add Department" : "Update Department"}
                </span>

                <span>
                  {!view ? <span className="fs-18 px-1 text-color-white cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /> </span> : ""}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>

              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              {
                !isEdit ?
                  <div className="px-4 py-3">
                    <div className="   p-0 d-inline-block position-relative w-100" aria-labelledby="page-header-notifications-dropdown" style={{ zIndex: "1" }}>

                      <EmployeeDesignationFunction validation={employeeDesignationValidation} submit={submit}
                        setSubmit={setSubmit} departmentAllList={departmentAllList} toggleDrawer={toggleDrawer}
                        renderNestedOptions={renderNestedOptions}
                      />
                    </div>

                  </div>

                  :
                  <SimpleBar className="h-100">
                    <div className="px-4 py-3">
                      <DepartmentFunction validation={validation} submit={submit} setSubmit={setSubmit} toggleDrawer={toggleDrawer} />
                    </div>
                  </SimpleBar>
              }
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Department;