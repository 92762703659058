import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Card, CardBody, Spinner, ModalFooter,
  Col, Container, ModalHeader, Form, Input, Label, Modal, ModalBody, Row,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { api, url } from "../../helpers/api";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import { SumTimeDifference, ToastMessage, SmallDateFormat } from "../../Components/Hooks/Function";


const WorkingHours = () => {
  document.title = "Working Hours ";
  var moment = require('moment-timezone');
  const history = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [userList, setUserList] = useState([]);
  const userDetails = getLoggedinUser()?.user;
  const [workingHourList, setWorkingHourList] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [settingsList, setSettingsList] = useState([]);

  const [status, setStatus] = useState({
    user_id: "",
    status: "",
    reason: ""
  });

  const [statusList, setStatusList] = useState([
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Reject", label: "Reject" },
  ]);

  const [employeeTypeList, setEmployeeTypeList] = useState([]);

  const getDefaultDate = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return [firstDayOfMonth, lastDayOfMonth];
  };

  const filterValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: '',
      endDate: '',
      user_id: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
        return ({ value: item.id, label: item.name });
      }),
      employee_type: employeeTypeList?.map((item, index) => {
        return (
          { value: item.id, label: item.name, }
        );
      }),
    },
    validationSchema: Yup.object({
      startDate: Yup.date(),
      endDate: Yup.date(),
      user_id: Yup.array(),
      employee_type: Yup.array(),
    }),
    onSubmit: async (values) => {
      // getWorkingHour();
      setLoading(true);
      api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
      api.defaults.headers.common['Content-Type'] = 'application/json'
      try {
        const response = await api.get(`getAllWorkinghour`,
          {
            params: {
              employee_type: employee_typeParam,
              user_id: user_idParam,
              startDate: startDateParam,
              endDate: endDateParam,
              page: currentPage,
              perPage: rowPerPage,
            }
          }
        );

        if (response.data.status === true) {
          setWorkingHourList(response.data?.data);
        } else {
          setWorkingHourList([]);
        }
        setLoading(false);

      } catch (err) {
        console.error(err.message);
      }
    },
  });

  useEffect(() => {
    getWorkingHour();
  }, []);

  const handleDateChange = (selectedDates) => {
    if (selectedDates?.length === 2) {
      setFromValue(selectedDates[0]);
      setToValue(selectedDates[1]);
      filterValidation.handleSubmit();
    }
  };

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [fromValue, setFromValue] = useState(moment().clone().tz(moment.tz.guess()).startOf('month'));
  const [toValue, setToValue] = useState(moment().clone().tz(moment.tz.guess()).endOf('month'));
  const startDateParam = fromValue ? `${moment(fromValue).format('YYYY-MM-DD')}` : '';
  const endDateParam = toValue ? `${moment(toValue).format('YYYY-MM-DD')}` : '';
  const user_idParam = userDetails?.roles?.[0]?.name === "Admin" ? filterValidation?.values?.user_id ? filterValidation.values.user_id?.map((value) => (value.value)).filter(value => value !== "") : '' : '';
  const employee_typeParam = filterValidation?.values?.employee_type ? filterValidation.values.employee_type?.map((value) => (value.value)).filter(value => value !== "") : '';

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    setSettingsList(getSettingsDetails("settings"));
  };

  useEffect(() => {
    getSettings();
  }, []);

  const getAllUser = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('getAllUser', {});
      if (response.data.status === true) {
        setUserList(response.data?.data);
      } else {
        setUserList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const selectStyles = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      margin: "0px 0px 5px 0px",
      minHeight: "30px",

    }),
    dropdownButton: (baseStyles, state) => ({
      ...baseStyles,
      width: "100%",
      height: "30px",
      padding: "5px 8px",
      boxWidth: "none",
      boxShadow: "none",
      color: "rgb(118, 118, 118)",
      border: "1px solid #ced4da",
      "& svg": {
        color: "hsl(0, 0%, 80%)",
        height: "35",
        width: "35"
      }
    }),

    groupHeading: (provided) => ({
      fontWeight: 'bold',
      // color: '#019aff',
      padding: "5px 12px",
      paddingLeft: '6px',
      cursor: "pointer",
      alignItems: 'center',
      display: 'flex',
    }),

    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: "pointer",
      paddingLeft: state.data.label === 'Select All' ? '6px' : '16px',
      fontWeight: state.data.label === 'Select All' && 'bold',
      // color: state.data.label === 'Select All' && '#019aff',
      // padding:"5px 12px",
      height: "30px",
      boxShadow: "none",
      display: 'flex',
      alignItems: 'center',
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "unset",
      },
    }),
  };

  const selectStyle = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: '20px',
      minHeight: '20px',
      padding: '0px',
      borderColor: state.isFocused ? 'grey' : '#ced4da'
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? ' ' : ' ',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '20px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '20px',
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: state.isFocused ? '4px' : '0px', // Change padding on focus
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginTop: '-4px',
    }),

    placeholder: (provided, state) => ({
      ...provided,
      marginTop: '-1px',
    }),
  }

  const changestatus = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post(`workinghourStatusAllUpdate`, {
        user_id: status.user_id,
        status: status.status,
        startDate: startDateParam,
        endDate: endDateParam,
        reason: status.reason,
      }
      );

      if (response.data.status === true) {
        getWorkingHour();
        ToastMessage("success", response.data?.message);
      } else {
        ToastMessage("error", response.data?.message);

      }
      setModalConfirm(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const [loading, setLoading] = useState(false);
  const getWorkingHour = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`getAllWorkinghour`,
        {
          params: {
            employee_type: employee_typeParam,
            user_id: user_idParam,
            startDate: startDateParam,
            endDate: endDateParam,
            page: currentPage,
            perPage: rowPerPage,
          }
        }
      );

      if (response.data.status === true) {
        setWorkingHourList(response.data?.data);
      } else {
        setWorkingHourList([]);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getWorkingHourPage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`getAllWorkinghour`,
        {
          params: {
            employee_type: employee_typeParam,
            user_id: user_idParam,
            startDate: startDateParam,
            endDate: endDateParam,
            page: currentPage,
            perPage: rowPerPage,
            reason: status.reason,
          }
        }
      );

      if (response.data.status === true) {
        setWorkingHourList(response.data?.data);
      } else {
        setWorkingHourList([]);
      }

    } catch (err) {
      console.error(err.message);
    }

  };


  const getEmployeeTypeList = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('employeeType', {});

      if (response.data.status === true) {
        setEmployeeTypeList(response.data?.data);
      } else {
        setEmployeeTypeList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };


  useEffect(() => {
    // getWorkingHour();
    getAllUser();
    getEmployeeTypeList();
  }, []);

  const getWorkingAllHour = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {

      const response = await api.get(`getAllWorkinghour`,
        {
          params: {
            employee_type: employee_typeParam,
            user_id: user_idParam,
            startDate: startDateParam,
            endDate: endDateParam,
            page: currentPage,
            perPage: workingHourList?.total,
          }
        }
      );

      if (response.data.status === true) {
        getAllReport(response.data?.data?.data)
      } else {
        getAllReport([]);
      }

    } catch (err) {
      console.error(err.message);
    }

  };


  const getAllReport = async (data) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json';
    try {
      const response = await api.get(`workinghourAllReport`, {
        params: {
          employee_type: employee_typeParam,
          logo: settingsList.filter(type => type?.name === "logo-light")[0]?.value,
          user_id: user_idParam,
          startDate: startDateParam,
          endDate: endDateParam,
          companyName: settingsList.filter(type => type?.name === "name")[0]?.value,
          selectedCountry: selectedCountry,
          report: (data)?.map(item => ({
            username: item.user?.name,
            total_hours: SumTimeDifference(item?.total),
            approved_hours: SumTimeDifference(item?.approved),
            pending_hours: SumTimeDifference(item?.pending),
          })),
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Working Hour Report.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err.message);
    }
  };


  const handleClick = (e) => {
    setCurrentPage(e);
    getWorkingHourPage(e, rowPerPage);
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className='mb-1 mb-md-0 '>
              <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 primary fw-bold px-3 px-md-0" >Employee Work Hours Status</h4>
              </div>
            </Col>
          </Row>

          <Row >
            <Col xs={12} md={6} lg={6} xl={6}  >
              <Form onSubmit={(e) => {
                e.preventDefault();
                filterValidation.handleSubmit();
                return false;
              }}>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6}  >
                    <Flatpickr
                      className="form-control py-1 px-2"
                      placeholder="Select Date Range"
                      options={{
                        allowInput: true,
                        mode: 'range',
                        dateFormat: SmallDateFormat(selectedCountry),
                        defaultDate: getDefaultDate(),
                      }}
                      // value={[fromValue, toValue]}  
                      onChange={handleDateChange}
                    />
                  </Col>

                  <Col xs={5} md={3} lg={3} xl={3} className=' px-md-0 py-1 py-md-0  pe-0' >
                    <ReactMultiSelectCheckboxes
                      value={filterValidation.values.user_id?.filter(item => item?.value !== 0)?.map((item, index) => {
                        return (
                          { value: item.value, label: item.label }
                        );
                      })
                      }

                      options={[{
                        label: "Select All",
                        options: userList?.filter(item => item?.id !== 1)?.map((item, index) => {
                          return (
                            { value: item.id, label: item.name, }
                          );
                        }),
                      }]}

                      onChange={(e) => {
                        filterValidation.setValues({
                          ...filterValidation.values,
                          user_id: e.length > 0 ? e : [{
                            name: "Select...",
                            value: 0
                          }]
                        });
                        filterValidation.handleSubmit();
                      }}
                      styles={selectStyles}
                    />

                  </Col>

                  <Col xs={5} md={3} lg={3} xl={3} className='   py-1 py-md-0  pe-0' >
                    <ReactMultiSelectCheckboxes
                      value={filterValidation.values.employee_type.map((item, index) => {
                        return ({ value: item.value, label: item.label });
                      })
                      }

                      options={[{
                        label: "Select All",
                        options: employeeTypeList?.map((item, index) => {
                          return (
                            { value: item.id, label: item.name, }
                          );
                        }),
                      }]}

                      onChange={(e) => {
                        filterValidation.setValues({
                          ...filterValidation.values,
                          employee_type: e
                        });
                        filterValidation.handleSubmit();
                      }}

                      styles={selectStyles}
                    />

                  </Col>

                  <Col xs={2} className='d-flex d-md-none align-items-center' >
                    <FaCloudDownloadAlt className="text-primary" size={25} onClick={() => { setModalDownload(!modalDownload) }} />
                  </Col>
                </Row>
              </Form>


            </Col>

            <Col xs={12} md={6} lg={6} xl={6} className='d-none d-md-flex  justify-content-end'   >
              <button color="primary" className="btn btn-primary shadow-lg d-flex gap-2 px-3 py-1" onClick={() => { setModalDownload(!modalDownload) }} >
                <i className="ri-download-cloud-fill search-icon"></i>  Download Report  </button>
            </Col>
          </Row>

          <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
            <Col lg={12} className='table px-1  mb-0 '  >
              <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                  <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                    <div className="col-2 px-2">
                      <h5 className="fs-15  fw-bold mb-0"> Employee Name</h5>
                    </div>

                    <div className=" col-2 px-2 mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">
                      <h5 className="fs-15 fw-bold mb-0">Total Hours </h5>
                    </div>

                    <div className=" col-2 px-2 mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">
                      <h5 className="fs-15 fw-bold mb-0">Approved Hours </h5>
                    </div>

                    <div className=" col-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">
                      <h5 className="fs-15 fw-bold mb-0">Pending Hours </h5>
                    </div>

                    <div className=" col-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">
                      <h5 className="fs-15 fw-bold mb-0">Status </h5>
                    </div>

                    <div className="col-1    d-flex gap-2 align-items-center justify-content-end ">
                      <h5 className="fs-15 fw-bold mb-0">Action</h5>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row className="row gy-1 mb-1"  >
            {loading ?
              <div className='text-center'>
                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                  Loading...
                </Spinner>
              </div>

              :
              workingHourList?.data?.length !== 0 ?

                (workingHourList?.data)?.map((item, index) => {
                  return (
                    <Col lg={12} key={index} className='mt-0 '  >
                      <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                        <CardBody className="px-2 py-0">
                          <div className="d-lg-flex align-items-center row">
                            <div className="col-6  col-md-2">
                              {item.user?.name}
                            </div>

                            <div className=" col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">
                              <Link to={
                                '/workingListView?user_id=' + item.user?.id + '&username=' + item.user?.name + '&startDate=' + startDateParam + '&endDate=' + endDateParam
                              }>
                                {SumTimeDifference(item?.total)}
                              </Link>

                            </div>

                            <div className=" col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-start">
                              <Link to={
                                '/workingListView?user_id=' + item.user?.id + '&username=' + item.user?.name + '&startDate=' + startDateParam + '&endDate=' + endDateParam
                              }
                              >
                                {SumTimeDifference(item?.approved)}
                              </Link>
                            </div>

                            <div className=" col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start">

                              <Link to={
                                '/workingListView?user_id=' + item.user?.id + '&username=' + item.user?.name + '&startDate=' + startDateParam + '&endDate=' + endDateParam
                              }
                              >
                                {SumTimeDifference(item?.pending)}
                              </Link>

                            </div>

                            <div className=" col-6 col-md-2  mx-auto d-flex gap-2 align-items-center justify-content-start mb-1">

                              <Select
                                onChange={(e) => {
                                  setSubmit(false);
                                  setModalConfirm(true);
                                  setStatus({
                                    user_id: item.user?.id,
                                    status: e?.value
                                  });

                                }}

                                options={statusList?.map((item) => {
                                  return (
                                    { value: item.value, label: item.label }
                                  );
                                })}
                                styles={selectStyle}

                              />
                            </div>

                            <div className="col-6 col-md-1  d-flex gap-0 align-items-center justify-content-end ">
                              <button type="button" className="btn  btn-sm btn-icon btn25"
                                onClick={(e) => {
                                  history('/workingListView?user_id=' + item.user?.id + '&username=' + item.user?.name + '&startDate=' + startDateParam + '&endDate=' + endDateParam);
                                }}>
                                <i className="ri-eye-fill fs-18 primary"  ></i>
                              </button>

                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>


                  );
                })

                :
                <div className="noresult" >
                  <div className="text-center">
                    <h6 className="mt-2">Sorry! No Result Found</h6>
                  </div>
                </div>
            }
          </Row>

          <div className="d-flex justify-content-end  pagination-wrap hstack gap-2">
            <div className="col-sm-6 d-flex gap-2 justify-content-end">
              <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                onChange={(e) => {
                  setRowPerPage(e.target.value);
                  getWorkingHourPage(1, e.target.value);
                }}
                value={rowPerPage}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={workingHourList?.total}>All</option>

              </select>

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={workingHourList?.total || 0}
                pageRangeDisplayed={10}
                onChange={handleClick}
                itemClass="page-item"
                linkClass="page-link"
              />

            </div>
          </div>


          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered"    >
              <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => {
                setModalConfirm(!modalConfirm)
              }} centered>

                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => { setModalConfirm(!modalConfirm) }}>  {"Confirm"}
                </ModalHeader>
                <ModalBody><p> Are you sure you want to change status?</p>

                  {status.status === "Reject" &&
                    <div className="mb-2">
                      <Label htmlFor="reason" className="form-label">Reason <span className='secondary'>*</span></Label>
                      <Input type="text" className="form-control px-2 py-1" id="reason" placeholder="Enter reason"
                        name='reason'
                        onChange={(e) => {
                          setStatus({
                            ...status,
                            reason: e.target.value
                          });
                        }}

                        invalid={submit && !status.reason ? true : false}
                        value={status.reason}
                        required={true}
                      />

                    </div>}


                </ModalBody >

                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => { setModalConfirm(false); }} > No </Button>

                    {status.status === "Reject" && !status.reason ?
                      <Button type="submit" color="success" onClick={(e) => { setSubmit(true); }} > Yes  </Button>
                      :  <Button type="submit" color="success" disabled={status.status !== "Reject" ? false :
                        status.status === "Reject" && status.reason ? false : true}
                        onClick={(e) => { changestatus(); }} > Yes  </Button>}

                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>



          <div className="modal fade" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered"    >
              <Modal size="md" backdrop={"static"} isOpen={modalDownload} toggle={() => {
                setModalDownload(!modalDownload)
              }} centered>

                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                  setModalDownload(!modalDownload)
                }}>  {"Confirm"} </ModalHeader>

                <ModalBody >
                  <p> Are you sure you want to download Employee Work Hours Report  </p>
                </ModalBody >

                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => { setModalDownload(false); }} > No </Button>
                    <Button type="submit" color="success" id="add-btn" onClick={(e) => {
                      getWorkingAllHour();
                      setModalDownload(!modalDownload);
                    }} > Yes  </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WorkingHours;