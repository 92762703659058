import React, { useState, useEffect, useCallback } from 'react';

import { Link, useSearchParams } from 'react-router-dom';
import {
  Card, CardBody, CardHeader, FormGroup, Spinner, Badge, UncontrolledTooltip,
  Offcanvas, OffcanvasHeader, OffcanvasBody,
  Col, Container, Form, Input, Label, Nav, NavItem, NavLink,
  Modal, ModalBody, ModalHeader, Progress, Row, TabContent, Table, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import SwiperCore, { Autoplay } from "swiper";
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FaCheck, FaTimesCircle, FaTimes, FaPlus, FaFileAlt, FaFilePdf, FaFileExcel } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from 'react-toastify';
import FeatherIcon from "feather-icons-react";
import Pagination from "react-js-pagination";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import { ToastMessage, DefaultDate, FormatDate, SmallDateFormat, FormatNumber } from "../../Components/Hooks/Function";

const ProfilePage = (props) => {
  SwiperCore.use([Autoplay]);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  var moment = require('moment-timezone');
  const userDetails = getLoggedinUser()?.user;
  const [fileUrl, setFileUrl] = useState([]);
  const placement = isDesktop ? 'end' : 'start';
  const [activeTab, setActiveTab] = useState('1');
  const [profileDetails, setProfileDetails] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [leaveView, setLeaveView] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
  const [file, setFile] = useState([]);
  const [files, setFiles] = useState([]);
  const [isEdit, setIsEdit] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [assetsView, setAssetsView] = useState(null)
  const [projectView, setProjectView] = useState(null)
  const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
  const pdfExtensions = new Set(["pdf"]);
  const excelExtensions = new Set(["xls", "xlsx"]);
  const [modalFile, setModalFile] = useState(false);
  const [modalProfile, setModalProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [editMoreOpen, setEditMoreOpen] = useState(false);
  const toggleMoreDrawer = () => {
    setEditMoreOpen(!editMoreOpen);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [isAssetsView, setIsAssetsView] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [isProjectView, setIsProjectView] = useState(false);
  const [isLeaveView, setIsLeaveView] = useState(false);
  const [title, setTitle] = useState("");
  const [inputs, setInputs] = useState([{ title: '', file: null, preview: '' }]);
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  const educationValueChange = (name, value, index) => {
    const values = [...education]
    values[index][name] = value
    setEducation(values)
  }

  const experienceValueChange = (name, value, index) => {
    const values = [...experience]
    values[index][name] = value
    setExperience(values)
  }

  const handleTitleChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].title = value;
    setInputs(newInputs);
  };

  const handleProofChange = (index, file) => {
    const newInputs = [...inputs];
    newInputs[index].file = file;
    newInputs[index].preview = URL.createObjectURL(file);
    setInputs(newInputs);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { title: '', file: null, preview: '' }]);
  };

  const handleEducationAdd = () => {
    setEducation([...education, { id: '', institution: '', subject: '', start_date: new Date().toISOString(), end_date: new Date().toISOString(), degree: '', grade: '' }]);
  };

  const handleExperienceAdd = () => {
    setExperience([...experience, { id: '', company_name: '', job_position: '', location: '', start_date: new Date().toISOString(), end_date: new Date().toISOString(), }]);
  };

  const handleRemoveInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleEducationRemove = (index) => {
    const newInputs = [...education];
    newInputs.splice(index, 1);
    setEducation(newInputs);
  };

  const handleExperienceRemove = (index) => {
    const newInputs = [...experience];
    newInputs.splice(index, 1);
    setExperience(newInputs);
  };

  function getExtension(filename) {
    return filename?.split('.').pop()
  }


  const [statusList, setStatusList] = useState([
    { value: "1", label: "Confirmed" },
    { value: "2", label: "Probation" },
    { value: "3", label: "Relieved" },
  ]);

  const handleProfileChange = (e) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    const files = Array.from(e.target.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize <= maxSizeInBytes) {
      setFile(files);
      setFiles(files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));

    } else {
      setFile([]);

      ToastMessage("error", "Uplaoad File is Greater than 10 MB");

    }
  }


  const handleClick = (e) => {
    setCurrentPage(e);
    activeTab === "2" ?
      getProjectPage(e, rowPerPage)
      : activeTab === "3" ?
        getAssetsPage(e, rowPerPage)
        :
        getLeavePage(e, rowPerPage)
  };

  const getEmployee = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('employee/' + id, {});
      if (response.data.status === true) {
        validation.setValues({
          ...response.data?.data,

          date_of_birth: DefaultDate(response.data?.data.date_of_birth, selectedCountry) || '',
          mobile: response.data?.data.users?.mobile || '',
          marital_status: response.data?.data.marital_status || 'Unmarried',
          gender: response.data?.data.gender || 'Male',
        });
        setAttachmentList(response.data?.attachment);
      } else {
        setAttachmentList();
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const getSettings = async () => {
    setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    setSelectedProfile(getSettingsDetails("settings")?.filter(type => type?.name === "profile")[0]?.value);
  };

  const viewUser = async () => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('userDetails', {});
      if (response.data.status === true) {
        setProfileDetails(response.data.data);
        setUserData(response.data.data);
      }

      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getUser = async (id) => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('user/' + id, {});
      if (response.data.status === true) {
        setProfileDetails(response.data.data);
        setUserData(response.data.data);
      }
      setLoading(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  const setUserData = (response) => {
    if (userDetails?.roles?.[0]?.name !== "Admin") {
      localStorage.setItem("authUser", JSON.stringify({
        email: profileDetails?.email,
        user: response,
        remember: profileDetails?.remember
      }));
      window.dispatchEvent(new Event("changeProfile"));
    }
  }

  const handleProjectClick = (arg) => {
    viewProject(arg?.id);
  };

  const handleAssestClick = (arg) => {
    viewAssets(arg?.id);
  };

  const handleLeaveClick = (arg) => {
    viewLeave(arg?.id);
  };

  const getAssets = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`userAssignedAsset/` + profileDetails?.id, {
        params: {
          page: currentPage,
          rowPerPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setAssetsList(response.data?.data);
      } else {
        setAssetsList([]);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const getAssetsPage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`userAssignedAsset/` + profileDetails?.id, {
        params: {
          page: currentPage,
          rowPerPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setAssetsList(response.data?.data);
      } else {
        setAssetsList([]);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const viewAssets = async (id) => {
    setIsAssetsView(true);
    setIsEdit(false);
    setIsProjectView(false);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('asset/' + id, {});

      if (response.data.status === true) {

        setAssetsView(response.data?.data);
        setAttachmentList(response.data?.attachment);
      } else {
        setAssetsView([]);
        setAttachmentList([]);
      }

      setOpen(!open);

    } catch (err) {
      console.error(err.message);
    }
  };

  const getProject = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`userAssignedProject/` + profileDetails?.id, {
        params: {
          page: currentPage,
          rowPerPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setProjectList(response.data?.data);
      } else {
        setProjectList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const getProjectPage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`userAssignedProject/` + profileDetails?.id, {
        params: {
          page: currentPage,
          rowPerPage: rowPerPage
        }
      });

      if (response.data.status === true) {
        setProjectList(response.data?.data);

      } else {
        setProjectList([]);

      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const viewProject = async (id) => {
    setIsProjectView(true);
    setIsAssetsView(false);
    setIsEdit(false);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('project/' + id, {});

      if (response.data.status === true) {

        setProjectView(response.data?.data);
      } else {
        setProjectView([]);

      }
      setOpen(true);

    } catch (err) {
      console.error(err.message);
    }

  };

  const getLeave = async () => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {

      const response = await api.get(`userAssignedLeave/` + profileDetails?.id, {
        params: {
          page: currentPage,
          perPage: rowPerPage
        }
      }
      );

      if (response.data.status === true) {
        setLeaveList(response.data?.data);
      } else {
        setLeaveList([]);
        ToastMessage("error", response.data?.message);
      }

    } catch (err) {
      console.error(err.message);
    }

  };

  const getLeavePage = async (currentPage, rowPerPage) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get(`userAssignedLeave/` + profileDetails?.id, {
        params: {
          page: currentPage,
          perPage: rowPerPage
        }
      }
      );

      if (response.data.status === true) {
        setLeaveList(response.data?.data);
      } else {
        setLeaveList([]);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const viewLeave = async (id) => {
    setIsProjectView(false);
    setIsAssetsView(false);
    setIsLeaveView(true);
    setIsEdit(false);
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.get('leave/' + id, {});
      if (response.data.status === true) {

        setLeaveView(response.data?.data);
      } else {
        setLeaveView([]);
      }

      setOpen(true);

    } catch (err) {
      console.error(err.message);
    }

  };



  const [searchParams] = useSearchParams();

  useEffect(() => {
    getSettings();
    if (searchParams.get('user_id') !== null) {
      getUser(searchParams.get('user_id'));
    } else {
      viewUser();
    }

  }, [])

  const handleRemoveProfile = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.post('employeeProfileDelete/' + id, {});

      if (response.data.status === true) {
        validation.setValues(response.data?.data);
      }

    } catch (err) {
      console.error(err.message);
    }

  };


  const handleEditClick = useCallback((profileDetails) => {
    setAttachmentList([]);
    setFiles();
    setFile();
    setInputs([{ title: '', file: null, preview: '' }]);
    getEmployee(profileDetails?.employee_id)
      .then(response => {
        setOpen(true);
      })
    setSubmit(false);
    setIsEdit(true);
  }, []);


  const handleEducationEdit = useCallback((profileDetails) => {

    if (profileDetails?.employee?.employee_educations?.length > 0) {
      setEducation(profileDetails?.employee?.employee_educations?.map(item => ({
        ...item,
        start_date: DefaultDate(item.start_date, selectedCountry),
        end_date: DefaultDate(item.end_date, selectedCountry),
      })));

    } else {

      setEducation([{ id: '', institution: '', subject: '', start_date: new Date().toISOString(), end_date: new Date().toISOString(), degree: '', grade: '' }]);

    }

    setTitle("Education");
    setEditMoreOpen(true);
    setInputs([{ title: '', file: null, preview: '' }]);

  }, []);

  const handleEmploymentEdit = useCallback((profileDetails) => {
    setTitle("Employment");
    setEditMoreOpen(true);
    setInputs([{ title: '', file: null, preview: '' }]);

    if (profileDetails?.employee?.employee_experiences?.length > 0) {


      setExperience(profileDetails?.employee?.employee_experiences?.map(item => ({
        ...item,
        start_date: DefaultDate(item.start_date, selectedCountry),
        end_date: DefaultDate(item.end_date, selectedCountry),
      })));

    } else {

      setExperience([{ id: '', company_name: '', job_position: '', location: '', start_date: new Date().toISOString(), end_date: new Date().toISOString(), }]);

    }

  }, []);


  const editEmployee = async (value) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    try {
      const formData = new FormData();
      formData.append('_method', "PUT");
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (key === "date_of_birth") {
            formData.append('date_of_birth', value.date_of_birth ? FormatDate(value.date_of_birth, selectedCountry) : "");
          } else {
            formData.append(key, value[key] || '');
          }
        }
      }

      inputs.forEach((input, index) => {
        formData.append(`attachment[${index}][title]`, input.title);
        formData.append(`attachment[${index}][file]`, input.file);
      });

      if (file) {
        formData.append(`profile`, file[0]);
      }

      const response = await api.post('employee/' + value.id, formData);

      if (response.data.status === true) {
        setOpen(false);
        setIsEdit(false);
        validation.resetForm();
        if (searchParams.get('user_id') !== null) {
          getUser(searchParams.get('user_id'));
        } else {
          viewUser();
        }

        ToastMessage("success", response.data?.message);

      } else {
        ToastMessage("error", response.data?.message);
      }
      validation.setSubmitting(false);

    } catch (err) {
      console.error(err.message);
    }
  };


  const addEmployeeEducation = async (value) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    try {
      const response = api.post('employeeEducation', {
        "education": education?.map(item => ({
          ...item,
          employee_id: profileDetails?.employee_id,
          start_date: item.start_date ? FormatDate(item.start_date, selectedCountry) : "",
          end_date: item.end_date ? FormatDate(item.end_date, selectedCountry) : "",
        }))
      });

      if (response) {
        if (searchParams.get('user_id') !== null) {
          getUser(searchParams.get('user_id'));
        } else {
          viewUser();
        }

        ToastMessage("success", response.data?.message);

      } else {
        ToastMessage("error", response.data?.message);
      }

      setEditMoreOpen(false);

    } catch (err) {
      console.error(err.message);
    }
  }

  const addEmployeeExperience = async (value) => {

    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    try {
      const response = api.post('employeeExperience', {
        "experience": experience?.map(item => ({
          ...item,
          employee_id: profileDetails?.employee_id,
          start_date: item.start_date ? FormatDate(item.start_date, selectedCountry) : "",
          end_date: item.end_date ? FormatDate(item.end_date, selectedCountry) : "",
        }))
      });

      if (response) {
        if (searchParams.get('user_id') !== null) {
          getUser(searchParams.get('user_id'));
        } else {
          viewUser();
        }

        ToastMessage("success", response.data?.message);

      } else {
        ToastMessage("error", response.data?.message);
      }
      setEditMoreOpen(false);

    } catch (err) {
      console.error(err.message);
    }

  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: '',
      last_name: '',
      mobile: '',
      date_of_birth: '',
      gender: '',
      emergency_contact_name: '',
      emergency_contact_number: '',
      //  designation_id: '',
      department_id: '',
      marital_status: 'Unmarried',
      spouse_name: '',
      parents_name: '',
      profile: "",
    },
    validationSchema: Yup.object({
      email: isEdit ? Yup.string() : Yup.string().required("Please enter your Email"),
      first_name: Yup.string().required("Please enter your name"),
      last_name: Yup.string().notRequired(),
      mobile: Yup.string()
        .min(10, "Mobile must be more than 10 characters long")
        .max(10, "Mobile must be less than 10 characters long").notRequired(),
      date_of_birth: Yup.string().required("Please enter"),
      gender: Yup.string().notRequired(),
      emergency_contact_name: Yup.string().required("Please enter"),
      emergency_contact_number: Yup.string()
        .min(10, "Mobile must be more than 10 characters long")
        .max(10, "Mobile must be less than 10 characters long").required("Please enter"),
      employee_type_id: Yup.string().required("Please enter"),
      employee_number_id: Yup.string().required(),
      role_id: isEdit ? Yup.string() : Yup.string().required("Please enter"),
      department_id: Yup.string().required("Please enter"),
      // designation_id: Yup.string().required("Please enter"),
      status: Yup.string().notRequired(),
      probation_period: Yup.string().when('status', {
        is: (status) => ['2'].includes(status),
        then: (schema) => schema.required("Enter"),
        otherwise: (schema) => schema.notRequired(),
      }),

      // notice_period:  Yup.string()
      // .min(1, "notice period must be more than 3 characters long")
      // .max(3, "notice period must be less than 3 characters long").required("Please enter"),
      marital_status: Yup.string().required("Please enter"),
      spouse_name: Yup.string().notRequired(),
      parents_name: Yup.string().notRequired(),
      profile: Yup.string().notRequired(),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        await editEmployee(values, validation.values?.id);
        setSubmitting(false);
      } catch (error) {
        console.error("An error occurred:", error);
        setSubmitting(false);
      }
    },
  });

  const educationValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      institution: '',
      subject: '',
      start_date: '',
      end_date: '',
      degree: '',
      grade: '',

    },
    validationSchema: Yup.object({
      institution: Yup.string().notRequired(),
      subject: Yup.string().notRequired(),
      start_date: Yup.string().notRequired(),
      end_date: Yup.string().notRequired(),
      degree: Yup.string().notRequired(),
      grade: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      addEmployeeEducation(values);
    },
  });

  const experienceValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: '',
      location: '',
      job_position: '',
      start_date: '',
      end_date: '',

    },
    validationSchema: Yup.object({
      company_name: Yup.string().notRequired(),
      location: Yup.string().notRequired(),
      job_position: Yup.string().notRequired(),
      start_date: Yup.string().notRequired(),
      end_date: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      addEmployeeExperience(values);
    },
  });

  const handleSubmit = () => {
    setSubmit(true);
    if (validation.isValid) {
      validation.handleSubmit();
    }
  };


  const handleDelete = async (id) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    api.defaults.headers.common['Content-Type'] = 'application/json'
    try {
      const response = await api.delete('employeeAttachment/' + id, {});
      if (response.data.status === true) {
        setAttachmentList(attachmentList?.filter(item => item.id !== id));
      }

    } catch (err) {
      console.error(err.message);
    }

  };


  document.title = "Profile ";
  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <Container fluid>
          {!loading ? <>
            <Row>
              <Col lg={12}>
                <Card className='mb-0'>
                  <CardBody className='py-0'>
                    <div className="pt-2  mb-lg-3 pb-lg-2 mb-0">
                      <Row>
                        <Col className='d-flex justify-content-end'>
                          <div className="flex-shrink-0 ">
                            <FeatherIcon icon="edit" className="cursor text-secondary me-2 icon-sm" onClick={(e) => { handleEditClick(profileDetails); }} />
                          </div>
                        </Col>
                      </Row>

                      <Row className="g-4">
                        <div className="col-auto">
                          <div className="avatar-lg">
                            <img src={profileDetails?.employee?.profile ? url + profileDetails?.employee?.profile : url + selectedProfile}
                              alt="user-img"
                              style={{ width: "200px", height: "100px" }}
                              className="img-thumbnail rounded-circle" />
                          </div>
                        </div>

                        <Col className='borderRight' >
                          <div className="p-2">
                            <h3>{profileDetails?.employee?.first_name} {profileDetails?.employee?.last_name}</h3>
                            <p className="mb-1 text-secondary">{profileDetails?.roles?.[0]?.name}</p>
                            <p className="mb-1  text-secondary">  {profileDetails.employee?.designations?.name}</p>
                          </div>
                        </Col>

                        <Col xs={12} md={4} className='mt-0 mt-md-4 borderRight'   >
                          <div className="p-2">
                            <p> <span className=" fw-bold">  Email : </span> <span className="  text-opacity-75"> {profileDetails?.email} </span> </p>
                            <p><span className=" fw-bold"> Employee Number : </span>  <span className=" text-opacity-75">  {profileDetails?.employee?.employee_number_id} </span>  </p>
                            <p className="mb-0">  <span className=" fw-bold"> Date of Join  : </span> <span className=" text-opacity-75"> {profileDetails?.employee?.date_of_joining ? DefaultDate(profileDetails?.employee?.date_of_joining, selectedCountry) : ""}  </span>   </p>
                          </div>
                        </Col>

                        <Col xs={12} md={4} className='mt-0 mt-md-4' >
                          <div className="p-2">
                            <p> <span className="fw-bold">Mobile : </span> <span className="text-opacity-75">  {profileDetails?.mobile}  </span>  </p>
                            <p> <span className="fw-bold">Status : </span> <span className="text-opacity-75"> {profileDetails?.employee?.status ? statusList?.filter(name => name?.value === profileDetails?.employee?.status)[0]?.label : ""} </span>  </p>
                            <p className="mb-0"> <span className="fw-bold">  Gender : </span><span className=" text-opacity-75">  {profileDetails?.employee?.gender}  </span>   </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>

                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div>
                  <Card>
                    <CardBody className='px-0 py-0'>
                      <div className="d-flex">
                        <Nav className="nav-tabs-custom   border-bottom-0"
                          role="tablist" >
                          <NavItem>
                            <NavLink
                              href="#overview-tab"
                              className={classnames(
                                { active: activeTab === "1" },
                                "text-muted"
                              )}
                              onClick={() => { toggleTab('1'); }}
                            >
                              <i className="ri-user-2-fill d-inline-block d-md-none"></i> <span
                                className="d-none d-md-inline-block">Profile</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              href="#projects"
                              className={classnames(
                                { active: activeTab === "2" },
                                "text-muted"
                              )}
                              onClick={() => {
                                toggleTab('2');
                                setCurrentPage(1);
                                setRowPerPage(50);
                                getProject();
                              }}
                            >
                              <i className="ri-folder-5-fill d-inline-block d-md-none"></i> <span
                                className="d-none d-md-inline-block">Projects</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#assets" className={classnames({ active: activeTab === "3" }, "text-muted")}
                              onClick={() => { toggleTab('3'); setCurrentPage(1); setRowPerPage(50); getAssets(); }} >
                              <i className="ri-tv-2-fill d-inline-block d-md-none"></i> <span
                                className="d-none d-md-inline-block">Assets</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#leaves" className={classnames({ active: activeTab === "4" }, "text-muted")}
                              onClick={() => { toggleTab('4'); setCurrentPage(1); setRowPerPage(50); getLeave(); }} >
                              <i className="ri-time-fill d-inline-block d-md-none"></i> <span
                                className="d-none d-md-inline-block">Leaves</span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </CardBody>
                  </Card>

                  <TabContent activeTab={activeTab} className="pt-0">
                    <TabPane tabId="1">
                      <Row>
                        <Col xs={12} md={6}>
                          <Card>
                            <CardBody>
                              <h5 className=" mb-3 fw-bold">Personal Informations</h5>
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <p > <span className=" fw-bold"> Mobile  : </span> {profileDetails?.mobile}  </p>
                                  </div>
                                </Col>
                                <Col lg={12}>
                                  <div className="mb-3">
                                    <p > <span className=" fw-bold">Parents Name  : </span>  {profileDetails?.employee?.parents_name}  </p>
                                  </div>
                                </Col>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <p> <span className=" fw-bold"> Date of Birth  : </span> {profileDetails?.employee?.date_of_birth ? DefaultDate(profileDetails?.employee?.date_of_birth, selectedCountry) : ""} </p>
                                  </div>
                                </Col>

                                <Col xs={12}>
                                  <div className="mb-3">
                                    <p> <span className=" fw-bold"> Marital Status  : </span> {profileDetails?.employee?.marital_status}  </p>
                                  </div>
                                </Col>
                                {profileDetails?.employee?.marital_status === "Married" &&
                                  <Col xs={12}>
                                    <div className="mb-3">
                                      <p > <span className=" fw-bold"> Spouse Name  : </span>  {profileDetails?.employee?.spouse_name}  </p>
                                    </div>
                                  </Col>}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>


                        <Col xs={12} md={6} >
                          <Card>
                            <CardBody>
                              <h5 className=" mb-3 fw-bold">Employee Informations</h5>
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <p  > <span className=" fw-bold"> Department    :</span> {profileDetails?.employee?.departments?.name}</p>
                                  </div>
                                </Col>

                                 <Col xs={12}>
                                  <div className="mb-3">
                                    <p > <span className=" fw-bold">Designation    : </span>    {profileDetails?.employee?.designations?.name}</p>
                                  </div>
                                </Col> 

                                {profileDetails?.employee?.status === "2" &&
                                  <Col xs={12}>
                                    <div className="mb-3">
                                      <p > <span className=" fw-bold">Probation Period  : </span>  {profileDetails?.employee?.probation_period + " In Days"} </p>
                                    </div>
                                  </Col>}

                                <Col xs={12}>
                                  <div className="mb-3">
                                    <p > <span className=" fw-bold"> Confirmation Date  : </span> {profileDetails?.employee?.confirmation_date ? DefaultDate(profileDetails?.employee?.confirmation_date, selectedCountry) : ""}  </p>
                                  </div>
                                </Col>

                                <Col xs={12}>
                                  <div className="mb-3">
                                    <p > <span className=" fw-bold"> Notice Period   : </span>  {profileDetails?.employee?.notice_period}  </p>
                                  </div>
                                </Col>

                              </Row>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs={12} md={6}  >
                          <Card>
                            <CardBody>
                              <h5 className=" mb-3 fw-bold">Emergency Contact</h5>
                              <Row>
                                <Col lg={12}>
                                  <div className="mb-3">
                                    <p > <span className=" fw-bold"> Emergency Contact Name  : </span>  {profileDetails?.employee?.emergency_contact_name}  </p>
                                  </div>
                                </Col>

                                <Col lg={12}>
                                  <div className="mb-3">
                                    <p > <span className=" fw-bold"> Emergency Contact Number  : </span> {profileDetails?.employee?.emergency_contact_number}   </p>
                                  </div>
                                </Col>

                              </Row>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs={12} md={6} >
                          <Card>
                            <CardBody>
                              <h5 className=" mb-3 fw-bold">Proof</h5>
                              <div className="avatar-group">
                                {profileDetails?.employee?.employee_attachments ? profileDetails?.employee?.employee_attachments?.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Link to="#" className="avatar-group-item" id={`proof${index}`}
                                        onClick={() => {
                                          setModalFile(true);

                                          setFileUrl({
                                            profile: false, name: item.name, path: url + item.path,
                                            ext: url + item.path
                                          });

                                        }} >
                                        {item.name ?

                                          <img src={url + item.path} alt="" className="rounded-circle avatar-sm" />

                                          :
                                          <div className="avatar-sm">
                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                              {item.name?.slice(0, 1)}
                                            </div>
                                          </div>

                                        }
                                      </Link>
                                      <UncontrolledTooltip placement="top" target={`proof${index}`}> {item.name} </UncontrolledTooltip>
                                    </React.Fragment>
                                  );
                                }) : ""}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs={12} md={6}  >
                          <Card>
                            <CardBody className='pb-4'>
                              <div className='d-flex justify-content-between'>
                                <h5 className="mb-1 fw-bold">Education Information</h5>
                                <div className="flex-shrink-0 ">
                                  <FeatherIcon icon="edit" className="cursor text-secondary me-2 icon-sm" onClick={(e) => {
                                    handleEducationEdit(profileDetails);
                                  }} />
                                </div>
                              </div>
                              <Row>
                                <Col lg={12}>
                                  <div className='profile'>
                                    <div className="profile timeline-2">
                                      <div className="timeline-continue">
                                        {profileDetails?.employee?.employee_educations?.map((item, index) => {


                                          return (
                                            <Row className="timeline-right" key={index}>
                                              <Col xs={12}>
                                                < p className="timeline-date">
                                                  <span className='fw-bold'>{item.institution}   </span><br />
                                                  <span className='fw-nornal'> {item.degree} {item.subject}  </span><br />
                                                  <span className='fw-nornal'>  {moment(item.start_date).tz(moment.tz.guess()).format('YYYY')}
                                                    - {moment(item.end_date).tz(moment.tz.guess()).format('YYYY')}  </span>
                                                </p>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs={12} md={6}  >
                          <Card>
                            <CardBody className='pb-4'>
                              <div className='d-flex justify-content-between'>
                                <h5 className=" mb-1 fw-bold">Experience</h5>
                                <div className="flex-shrink-0 ">
                                  <FeatherIcon icon="edit" className="cursor text-secondary me-2 icon-sm"
                                    onClick={(e) => {

                                      handleEmploymentEdit(profileDetails);
                                    }}
                                  />
                                </div>
                              </div>
                              <Row>
                                <Col lg={12}>
                                  <div className='profile'>
                                    <div className=" timeline-2">
                                      <div className="timeline-continue">
                                        {profileDetails?.employee?.employee_experiences?.map((item, index) => {
                                          return (
                                            <Row className="timeline-right" key={index}>
                                              <Col xs={12}>
                                                < p className="timeline-date">
                                                  <span className='fw-bold'> {item.job_position} at {item.company_name}  </span><br />
                                                  <span className='fw-nornal'>{moment(item.start_date).tz(moment.tz.guess()).format('MMM YYYY')}  - {item.end_date ? moment(item.end_date).tz(moment.tz.guess()).format('MMM YYYY') : "Present"} </span>
                                                </p>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="2">
                      <Row>
                        <Col >
                          <Card>
                            <CardBody className=" py-2">
                              <Row className="row  gy-1 mb-1 mt-1 px-2" >
                                <Col lg={12} className='table px-1 mb-0' >
                                  <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                    <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                      <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-4 px-1">
                                          <h5 className="fs-15 mb-0">Project Name</h5>
                                        </div>

                                        <div className="col-3 px-2 d-flex justify-content-end justify-content-md-start" >
                                          <h5 className="fs-15 mb-0">Client </h5>
                                        </div>

                                        <div className="col-3  px-2  d-flex justify-content-start" >
                                          <h5 className="fs-15 mb-0">Deadline </h5>
                                        </div>

                                        <div className="col-2 px-2 d-flex justify-content-end">
                                          <h5 className="fs-15 mb-0"> Status </h5>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>

                              <Row className="row gy-1 mb-2" id="project-list">
                                {projectList?.data?.length !== 0 ?

                                  (projectList?.data)?.map((item, index) => (

                                    <Col lg={12} key={index} className='mt-0' >
                                      <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 px-1 ">
                                        <CardBody className="px-1 py-0">

                                          <div className="d-lg-flex align-items-center row">

                                            <div className="col-6 col-md-4 px-2" >

                                              <a href="#!" > <span onClick={() => { handleProjectClick(item); }}>  {item.name}</span>  </a>

                                            </div>

                                            <div className="col-6 col-md-3  px-2   d-flex justify-content-end justify-content-md-start"  >

                                              <p className="mb-0 ">  {item.client?.name}  </p>

                                            </div>

                                            <div className="col-6 col-md-3  px-2  d-flex justify-content-start" >

                                              <p className=" mb-0">  {DefaultDate(item.end_date, selectedCountry)}  </p>

                                            </div>

                                            <div className="col-6 col-md-2  px-2 d-flex justify-content-end">

                                              <p className="mb-0">   {item.status === 1 ?

                                                <Badge color="success-subtle text-success" >Active</Badge>
                                                :
                                                <Badge color="danger-subtle text-danger"> Inactive  </Badge>

                                              } </p>
                                            </div>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ))
                                  :
                                  <div className="noresult" >
                                    <div className="text-center">
                                      <h6 className="mt-2">Sorry! No Result Found</h6>
                                    </div>
                                  </div>
                                }
                              </Row>

                              <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                                <div className="col-sm-6 d-flex gap-2 justify-content-end">
                                  <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                    onChange={(e) => {
                                      setRowPerPage(e.target.value);
                                      getProjectPage(1, e.target.value);
                                    }}
                                    value={rowPerPage}
                                  >

                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={projectList?.total}>All</option>
                                  </select>

                                  <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={50}
                                    totalItemsCount={projectList?.total || 0}
                                    pageRangeDisplayed={10}
                                    onChange={handleClick}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                  />
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="3">
                      <Row>
                        <Col>
                          <Card>
                            <CardBody className="py-2">
                              <Row className="row gy-1 mb-1 mt-1 px-2"  >
                                <Col lg={12} className='table px-1  mb-0 '  >
                                  <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                    <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                      <div className="row d-lg-flex align-items-center px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-6  col-md-3 px-1 align-items-center  d-flex justify-content-end  justify-content-md-start px-md-2"  >
                                          <h5 className="fs-15 mb-0">Name</h5>
                                        </div>

                                        <div className="col-7 col-md-3  px-1 align-items-center">
                                          <h5 className="fs-15 mb-0">Asset Date</h5>
                                        </div>

                                        <div className="col-12 col-md-3 align-items-center ">
                                          <h5 className="fs-15 mb-0">Condition</h5>
                                        </div>

                                        <div className="col-6 col-md-3 align-items-center d-flex justify-content-end">
                                          <h5 className="fs-15 mb-0" >Return Date</h5>
                                        </div>
                                      </div>

                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>

                              <Row className="row gy-1 mb-2" id="assets-list">
                                {assetsList?.data?.length !== 0 ?
                                  (assetsList?.data)?.map((item, index) => (
                                    <Col lg={12} key={index} className='mt-0'  >
                                      <Card className="mb-0  border-0 border-bottom rounded-0 py-1">
                                        <CardBody className="px-1 py-0">

                                          <div className="d-lg-flex align-items-center row">

                                            <div className="col-6 col-md-3 px-md-3">

                                              <a href="#!" onClick={() => { handleAssestClick(item); }}>  {item.asset?.asset_name} </a>

                                            </div>

                                            <div className="col-6 col-md-3 d-flex justify-content-end justify-content-md-start">

                                              <p className={`mb-0 `}> {DefaultDate(item.asset_date, selectedCountry)}</p>

                                            </div>

                                            <div className="col-4 col-md-3 align-items-center d-none d-md-flex">

                                              <p className=" mb-0 ">{item.condition}</p>

                                            </div>


                                            <div className="d-flex  col-6 col-md-3 mt-0 align-items-center  justify-content-end ">

                                              <p className="mb-0 ">

                                                {DefaultDate(item.return_date, selectedCountry)}
                                              </p>

                                            </div>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>

                                  ))

                                  :
                                  <div className="noresult" >
                                    <div className="text-center">
                                      <h6 className="mt-2">Sorry! No Result Found</h6>
                                      {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
orders for you search.</p> */}
                                    </div>
                                  </div>
                                }
                              </Row>

                              <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                                <div className="col-sm-6 d-flex gap-2 justify-content-end">
                                  <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                    onChange={(e) => {
                                      setRowPerPage(e.target.value);
                                      getAssetsPage(1, e.target.value);
                                    }}
                                    value={rowPerPage}
                                  >
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={assetsList?.total}>All</option>

                                  </select>

                                  <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={50}
                                    totalItemsCount={assetsList?.total || 0}
                                    pageRangeDisplayed={10}
                                    onChange={handleClick}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                  />

                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="4">
                      <Row>
                        <Col>
                          <Card>
                            <CardBody className="py-2">
                              <Row className="row gy-1 mb-1 mt-1 px-2"  >
                                <Col lg={12} className='table px-1  mb-0'  >
                                  <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                    <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                      <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                                        <div className="col-6  mx-auto d-flex gap-2 align-items-center justify-content-end  justify-content-md-start px-md-2">
                                          <h5 className="fs-15 mb-0">Leave Type</h5>
                                        </div>

                                        <div className="col-5 mx-auto d-flex gap-2 align-items-center ">
                                          <h5 className="fs-15 mb-0">Date </h5>
                                        </div>

                                        <div className="col-1  d-flex gap-2 align-items-center justify-content-end px-md-3">
                                          <h5 className="fs-15 mb-0">Status  </h5>
                                        </div>

                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>

                              <Row className="row gy-1 mb-2" id="leave-list">
                                {leaveList?.data?.length !== 0 ?
                                  (leaveList?.data)?.map((item, index) => (
                                    <Col lg={12} key={index} className='mt-0'  >
                                      <Card className="mb-0  border-0 border-bottom rounded-0 py-1">
                                        <CardBody className="px-1 py-0">

                                          <div className="d-flex align-items-center row">
                                            <div className="col-8 col-md-6 order-1 order-md-1  d-flex gap-2 align-items-center ">
                                              <a href="#!" onClick={() => { handleLeaveClick(item); }}>   {item.leave_type?.name}</a>
                                            </div>


                                            <div className="col-12 col-md-5 order-3 order-md-2 mx-auto d-flex gap-2 align-items-center">

                                              {item.leave_duration === "Single" ?
                                                DefaultDate(item.start_date, selectedCountry)
                                                : item.leave_duration === "Multi" ?
                                                  DefaultDate(item.start_date, selectedCountry) + " - " + DefaultDate(item.end_date, selectedCountry)
                                                  : item.leave_duration === "Half" ?
                                                    DefaultDate(item.start_date, selectedCountry) + " (" + item.half_day_period + " half)"
                                                    :
                                                    DefaultDate(item.start_date, selectedCountry) + " (" +
                                                    item.start_time + " - " + item.end_time + ")"
                                              }

                                            </div>

                                            <div className="col-4 col-md-1 order-2 order-md-3 d-flex gap-2 align-items-center justify-content-end">
                                              <Badge color={`${item?.status === "Approved" ? "success-subtle text-success" : "danger-subtle text-danger"}`} >
                                                {item?.status} </Badge>
                                            </div>

                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ))

                                  :
                                  <div className="noresult" >
                                    <div className="text-center">
                                      <h6 className="mt-2">Sorry! No Result Found</h6>

                                    </div>
                                  </div>
                                }
                              </Row>

                              <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">
                                <div className="col-sm-6 d-flex gap-2 justify-content-end">
                                  <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                    onChange={(e) => {
                                      setRowPerPage(e.target.value);
                                      getLeavePage(1, e.target.value);
                                    }}
                                    value={rowPerPage}
                                  >
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={leaveList?.total}>All</option>
                                  </select>
                                  <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={50}
                                    totalItemsCount={leaveList?.total || 0}
                                    pageRangeDisplayed={10}
                                    onChange={handleClick}
                                    itemClass="page-item"
                                    linkClass="page-link"

                                  />

                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </>
            : <div className='text-center'>
              <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                Loading...
              </Spinner>
            </div>
          }

          <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">{isEdit ? "Update Profile" : isAssetsView ? "View Asset" : "View Project"}</span>
                <span>
                  {isEdit && <span className="fs-18 px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>

              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">
                  {isEdit ?
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="first_name" className="form-label">First Name  <span className='secondary'>*</span> </Label>
                          <Input type="text" className="form-control px-2 py-1" id="first_name" placeholder="Enter first name"
                            name='first_name'
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ""}
                            invalid={
                              submit && validation.errors.first_name ? true : false
                            }
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="last_name" className="form-label">Last Name   </Label>
                          <Input type="text" className="form-control px-2 py-1" id="last_name" placeholder="Enter last name"
                            name='last_name'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ""}
                            invalid={
                              submit && validation.errors.last_name ? true : false
                            }
                          />

                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="mobile" className="form-label">Mobile   </Label>
                          <Input type="number" className="form-control px-2 py-1" id="mobile" placeholder="Enter mobile" name='mobile'
                            validate={{
                              required: { value: true },
                            }}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                            onChange={(e) => {
                              let inputValue = e.target.value;
                              if (inputValue.length > 10) {
                                inputValue = inputValue.slice(0, 10);
                              }

                              validation.handleChange({
                                target: {
                                  name: 'mobile',
                                  value: inputValue,
                                },
                              });
                            }}

                            onBlur={validation.handleBlur}
                            value={validation.values.mobile || ""}
                            invalid={
                              submit && validation.errors.mobile ? true : false
                            }
                          />

                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="date_of_birth" className="form-label">Date of Birth <span className='secondary'>*</span>   </Label>
                          <Flatpickr
                            name='date_of_birth'
                            className={`form-control py-1 px-2 ${submit && validation.errors.date_of_birth ? 'is-invalid' : ''}`}

                            options={{
                              allowInput: true,
                              dateFormat: SmallDateFormat(selectedCountry),
                            }}

                            onChange={(selectedDates, dateStr, instance) => {
                              validation.setValues({
                                ...validation.values,
                                date_of_birth: dateStr
                              });
                            }}

                            value={validation.values.date_of_birth || ""}

                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="gender" className="form-label">Gender</Label>
                          <FormGroup tag={Row}>
                            <Col className="d-flex gap-2 align-items-center" >
                              <Label check className="d-flex gap-2 align-items-center">
                                <Input
                                  type="radio"
                                  name="gender"
                                  value="Male"
                                  checked={validation.values.gender === 'Male'}
                                  onChange={validation.handleChange}
                                />
                                Male
                              </Label>

                              <Label check className="d-flex gap-2 align-items-center">
                                <Input
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                  checked={validation.values.gender === 'Female'}
                                  onChange={validation.handleChange}
                                />
                                Female
                              </Label>
                            </Col>
                          </FormGroup>
                        </div>
                      </Col>


                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="emergency_contact_name" className="form-label">Emergency Contact Name   <span className='secondary'>*</span>  </Label>
                          <Input type="text" className="form-control px-2 py-1" id="emergency_contact_name" placeholder="Enter emergency contact name" name='emergency_contact_name'
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.emergency_contact_name || ""}
                            invalid={
                              submit && validation.errors.emergency_contact_name ? true : false
                            }
                          />
                        </div>
                      </Col>


                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="emergency_contact_number" className="form-label">Emergency Contact Number   <span className='secondary'>*</span>  </Label>
                          <Input type="number" className="form-control px-2 py-1" id="emergency_contact_number" placeholder="Emergency contact number" name='emergency_contact_number'
                            validate={{
                              required: { value: true },
                            }}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                            onChange={(e) => {
                              let inputValue = e.target.value;

                              if (inputValue.length > 10) {
                                inputValue = inputValue.slice(0, 10);
                              }

                              validation.handleChange({
                                target: {
                                  name: 'emergency_contact_number',
                                  value: inputValue,
                                },
                              });

                            }
                            }
                            onBlur={validation.handleBlur}
                            value={validation.values.emergency_contact_number || ""}
                            invalid={
                              submit && validation.errors.emergency_contact_number ? true : false
                            }
                            max={10}
                          />
                        </div>
                      </Col>


                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="parents_name" className="form-label">Parents Name </Label>
                          <Input type="text" className="form-control px-2 py-1" id="parents_name" placeholder="Enter parents name" name='parents_name'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.parents_name || ""}
                          />

                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="marital_status" className="form-label">Marital Status     </Label>
                          <FormGroup tag={Row}>
                            <Col className="d-flex gap-2 align-items-center" >
                              <Label check className="d-flex gap-2 align-items-center">
                                <Input
                                  type="radio"
                                  name="marital_status"
                                  value="Married"
                                  checked={validation.values.marital_status === 'Married'}
                                  onChange={validation.handleChange}
                                />
                                Married
                              </Label>

                              <Label check className="d-flex gap-2 align-items-center">
                                <Input
                                  type="radio"
                                  name="marital_status"
                                  value="Unmarried"
                                  checked={validation.values.marital_status === 'Unmarried'}
                                  onChange={validation.handleChange}
                                />
                                Unmarried
                              </Label>
                            </Col>
                          </FormGroup>
                        </div>
                      </Col>

                      {validation.values.marital_status === 'Married' && <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="spouse_name" className="form-label">Spouse Name </Label>
                          <Input type="text" className="form-control px-2 py-1" id="spouse_name" placeholder="Enter spouse name" name='spouse_name'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.spouse_name || ""}
                          />

                        </div>
                      </Col>}

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="profile" className="form-label">Profile Picture  </Label>
                          <Input type="file" accept="image/*" className="form-control px-2 py-1" id="profile"
                            name='profile'
                            onChange={handleProfileChange}
                          />
                        </div>

                        <Row className='pb-2'>
                          {(files || [])?.map((item, index) => {
                            return [
                              <Col xs={6} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                <FaTimesCircle className="close-button" onClick={(e) => {
                                  setFiles(
                                    files.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                  );
                                  setFile(
                                    file.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                  );
                                }} />
                                <div className="selected-image cursor" key={index} onClick={(e) => {
                                  setModalProfile(true); setFileUrl({ profile: true, name: item.name, path: item.preview });
                                }}>
                                  <img
                                    src={item.preview}
                                    alt={item.name}
                                    width={100}
                                    height={80}
                                  />

                                </div>
                              </Col>
                            ]
                          })}


                          {validation.values.profile &&
                            <Col xs={6} className='text-center p-2' >
                              <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                handleRemoveProfile(validation.values.id);
                              }} />

                              <div className="selected-image cursor d-flex text-center" onClick={(e) => {
                                setModalProfile(true); setFileUrl({ profile: true, name: "", path: url + validation.values.profile, ext: url + validation.values.profile });
                              }}>

                                <img src={url + validation.values.profile}
                                  alt={validation.values.profile}
                                  width={100}
                                  height={80}
                                />

                              </div>
                            </Col>
                          }

                        </Row>
                      </Col>

                      <Col lg={12} className='py-2'>
                        <div className="mb-2">
                          <div className='d-flex justify-content-between align-items-end'>
                            <Label htmlFor="attachment" className="form-label">Upload Proof</Label>
                            <button
                              type="button"
                              className="btn btn-primary shadow-lg px-2 py-1 mb-2 d-flex align-items-center gap-1"
                              onClick={handleAddInput}
                            >
                              <FaPlus /> More
                            </button>
                          </div>

                          {inputs?.map((input, index) => (
                            <div key={index} className='d-flex gap-3 py-1 align-items-center'>
                              <Input type="text" className="form-control px-2 py-1" placeholder="Enter title" name='title'
                                onChange={(e) => handleTitleChange(index, e.target.value)}
                              />
                              <Input type="file" accept=".pdf, image/*" className="form-control px-2 py-1" id="attachment"
                                name='attachment'
                                onChange={(e) => handleProofChange(index, e.target.files[0])}
                              />

                              {index !== 0 ?
                                <FaTimesCircle className="cursor text-danger" size={25} style={{ minWidth: "20px" }} onClick={() => handleRemoveInput(index)} />
                                :
                                <FaTimesCircle className="cursor text-danger invisible" size={25} style={{ minWidth: "20px" }} onClick={() => handleRemoveInput(index)} />
                              }
                            </div>
                          ))}

                        </div>

                        <Row className='py-2  '>
                          {(inputs || [])?.filter(item => item.preview !== "")?.map((item, index) => {

                            return [
                              <Col xs={6} sm={3} key={index} className='text-center p-2' >
                                {index !== 0 && <FaTimesCircle className="close-button" onClick={(e) => {
                                  handleRemoveInput(index);
                                }} />}

                                <div className="selected-image cursor d-flex text-center" onClick={(e) => {
                                  setModalFile(true);
                                  setFileUrl({ profile: false, name: item.title, path: item.preview, ext: item.file?.name });
                                }}>
                                  {imageExtensions.has(getExtension(item.file?.name)?.toLowerCase()) ?
                                    <img
                                      src={item.preview}
                                      alt={item.file?.name}
                                      width={100}
                                      height={80}
                                    />
                                    : pdfExtensions.has(getExtension(item.file?.name)?.toLowerCase()) ?
                                      <FaFilePdf size={30} />
                                      : excelExtensions.has(getExtension(item.file?.name)?.toLowerCase()) ?
                                        <FaFileExcel size={30} />
                                        : <FaFileAlt size={30} />

                                  }

                                </div>
                                <br />
                                {item.title}
                              </Col>
                            ]
                          })}

                          {attachmentList?.map((item, index) => {
                            return [
                              <Col xs={6} sm={3} key={index} className='text-center p-2' >
                                <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                  handleDelete(item.id);
                                }} />

                                <div className="selected-image cursor d-flex text-center" onClick={(e) => {
                                  setModalFile(true); setFileUrl({ profile: false, name: item.name, path: url + item.path, ext: url + item.path });
                                }}>

                                  {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                    <img
                                      src={url + item.path}
                                      alt={item.name}
                                      width={100}
                                      height={80}
                                    />
                                    : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                      <FaFilePdf size={30} />
                                      : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                        <FaFileExcel size={30} />
                                        : <FaFileAlt size={30} />

                                  }
                                </div>
                                <br />
                                {item.name}
                              </Col>
                            ]
                          })}
                        </Row>
                      </Col>

                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button" disabled={validation.isSubmitting ? true : false}
                          className="btn btn-primary shadow-lg btn-label right ms-auto nexttab nexttab"
                          onClick={() => {
                            handleSubmit();
                          }}
                        > {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          Submit
                        </button>
                      </div>
                    </Row>

                    : isAssetsView ?
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Name </Label> <br />
                            {assetsView?.asset_name}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Type </Label> <br />
                            {assetsView?.asset_category?.name}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Purchase Value </Label> <br />
                            {FormatNumber(assetsView?.value)}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Purchase Date </Label>
                            <br />
                            {DefaultDate(assetsView?.purchase_date, selectedCountry)}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Vendor </Label>
                            <br />
                            {assetsView?.purchase_from}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Manufacturer </Label>
                            <br />
                            {assetsView?.manufacturer}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Model </Label>
                            <br />
                            {assetsView?.model}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Serial Number </Label>
                            <br />
                            {assetsView?.serial_number}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Supplier </Label>
                            <br />
                            {assetsView?.supplier}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Warranty </Label>
                            <br />
                            {assetsView?.warranty}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Description </Label>
                            <br />
                            {assetsView?.description}
                          </div>

                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label fw-bold"> Asset User </Label>
                            <br />
                            {assetsView?.user?.name}
                          </div>
                        </Col>
                      </Row>
                      : isProjectView ?
                        <Row>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Project Name  </Label> <br />
                              {projectView?.name}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Client </Label> <br />
                              {projectView?.client?.name}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Start Date </Label> <br />
                              {DefaultDate(projectView?.start_date, selectedCountry)}
                            </div>

                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold">End Date </Label>
                              <br />

                              {DefaultDate(projectView?.end_date, selectedCountry)}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-2">

                              <Label className="form-label fw-bold">Team Leader </Label>

                              <div className="avatar-group">
                                <Link to="#" className="avatar-group-item" id={`leader`} >

                                  {validation.values.leader ?
                                    projectView?.leader?.employee?.profile ? <img src={url + projectView?.leader?.employee?.profile} alt="" className="rounded-circle avatar-sm" />
                                      :
                                      <div className="avatar-sm">
                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                          {projectView?.leader?.name?.slice(0, 1)}
                                        </div>
                                      </div>
                                    : ""}

                                </Link>

                                <UncontrolledTooltip placement="top" target={`leader`}> {projectView?.leader?.name} </UncontrolledTooltip>

                              </div>
                            </div>
                          </Col>


                          <Col lg={6}>
                            <div className="mb-2">
                              <Label className="form-label fw-bold">Team Members</Label>

                              <div className="avatar-group">
                                {projectView?.project_users ? projectView?.project_users?.filter(item => (item?.user_id !== projectView?.leader_id))?.map((item, index) => {

                                  return (
                                    <React.Fragment key={index}>
                                      <Link to="#" className="avatar-group-item" id={`profile${index}`} >
                                        {item.user?.employee?.profile ? <img src={url + item.user?.employee?.profile} alt="" className="rounded-circle avatar-sm" />
                                          :
                                          <div className="avatar-sm">
                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                              {item.user?.name?.slice(0, 1)}
                                            </div>
                                          </div>
                                        }
                                      </Link>
                                      <UncontrolledTooltip placement="top" target={`profile${index}`}  > {item.user?.name} </UncontrolledTooltip>
                                    </React.Fragment>
                                  );
                                }) : ""}

                              </div>

                            </div>
                          </Col>


                          <Col lg={12}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Description </Label>   <br />

                              <div dangerouslySetInnerHTML={{ __html: projectView?.description }} />
                            </div>

                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Status </Label>
                              <br />
                              {projectView?.status === 1 ?

                                <Badge color="success-subtle text-success" >Active</Badge>
                                :
                                <Badge color="danger-subtle text-danger"  > Inactive  </Badge>

                              }
                            </div>
                          </Col>

                        </Row>
                        : isLeaveView &&
                        <Row>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Employee Name</Label> <br />
                              {leaveView?.user?.name}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Leave Type </Label> <br />
                              {leaveView?.leave_type?.name}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Date </Label> <br />
                              {leaveView?.leave_duration === "Single" ?
                                DefaultDate(leaveView?.start_date, selectedCountry)
                                : leaveView?.leave_duration === "Multi" ?
                                  DefaultDate(leaveView?.start_date, selectedCountry) + " - " + DefaultDate(leaveView.end_date, selectedCountry)
                                  : leaveView?.leave_duration === "Half" ?
                                    DefaultDate(leaveView?.start_date, selectedCountry) + " (" + leaveView?.half_day_period + " half)"
                                    :
                                    DefaultDate(leaveView?.start_date, selectedCountry) + " (" +
                                    leaveView?.start_time + " - " + leaveView?.end_time + ")"
                              }
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Reason </Label>
                              <br />
                              {leaveView?.reason}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Status </Label>
                              <br />
                              <Badge color={`${leaveView?.status === "Approved" ? "success-subtle text-success" :
                                "danger-subtle text-danger"}`} className="ms-auto">
                                {leaveView?.status}</Badge>
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Status Reason </Label>
                              <br />
                              {leaveView?.admin_reason}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label fw-bold"> Applied On </Label>
                              <br />
                              {DefaultDate(leaveView?.created_at, selectedCountry)}
                            </div>
                          </Col>
                        </Row>
                  }
                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>

          <div className="modal fade" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="lg" isOpen={modalProfile} toggle={() => { setModalProfile(false); }} centered>

                <ModalHeader className='bpBottom pb-2' toggle={() => { setModalProfile(false); }}  >  {"View Profile Picture"}    </ModalHeader>

                <ModalBody >
                  {modalProfile && <>
                    <div className='text-center py-4' >

                      <h5>{fileUrl.name}</h5>

                      <img src={fileUrl.path} alt={fileUrl.name} width={isDesktop ? 500 : "100%"} height={"100%"} />

                    </div>
                  </>
                  }


                  <div className='pt-2 text-center' >
                    <button className="btn btn-primary shadow-lg"  >
                      <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                    </button>
                  </div>

                </ModalBody>
              </Modal>
            </div>
          </div>

          <div className="modal fade" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <Modal size="lg" isOpen={modalFile} toggle={() => {
                setModalFile(false);
              }} centered>

                <ModalHeader className='bpBottom pb-2' toggle={() => {
                  setModalFile(false);
                }}  >
                  {fileUrl.profile ? "View Profile" : "View Proof"}

                </ModalHeader>

                <ModalBody >


                  {modalFile && <>

                    <div className='text-center py-4' >

                      <h5>{fileUrl.name}</h5>

                      {imageExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                        <img

                          src={fileUrl.path}
                          alt={fileUrl.name}
                          width={isDesktop ? 500 : "100%"}
                          height={"100%"}

                        />
                        : pdfExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                          <iframe src={fileUrl.path} className='iframe' />
                          : excelExtensions.has(getExtension(fileUrl.ext)?.toLowerCase()) ?
                            <>  {fileUrl.name}</>
                            : <>  {fileUrl.name}</>

                      }

                    </div>
                  </>
                  }

                  <div className='pt-2 text-center' >
                    <button className="btn btn-primary shadow-lg"  >
                      <a style={{ color: "white" }} href={fileUrl.path} target='_blank' download>  Download</a>
                    </button>
                  </div>

                </ModalBody>
              </Modal>
            </div>
          </div>

          <Offcanvas isOpen={editMoreOpen} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
              <span className="d-flex justify-content-between" >
                <span className="m-0 me-2 text-white">{title === "Education" ? "Education Information" : "Experience"}</span>

                <span>
                  {<span className="fs-18 px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>}
                  <span className="fs-18  text-color-white cursor" onClick={() => { toggleMoreDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                </span>

              </span>
            </OffcanvasHeader>
            <OffcanvasBody className="p-0">
              <SimpleBar className="h-100">
                <div className="px-4 py-3">
                  <Form onSubmit={(e) => {
                    e.preventDefault();

                    title === "Education" && educationValidation.handleSubmit();
                    title !== "Education" && experienceValidation.handleSubmit();
                    return false;
                  }}>

                    {title === "Education" && education?.map((item, index) => {

                      return (
                        <Card key={index}>
                          <CardBody>
                            <Row>
                              {index !== 0 &&
                                <Col lg={12} className='d-flex justify-content-end'>
                                  <button type="button" className="btn btn-sm btn-icon btn25"
                                    onClick={(e) => { handleEducationRemove(index); }}> <i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                  </button>
                                </Col>}

                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label htmlFor="institution" className="form-label">Institution  </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="institution" placeholder="Enter institution"
                                    name='institution'
                                    value={item.institution || ""}
                                    onChange={(event) => educationValueChange("institution", event.target.value, index)}
                                    required={true}
                                  // onChange={validation.handleChange}
                                  // onBlur={validation.handleBlur}
                                  // value={validation.values.institution || ""}
                                  // invalid={submit && validation.errors.institution ? true : false
                                  // }
                                  />

                                </div>

                              </Col>


                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label htmlFor="subject" className="form-label">Subject  </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="subject" placeholder="Enter subject"
                                    name='subject'
                                    value={item.subject || ""}
                                    onChange={(event) => educationValueChange("subject", event.target.value, index)}

                                  // onChange={validation.handleChange}
                                  // onBlur={validation.handleBlur}
                                  // value={validation.values.subject || ""}
                                  // invalid={submit && validation.errors.subject ? true : false
                                  // }

                                  />

                                </div>

                              </Col>


                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label htmlFor="start_date" className="form-label">Start Date  </Label>
                                  <Flatpickr
                                    name='start_date'
                                    className={`form-control py-1 px-2  `}

                                    options={{
                                      allowInput: true,
                                      dateFormat: SmallDateFormat(selectedCountry),
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                      educationValueChange("start_date", dateStr, index)
                                    }}

                                    value={item.start_date || ""}

                                  />
                                </div>

                              </Col>

                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label htmlFor="end_date" className="form-label">End Date  </Label>
                                  <Flatpickr
                                    name='end_date'
                                    className={`form-control py-1 px-2  `}

                                    options={{
                                      allowInput: true,
                                      dateFormat: SmallDateFormat(selectedCountry),
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                      educationValueChange("end_date", dateStr, index)
                                    }}

                                    value={item.end_date || ""}

                                  />

                                </div>

                              </Col>

                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label htmlFor="degree" className="form-label">Degree </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="degree" placeholder="Enter degree"
                                    name='degree'
                                    value={item.degree}
                                    onChange={(event) => educationValueChange("degree", event.target.value, index)}

                                  // onChange={validation.handleChange}
                                  // onBlur={validation.handleBlur}
                                  // value={validation.values.degree || ""}
                                  // invalid={submit && validation.errors.degree ? true : false
                                  // }

                                  />

                                </div>

                              </Col>


                              <Col lg={6}>
                                <div className="mb-2">
                                  <Label htmlFor="grade" className="form-label">Grade </Label>
                                  <Input type="text" className="form-control px-2 py-1" id="grade" placeholder="Enter grade"
                                    name='grade'
                                    value={item.grade}
                                    onChange={(event) => educationValueChange("grade", event.target.value, index)}

                                  // onChange={validation.handleChange}
                                  // onBlur={validation.handleBlur}
                                  // value={validation.values.grade || ""}
                                  // invalid={submit && validation.errors.grade ? true : false
                                  // }

                                  />

                                </div>

                              </Col>


                            </Row>

                          </CardBody>
                        </Card>
                      )
                    }
                    )}


                    {title !== "Education" && experience?.map((item, index) => (

                      <Card key={index}>
                        <CardBody>
                          <Row>
                            {index !== 0 &&
                              <Col lg={12} className='d-flex justify-content-end'>
                                <button type="button" className="btn btn-sm btn-icon btn25"
                                  onClick={(e) => { handleExperienceRemove(index); }}>
                                  < i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                </button>
                              </Col>}

                            <Col lg={6}>
                              <div className="mb-2">
                                <Label htmlFor="company_name" className="form-label">Company Name  </Label>
                                <Input type="text" className="form-control px-2 py-1" id="company_name" placeholder="Enter company name"
                                  name='company_name'
                                  value={item.company_name}
                                  onChange={(event) => experienceValueChange("company_name", event.target.value, index)}
                                  required
                                //  onChange={validation.handleChange}
                                //  onBlur={validation.handleBlur}
                                //  value={validation.values.company_name || ""}
                                // invalid={submit && !item.company_name ? true : false}

                                />

                              </div>

                            </Col>


                            <Col lg={6}>
                              <div className="mb-2">
                                <Label htmlFor="location" className="form-label">Location </Label>
                                <Input type="text" className="form-control px-2 py-1" id="location" placeholder="Enter location"
                                  name='location'
                                  value={item.location}
                                  onChange={(event) => experienceValueChange("location", event.target.value, index)}

                                //  onChange={validation.handleChange}
                                //  onBlur={validation.handleBlur}
                                //  value={validation.values.location || ""}
                                //  invalid={submit && validation.errors.location ? true : false}

                                />

                              </div>

                            </Col>

                            <Col lg={6}>
                              <div className="mb-2">
                                <Label htmlFor="job_position" className="form-label">Job Position  </Label>
                                <Input type="text" className="form-control px-2 py-1" id="job_position" placeholder="Enter job position"
                                  name='job_position'
                                  value={item.job_position}
                                  onChange={(event) => experienceValueChange("job_position", event.target.value, index)}

                                //  onChange={validation.handleChange}
                                //  onBlur={validation.handleBlur}
                                //  value={validation.values.job_position || ""}
                                //  invalid={submit && validation.errors.job_position ? true : false }

                                />

                              </div>

                            </Col>

                            <Col lg={6}>
                              <div className="mb-2">
                                <Label htmlFor="start_date" className="form-label">Start Date  </Label>
                                <Flatpickr
                                  name='start_date'
                                  className={`form-control py-1 px-2 `}

                                  options={{
                                    allowInput: true,
                                    dateFormat: SmallDateFormat(selectedCountry),
                                  }}
                                  onChange={(selectedDates, dateStr, instance) => {
                                    experienceValueChange("start_date", dateStr, index);
                                  }}

                                  value={item.start_date || ""}
                                />

                              </div>

                            </Col>

                            <Col lg={6}>
                              <div className="mb-2">
                                <Label htmlFor="end_date" className="form-label">End Date  </Label>
                                <Flatpickr
                                  name='end_date'
                                  className={`form-control py-1 px-2   `}

                                  options={{
                                    allowInput: true,
                                    dateFormat: SmallDateFormat(selectedCountry),
                                  }}
                                  onChange={(selectedDates, dateStr, instance) => {
                                    experienceValueChange("end_date", dateStr, index)
                                  }}

                                  value={item.end_date || ""}
                                />

                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}

                    <Row>
                      <Col className='d-flex justify-content-end align-items-center'>
                        <button
                          type="button"
                          className="btn btn-success shadow-lg px-2 py-1 mb-2 d-flex align-items-center gap-1"
                          onClick={() => {
                            title === "Education" ? handleEducationAdd() : handleExperienceAdd()
                          }}
                        >
                          <FaPlus /> More
                        </button>
                      </Col>

                      <Col lg={12}  >
                        <div className="hstack gap-2 justify-content-end">

                          <button type="submit" className="btn btn-primary shadow-lg" id="addNewExpense" onClick={() => {
                            setSubmit(true);
                          }}>
                            {"Submit"}</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProfilePage;