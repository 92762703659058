import React, { useState, useEffect, useCallback } from 'react';
import {
    Card, CardBody, Col, Container, FormGroup, Offcanvas,
    OffcanvasHeader, Spinner,
    OffcanvasBody, Form, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FaCheck, FaTimes, } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useMediaQuery } from 'react-responsive';
import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";

const Setting = () => {
    document.title = "Setting";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [settingList, setSettingList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [open, setOpen] = useState(false);
    const [submit, setSubmit] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleEditClick = useCallback((arg) => {
        setSubmit(false);
        viewSetting(arg?.id);
        setIsEdit(true);
    }, []);


    const keywordSearch = async (e) => {
        let inputVal = e;

        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.text?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.value?.toLowerCase().includes(inputVal.toLowerCase())
            );

            setSettingList({
                ...settingList,
                data: filteredResults
            });

        } else {
            setSettingList(searchList);
        }
    };



    const getSetting = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`mailSetting?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setSettingList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setSettingList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }
            setLoading(false);

        } catch (err) {
            console.error(err.message);
        }

    };

    const getSettingPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {

            const response = await api.get(`mailSetting?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setSettingList(response.data?.data);
                setSearchList(response.data?.data);

            } else {
                setSettingList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };


    const viewSetting = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('mailSetting/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
            }

            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }

    };

    useEffect(() => {
        getSetting();
    }, []);

    const editSettings = async (values, id) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';

        try {
            const formData = new FormData();
            formData.append('_method', "PUT");
            formData.append('text', values.text);
            formData.append('value', values.value);
            const response = await api.post('mailSetting/' + id, formData);
            if (response.data.status === true) {
                getSetting();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            text: '',
            value: '',
        },
        validationSchema: Yup.object({
            text: Yup.string().required("Please Enter"),
            value: Yup.string().required("Please Enter"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editSettings(values, validation.values?.id);
            }
        },
    });

    const handleClick = (e) => {
        setCurrentPage(e);
        getSettingPage(e, rowPerPage);
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0">Mail Settings</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={6} > </Col>
                        <Col xs={12} md={6} lg={6} className="justify-content-end"  >

                            <Row className=" justify-content-end" >
                                <Col xs={12} md={6} lg={6} className='d-block d-md-flex justify-content-end py-2 py-md-0'     >
                                    <div className="search-box">
                                        <Input type="text" className="form-control" placeholder="Search for name..." onChange={(e) => keywordSearch(e.target.value)} />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </Col>

                            </Row>

                        </Col>

                    </Row>


                    <Row className="row  gy-1 mb-1  mt-1  px-2" >

                        <Col lg={12} className='table px-1  mb-0'>
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-info border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">

                                        <div className="col-12 col-md-4  px-2">
                                            <h5 className="fs-15 mb-0 px-1"> Name</h5>
                                        </div>

                                        <div className="col-10 col-md-7  px-1">
                                            <h5 className="fs-15 mb-0">Value</h5>
                                        </div>

                                        <div className="col-2 col-md-1 d-flex justify-content-end px-3">
                                            <h5 className="fs-15 mb-0">Action</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row className="row gy-1 mb-1" id="setting-list">
                        {loading ?
                            <div className='text-center'>

                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>

                            :
                            settingList?.data?.length !== 0 ?

                                (settingList?.data)?.map((item, index) => (

                                    <Col lg={12} key={index} className='mt-0 '  >

                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                            <CardBody className="px-2 py-0">

                                                <div className="d-lg-flex align-items-center row px-2">

                                                    <div className="col-12 col-md-4  px-1 px-md-2">

                                                        <p className="mb-0 ">    {item.text} </p>

                                                    </div>

                                                    <div className="col-10 col-md-7    d-flex  justify-content-md-start px-1">

                                                        <p className="mb-0 ">  {item?.value}  </p>

                                                    </div>

                                                    <div className="col-2 col-md-1 mb-1  mx-auto d-flex gap-2 align-items-center justify-content-end px-1 px-md-2">
                                                        <button type="button" className="btn  btn-sm btn-icon btn25"
                                                            onClick={(e) => { handleEditClick(item); }}>
                                                            <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                        </button>

                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>


                                ))

                                :
                                <div className="noresult" >
                                    <div className="text-center">

                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                        {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                    </div>
                                </div>
                        }
                    </Row>



                    <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">



                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getSettingPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >

                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={settingList?.total}>All</option>

                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={settingList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-primary p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2 text-white">{!isEdit ? "Add Settings" : "Update Settings"}</span>

                                <span>
                                    <span className="fs-18 px-1 text-color-white" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>   <FaCheck className='cursor' style={{ color: "white" }} /></span>
                                    <span className="fs-18 text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                </span>
                            </span>

                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                        <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="text" className="form-label">Name</Label>
                                                    <Input type="text"  className="form-control px-2 py-1"id="text" name='text'
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.text || ""}
                                                        invalid={
                                                            submit && validation.errors.text ? true : false
                                                        }
                                                    />
                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="settingName" className="form-label">Value</Label>
                                                    <Input type="text"   className="form-control px-2 py-1" id="settingName" name='value'
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.value || ""}
                                                        invalid={
                                                            submit && validation.errors.value ? true : false
                                                        }
                                                    />
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    {isEdit ? <button type="submit" className="btn btn-primary" onClick={() => {   setSubmit(true);   }}>
                                                    {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Save"}</button> : ""}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>

                                </div>
                            </SimpleBar>

                        </OffcanvasBody>

                    </Offcanvas>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default Setting;