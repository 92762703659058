import React, {  useEffect  } from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Register from "../pages/Authentication/Register";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import User from "../pages/Employee/User";
import Permission from "../pages/Employee/Permission";
import Role from "../pages/Employee/Role";
import Employee from "../pages/Employee/Employee";
import Department from "../pages/Employee/Department";
import Settings from "../pages/Settings/Settings";
import MailSettings from "../pages/Settings/MailSettings";
import Expense from "../pages/Expenses/Expense";
import Category from "../pages/Expenses/Category";
import WorkingHours from "../pages/WorkingHours/index";
import WorkingHourList from "../pages/WorkingHours/WorkingHoursList";
import WorkingHourView from "../pages/WorkingHours/WorkingHoursView";
import LeaveType from "../pages/Leave/LeaveType";
import Leave from "../pages/Leave/Leave";
import Assets from "../pages/Assets/Assets";
import AssignUser from "../pages/Assets/AssignUser";
import AssetCategory from "../pages/Assets/Category";
import Documents from "../pages/Documents/Documents";
import Documentcategory from "../pages/Documents/Category";
import Holiday from "../pages/Holiday/Holiday";
import Holidays from "../pages/Holiday";
import Attendance from "../pages/Attendance/Attendance";
import EODReport from "../pages/Others/EODReport";
import Clients from "../pages/Others/Clients";
import Projects from "../pages/Projects/Projects";
import Profile from "../pages/Employee/Profile";

import Blogs from "../pages/Blogs/Blogs";
import BlogCategory from "../pages/Blogs/Category";
import BlogTag from "../pages/Blogs/Tags";
import Notes from "../pages/Others/Notes";
import Faq from "../pages/Others/Faq";

 
const user = localStorage.getItem("authUser");
const userDetails = JSON.parse(user)?.user;
const role = userDetails?.roles?.[0]?.name;
 
// role === "Admin" ?  : <Navigate to="/dashboard" />,
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/expenses", component: <Expense /> },
  { path: "/assets", component:  <Assets /> , },
  { path: "/asset_user", component: <AssignUser /> },
  { path: "/assetcategory", component:<AssetCategory />  },
  { path: "/category", component: <Category /> },
  { path: "/employees", component: <Employee /> },
  { path: "/departments", component: <Department />  },
  { path: "/documents", component: <Documents /> },
  { path: "/documentcategory", component: <Documentcategory /> },
   { path: "/workinghours", component: <WorkingHours /> },
  { path: "/workinglist", component: <WorkingHourList />  },
  { path: "/workingListView", component: <WorkingHourView /> },
  { path: "/leavetypes", component: <LeaveType /> },
  { path: "/leave", component: <Leave /> },
  { path: "/user", component: <User />  },
  { path: "/role", component: <Role /> },
  { path: "/permission", component: <Permission /> },
  { path: "/settings", component: < Settings /> },
  { path: "/mailsettings", component: <MailSettings />  },
  // { path: "/leaveSettings", component: < LeaveSettings /> },
  // { path: "/currency", component: <Currency /> },
  // { path: "/status", component: < Status /> },
  { path: "/holidays", component: <Holidays /> },
  { path: "/holiday", component:  <Holiday /> },
  { path: "/attendance", component: <Attendance /> },
  { path: "/eodReport", component: <EODReport /> },
  //  { path: "/schedule", component: role === "Admin"? <Schedule /> : <Navigate to="/dashboard" />, },
  //  { path: "/shift", component: role === "Admin"? <Shift /> : <Navigate to="/dashboard" />, },
  { path: "/clients", component: <Clients /> , },
  { path: "/projects", component: <Projects /> , },
  { path: "/profile", component: <Profile /> },

  { path: "/blogs", component: <Blogs /> },
  { path: "/blog-category", component: <BlogCategory /> },
  { path: "/blog-tag", component: <BlogTag /> }, 
  { path: "/notes", component: <Notes /> }, 
  { path: "/faq", component: <Faq /> },
  
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };